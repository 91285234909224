import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Header, Message, Field, Toolbar, Image, NotFound, TaxDataModal, UserLog, SearchProductsModal, PaymentInfoModal } from '../components';
import { Order, ShippingDetails, OrderItem, ShippingForm, OrderPayment, PaymentMethod, User, RFC, Invoice, SetLoading, ShippingType, UserAccess, UserLoginData, EditProduct, newProduct, Shipping } from '../Classes';
import { addCommas, bindClick, bindFormChange, validatePhone } from '../Util';
import { Modal, Checkbox, Dropdown, ModalActions, Icon } from 'semantic-ui-react';
import { Stat, Table, Items, Input, Button } from 'react-frontier';
import { useTitle } from '../Hooks';
import { normalizeSync } from 'normalize-diacritics';
import { firstBy } from 'thenby';
import { WebProduct } from '@cocoan/components/Classes';
import moment from 'moment';
import Validator from '../Validator';
import Toast from 'react-hot-toast';
import API from '../API';

import classNames from 'classnames';
import '../style/site.scss'
import '../style/order_view.scss'

enum ResendType{
	EMAIL = 1,
	SMS = 2,
}

enum SendType{
	EMAIL = 1,
	SMS = 2,
	NONE = 3
}

interface ResendForm{
	type: ResendType,
	phone: string
}

export interface PaidForm{
	payment_method: number,
	amount: string,
	reference: string,
	date_paid: number,
	complete_payment: boolean,
	corte_id: number
}

export interface InvoiceForm{
	payment_method: string,
	payment_form: number,
	tax_id: number
}

export interface PaymentInvoice {
	clave: string;
	description: string;
}

export interface GiftInfo{
	gift_name: string,
	gift_last_name: string,
	gift_phone?: string,
	gift_message?: string
}

export interface OrderMethod {
	method_id: number,
	method_name: string
}

export interface Corte {
	corte_id: number,
	name: string
}

export interface NewShipment {
	mehod_id: number,
	shipping_cost: number
}

const initialShippingDetailsForm = {
	name: "",
	phone: "",
	street: "",
	exterior_number: "",
	interior_number: "",
	neighborhood: "",
	city: "",
	state: "",
	zipcode: "",
	country: "Mexico",
	comments: ""
};

const orderMethods: OrderMethod[] = [
	{ method_id: 1 , method_name: 'PDV' },
	{ method_id: 2 , method_name: 'WEB' },
	{ method_id: 20 , method_name: 'Empresarial' },
	{ method_id: 21 , method_name: 'Personalizado' },
	{ method_id: 22 , method_name: 'Call Center' }
];

const invoicePayment: PaymentInvoice[] = [
	{ clave: "01", description: "Efectivo" },
	{ clave: "02", description: "Cheque nominativo" },
	{ clave: "03", description: "Transferencia electrónica de fondos" },
	{ clave: "04", description: "Tarjeta de crédito" },
	{ clave: "05", description: "Monedero electrónico" },
	{ clave: "06", description: "Dinero electrónico" },
	{ clave: "08", description: "Vales de despensa" },
	{ clave: "12", description: "Dación en pago" },
	{ clave: "13", description: "Pago por subrogación" },
	{ clave: "14", description: "Pago por consignación" },
	{ clave: "15", description: "Condonación" },
	{ clave: "17", description: "Compensación" },
	{ clave: "23", description: "Novación" },
	{ clave: "24", description: "Confusión" },
	{ clave: "25", description: "Remisión de deuda" },
	{ clave: "26", description: "Prescripción o caducidad" },
	{ clave: "27", description: "A satisfacción del acreedor" },
	{ clave: "28", description: "Tarjeta de débito" },
	{ clave: "29", description: "Tarjeta de servicios" },
	{ clave: "30", description: "Aplicación de anticipos" },
	{ clave: "31", description: "Intermediario pagos" },
	{ clave: "99", description: "Por definir" }
];

enum Modals{
	SHIPPING = 1,
	RESEND = 2,
	EDIT_INFO = 3,
	PAYMENT_INFO = 4,
	SHIPPING_DATE = 5,
	DELETE_PAYMENT = 6,
	ADD_PAYMENT = 7,
	PREPARE = 8,
	ASSIGN = 9,
	COMMENTS = 10,
	INVOICE = 11,
	CANCEL_INVOICE = 12,
	TAX_DATA_MODAL = 13,
	CANCEL = 14,
	SHIPPING_DETAILS = 15,
	SHIPPING_SUMMARY = 16,
	NOTIFICATION = 17,
	UNSHIPPED = 18,
	EDIT_METHOD = 19,
	GIFT_DETAILS = 20,
	PAYMENT_EDIT_INFO = 21,
	EDIT_ITEM_MODAL = 22,
	DELETE_ITEM= 23,
	ADD_ITEM = 24,
	ADD_PRODUCT_CONFIRMATION = 25,
	ITEM_USER_INFO = 26,
	SEARCH_PRODUCT = 27,
	SHIPPING_LOCATION_DELETE = 28,
	PARTIAL_PAYMENT = 29,
	PAID_ORDER = 30,
	SHIPMENT = 31
}

enum PdvPayments {
	EFECTIVO_PDV = 21,
	TARJETA_PDV =  20
}

var OrderView = ()=>{
	var { order_id } = useParams();
	var { setTitle } = useTitle();
	var [userLogged, setUserLogged] = useState<UserLoginData>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [orderDetails, setOrderDetails] = useState<Order>(null);
	var [shippingDetails, setShippingDetails] = useState<ShippingDetails>(null);
	var [shippingDetailsForm, setShippingDetailsForm] = useState<ShippingDetails>(initialShippingDetailsForm);
	var [orderProducts, setOrderProducts] = useState<OrderItem[]>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>([]);
	var [shownModal, setShownModal] = useState<Modals>(null);
	var [shippingForm, setShippingForm] = useState<ShippingForm>({ shipping_handler: null, tracking_number: null, notification: null, shipping_date: null, include_tracking_number: false});
	var [resendForm, setResendForm] = useState<ResendForm>({ type: null, phone: '' });
	var [paidForm, setPaidForm] = useState<PaidForm>(null);
	var [invoiceForm, setInvoiceForm] = useState<InvoiceForm>(null);
	var [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>(null);
	var [selectedPayment, setSelectedPayment] =  useState<OrderPayment>(null);
	var [users, setUsers] = useState<User[]>(null);
	var [userSearch, setUserSearch] = useState<string>('');
	var [selectedUser, setSelectedUser] = useState<number>(null);
	var [comments, setComments] = useState<string>('');
	var [usersLoading, setUsersLoading] = useState<boolean>(false);
	var [taxDataList, setTaxDataList] = useState<RFC[]>(null);
	var [currentInvoice, setCurrentInvoice] = useState<Invoice>(null);
	var [notificationPhone, setNotificationPhone] =  useState<string>('');
	var [newOrderMethod, setNewOrderMethod] = useState<OrderMethod>({ method_id: null, method_name: null});
	var [editPayment, setEditPayment] = useState<OrderPayment>(null);
	var [selectedItem, setSelectedItem] = useState<OrderItem>(null);
	var [addedProducts, setAddedProducts] = useState<WebProduct[]>([]);
	var [giftForm, setGiftForm] = useState<GiftInfo>(null);
	var [newProduct, setNewProduct] = useState<boolean>(false);
	var [partialPaymentAmount, setPartialPaymentAmount] = useState<number>(null);
	var [loadingCortes, setLoadingCortes] = useState<boolean>(false);
	var [cortes, setCortes] = useState<Corte[]>(null);
	var [newShipment, setNewShipment] = useState<NewShipment>(null);
	var [shipmentMethods, setShipmentMethods] = useState<Shipping[]>([]);
	
	useEffect(() => {
		setUserLogged(API.getLogin());
		get();
		setTitle(`Orden ${order_id}`);
	}, []);

	var get = async() => {	
		API.getOrderDetails(parseInt(order_id), false, true, true).then(res => {
			if (res.error) return setLoadError(res.message);
			setShippingDetails(res.data.shipping);
			setOrderProducts(res.data.cart);
			if(res.data.comments) setComments(res.data.comments);
			setOrderDetails(res.data);
		}).catch(err => {
			setLoadError('Hubo un error al obtener los detalles de la orden. (GOD-1)');
		});
	};

	var getMethods = async()=>{
		if(paymentMethods) return;
		API.getPaymentMethods().then(res=>{
			if (res.error) return Toast.error(res.message);
			return setPaymentMethods(res.data);
		}).catch(err=>{
			Toast.error('Hubo un error al obtener los metodos de pago. (GPM-1)');
		})
	}

	var getTaxData = async()=>{
		if(taxDataList) return;
		API.getRfcList(1).then(res=>{
			if (res.error) return Toast.error(res.message);
			return setTaxDataList(res.data);
		}).catch(err=>{
			Toast.error('Hubo un error al obtener los datos fiscales. (GTD-1)');
		})
	}

	var showModal = (modal: Modals)=>{
		setModalPrompts(null);
		return setShownModal(modal);
	}

	var showPaidModal = ()=>{
		getMethods();

		var amount_paid = orderDetails.payments.reduce((a,b)=>a+b.amount, 0);
		var pending_paid = orderDetails.total-amount_paid;
		setPaidForm({
			amount: pending_paid.toString(),
			complete_payment: true,
			date_paid: moment().unix(),
			payment_method: null,
			reference: '',
			corte_id: null
		})
		return showModal(Modals.ADD_PAYMENT);
	}

	var updateShippingDetails = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(shippingForm, {
			notification: [{
				rule: 'empty', prompt: 'Favor de seleccionar el tipo de notificación',
			}],
			...(shippingForm.include_tracking_number ? {
				tracking_number: [{
					rule: 'minLength', params: [3], label: 'Número de guia'
				}],
				shipping_handler: [{
					rule: 'minLength', params: [3], label: 'Paquetería'
				}],
   		} : {}),
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.orderShippingDetails(orderDetails.order_id, shippingForm.shipping_handler, shippingForm.tracking_number, shippingForm.notification).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var ord = { ...orderDetails };
			ord.shipped = true;
			setOrderDetails(ord);
			Toast.success('Datos de envío actualizados!');
			return showModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado actualizando los datos de envio. (O-SDU-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	};

	var editShippingDate = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(shippingForm, {
			shipping_date:[{
				rule: 'number', label: 'Fecha de envío'
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editOrderShippingDate(orderDetails.order_id, shippingForm.shipping_date).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Fecha de envío actualizada!');
			orderDetails.shipping_date = shippingForm.shipping_date;
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado actualizando la fecha de envío. (O-SDE-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var update = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(orderDetails, {
			owner_name:[{
				rule: 'minLength', params: [3], label: 'Cliente'
			}],
			owner_last_name:[{
				rule: 'minLength', params: [3], label: 'Apellido'
			}],
			owner_email:[{
				rule: 'minLength', params: [3], label: 'Correo electrónico'
			}],
			owner_phone:[{
				rule: 'minLength', params: [3], label: 'Teléfono'
			}]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.orderUpdateDetails(orderDetails.order_id, orderDetails.owner_name, orderDetails.owner_last_name, orderDetails.owner_email, orderDetails.owner_phone).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Datos de orden actualizados!');
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado actualizando los datos de la orden. (O-OUD-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	};

	var resendOrder = async (setLoading: (l: boolean)=>void)=>{
		if(!resendForm) return;
		if(resendForm.type===ResendType.SMS){
			var phone = validatePhone(resendForm.phone);
			if(!phone) return setModalPrompts(['El telefóno ingresado es invalido.']);
		}

		var fn = resendForm.type===ResendType.EMAIL ? API.resendOrderEmail(orderDetails.order_id) : API.resendOrderSms(orderDetails.order_id, resendForm.phone);

		setLoading(true);
		setModalPrompts(null);
		fn.then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Orden reenviada');
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado enviando el mensaje de la orden. (O-MSG-1)']);
		}).finally(()=>{	
			setLoading(false);
		});
	};	
	
	var showResendModal = ()=>{
		setResendForm({ type: orderDetails.owner_email && orderDetails.owner_email.length>=5 ? null : ResendType.SMS, phone: orderDetails.owner_phone });
		setModalPrompts(null);
		return showModal(Modals.RESEND);
	}

	var createPayment = async(setLoading: (l: boolean)=>void)=>{
		var amount_paid = orderDetails.payments.reduce((a,b)=>a+b.amount, 0);
		var pending_paid = orderDetails.total-amount_paid;
		var form_data : PaidForm = { ...paidForm };

		var { valid, prompts } = Validator(form_data, {
			payment_method: [{ rule: 'empty', label: 'Metodo de pago' }],
			amount: [{ rule: 'max', params: [pending_paid], label: 'Monto' }],
			date_paid: [{ rule: 'empty', label: 'Fecha de pago' }],
			...(form_data.payment_method === PdvPayments.EFECTIVO_PDV || form_data.payment_method === PdvPayments.TARJETA_PDV ? {
				corte_id: [{
					rule: 'number', label: 'Corte'
				}],
   		} : {}),
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);

		var payment_amount = (form_data.complete_payment ? pending_paid : parseFloat(form_data.amount));
		API.createOrderPayment(orderDetails.order_id, form_data.payment_method, payment_amount, form_data.reference, form_data.date_paid, form_data.corte_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Pago agregado');
			setShownModal(null);

			var ord = { ...orderDetails };
			if(!ord.payments) ord.payments = [];

			var method = paymentMethods.find(a=>a.method_id==form_data.payment_method);

			ord.payments.push({
				payment_id: (res.data.payment_id as unknown as { payment_id: number }).payment_id,
				order_id: orderDetails.order_id,
				payment_method: form_data.payment_method,
				amount: payment_amount,
				date_paid: form_data.date_paid,
				reference: form_data.reference,
				paid: true,
				method_name: method?.method_name_localized
			})
			var amount_paid = ord.payments.reduce((a,b)=>a+b.amount, 0);
			if(amount_paid>=ord.total){
				ord.paid = true;
				ord.date_paid = form_data.date_paid;
			}
			
			setOrderDetails(ord);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado creando el pago. (LCL-1)']);
		}).finally(()=>{	
			setLoading(false);
		});
	}

	var prepare = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.prepareOrder(orderDetails.order_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Orden Ready');
			var o = { ...orderDetails };
			o.prepared = 1;
			o.date_prepared = moment().unix();
			setOrderDetails(o);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado al marcar la orden como ready. (LCL-1)']);
		}).finally(()=>{	
			setLoading(false);
		})
	}

	var unprepare = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.unprepareOrder(orderDetails.order_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Orden unready!');
			var o = { ...orderDetails };
			o.prepared = 0;
			o.date_prepared = null;
			setOrderDetails(o);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado marcando la orden como unready. (LCL-1)']);
		}).finally(()=>{	
			setLoading(false);
		})
	}

	var openInfoModal = (payment_id: number)=>{
		return ()=>{
			var payment = orderDetails.payments.find(a=>a.payment_id==payment_id);
			if(!payment){
				return Toast.error('Pago no encontrado.');
			}
			setSelectedPayment(payment);
			return showModal(Modals.PAYMENT_INFO);
		}
	}

	var showAssignModal = async() =>{
		if(!users){
			try{
				setUsersLoading(true);
				var res = await API.getActiveUsers();
				if(res.error) return alert(res.message);
				setUsers(res.data);
				setUsersLoading(false);
			}catch(e){
				alert('Hubo un error cargando los usuarios');
			}
		}
		showModal(Modals.ASSIGN);
	}

	var assign = async (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ user_id: selectedUser }, {
			user_id: [{
				rule: 'empty', label: 'Usuario'
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		
		API.assingOrders(selectedUser, parseInt(order_id)).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var assigned_user = users.find(u => u.user_id === selectedUser);
			Toast.success('Orden asignada!')

			var o = { ...orderDetails };
			o.assigned_user = assigned_user;
			o.date_assigned = moment().unix();
			setOrderDetails(o);
			setShownModal(null);
			return setSelectedUser(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error al asignar las ordenes. (AOE-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var addComments = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.addOrderComments(orderDetails.order_id, comments).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Comentarios agregados!');
			var o = { ...orderDetails };
			o.comments = comments;
			setOrderDetails(o);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado agregando los comentarios. (LCL-1)']);
		}).finally(()=>{	
			setLoading(false);
		})
	}

	var unshipped = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.unshippedOrder(orderDetails.order_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Orden actualizada!');
			var o = { ...orderDetails };
			o.shipped = false;
			o.date_shipped = null;
			setOrderDetails(o);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado actualizando la orden. (LCL-1)']);
		}).finally(()=>{	
			setLoading(false);
		})
	}

	var cancelOrder = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.cancelOrder(orderDetails.order_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Orden cancelada!');
			var o = { ...orderDetails };
			o.cancelled = true;
			setOrderDetails(o);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado cancelando la orden. (LCL-1)']);
		}).finally(()=>{	
			setLoading(false);
		})
	}

	var openInvoiceModal = ()=>{
		getTaxData();
		setInvoiceForm({
			payment_method: null,
			tax_id: null,
			payment_form: null,
		})
		showModal(Modals.INVOICE);
	}

	var createInvoice = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(invoiceForm, {
			payment_method:[{
				rule: 'empty', label: 'Metodó de pago'
			}],
			payment_form:[{
				rule: 'number', label: 'Forma de pago'
			}],
			tax_id:[{
				rule: 'number', label: 'Datos fiscales'
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createInvoice(orderDetails.order_id, invoiceForm.tax_id, invoiceForm.payment_method, invoiceForm.payment_form).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Factura generada!');

			var t = taxDataList.find(ta => ta.tax_id === invoiceForm.tax_id);
			var o = { ...orderDetails };
			o.factura_external = res.data.external_id;
			o.invoices.push({
				invoice_id: res.data.invoice_id,
				order_id: orderDetails.order_id,
				provider_id: 1,
				tax_id: invoiceForm.tax_id,
				payment_method: null,
				payment_form: null,
				external_id: res.data.external_id,
				user_creator: null,
				user_canceller: null,
				cancelled: 0,
				date_created: moment().unix(),
				date_cancelled: null,
				method_name: null,
				provider_name: 'FacturApi',
				rfc: t.rfc,
				invoice_number: res.data.invoice_number

			})
			setOrderDetails(o);
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado generando la factura. (O-IGE-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var openCancelInvoiceModal = (invoice: Invoice)=>{
		setCurrentInvoice(invoice);
		showModal(Modals.CANCEL_INVOICE);
	}

	var openUsersModal = (item: OrderItem)=>{
		setSelectedItem(item)
		showModal(Modals.ITEM_USER_INFO);
	}

	var cancelInvoice = async(setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.cancelInvoice(currentInvoice.invoice_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Factura cancelada!')

			currentInvoice.cancelled = 1;
			var o = { ...orderDetails };
			o.factura_external = null;
			var i = o.invoices.findIndex(invoice => invoice.invoice_id === currentInvoice.invoice_id);
			o.invoices[i] = currentInvoice;
			setOrderDetails(o);
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado cancelado la factura. (O-ICE-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onTaxInfoCreated = (form: RFC) => {
		var list = [...taxDataList];
		list.push(form);
		setTaxDataList(list);
		setShownModal(Modals.INVOICE);
	}

	var viewFactura = (invoice_id: number)=>{
		return ()=>{
			API.downloadInvoice(invoice_id).then(res=>{
				if(res.error) return Toast.error(res.message);
				if(!res.data || !res.data.invoice_url) return Toast.error('Hubo un error cargando la factura (LCL-3)')
				window.open(res.data.invoice_url, '_blank', 'noreferrer');
			}).catch(err=>{
				return Toast.error('Hubo un error cargando la factura. (LCL-3)');
			});
		}
	}

	var downloadFactura = (invoice_id: number)=>{
		return ()=>{
			API.downloadInvoice(invoice_id, true).then(res=>{
				if(res.error) return Toast.error(res.message);
				if(!res.data || !res.data.invoice_url) return Toast.error('Hubo un error cargando la factura (LCL-4)')
				window.open(res.data.invoice_url, '_blank', 'noreferrer');
			}).catch(err=>{
				return Toast.error('Hubo un error cargando la factura. (LCL-4)');
			});
		}
	}

	var editShippingDetails = async (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(shippingDetails, {
			street: [{
				rule: 'empty', label: 'Calle',
			}],    
			neighborhood: [{
				rule: 'minLength', params: [3], label: 'Colonia'
			}],
			city: [{
				rule: 'minLength', params: [3], label: 'Ciudad'
			}],
			state: [{
				rule: 'minLength', params: [3], label: 'Estado'
			}],
			zipcode: [{
				rule: 'empty', label: 'Codigo postal'
			}],
			exterior_number: [{
				rule: 'empty', label: 'Número exterior'
			}]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editShippingDetails(orderDetails.order_id, shippingDetailsForm).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var s = {...shippingDetailsForm};
			setShippingDetails(s);
			Toast.success('Dirección actualizada!');
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado editando la dirección. (O-ESD-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var getShippingSummary = () => {
		var summaryText = '';
		if (orderDetails.gift_name) {
			summaryText += `Recibe: ${orderDetails.gift_name} ${orderDetails.gift_last_name || ''} \n`;
		}else if (orderDetails.owner_name !== null) {
			summaryText += `Recibe: ${orderDetails.owner_name} ${orderDetails.owner_last_name || ''} \n\n`;
		}
		if (orderDetails.gift_name) {
			summaryText += `Envía: ${orderDetails.owner_name || 'Sin Datos.'} ${orderDetails.owner_last_name || ''}\n\n`;
		}
		summaryText += `${shippingDetails.street} ${shippingDetails.exterior_number}${shippingDetails.interior_number ? `-${shippingDetails.interior_number}` : ''}\n${shippingDetails.neighborhood}, ${shippingDetails.zipcode}\n${shippingDetails.city}, ${shippingDetails.state}`;
		if (shippingDetails.comments !== null) {
			summaryText += `\nReferencia: ${shippingDetails.comments}`;
		}
		
		var mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(shippingDetails.street + " " + shippingDetails.exterior_number + (shippingDetails.interior_number ? "-" + shippingDetails.interior_number : "") + ", " + shippingDetails.neighborhood + ", " + shippingDetails.zipcode + ", " + shippingDetails.city + ", " + shippingDetails.state)}`;
		return `${summaryText}\n\n${mapUrl}`;
	}

	var showShippingForm = ()=>{
		setShippingForm({
			shipping_handler: orderDetails.shipping_handler ? orderDetails.shipping_handler : null,
			tracking_number: orderDetails.tracking_number ? orderDetails.tracking_number : null,
			shipping_date: orderDetails.shipping_date,
			notification: null,
			include_tracking_number: orderDetails.tracking_number ? true : false
		});

		showModal(Modals.SHIPPING)
	}

	var openMaps = async (setLoading: SetLoading) => {
		setLoading(true);
		var mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(shippingDetails.street + " " + shippingDetails.exterior_number + (shippingDetails.interior_number ? "-" + shippingDetails.interior_number : "") + ", " + shippingDetails.neighborhood + ", " + shippingDetails.zipcode + ", " + shippingDetails.city + ", " + shippingDetails.state)}`;
		window.open(mapUrl, '_blank');
		setLoading(false);
	}

	var openNotificationModal = ()=>{
		setNotificationPhone(orderDetails.owner_phone);
		showModal(Modals.NOTIFICATION);
	}

	var editMethod = async (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(newOrderMethod, {
			method_id: [{
				rule: 'empty', label: 'Tipo',
			}],    
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editOrderMethod(orderDetails.order_id, newOrderMethod.method_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var o = {...orderDetails};
			o.order_method = newOrderMethod.method_id;
			setOrderDetails(o);
			setNewOrderMethod({ method_id: null, method_name: null});
			Toast.success('Tipo actualizado!')
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado editando el tipo. (O-EOM-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var showShippingDetails = () =>{
		if(shippingDetails) setShippingDetailsForm(shippingDetails);
		setShownModal(Modals.SHIPPING_DETAILS);
	}

	var showGiftModal = () =>{
		setGiftForm({
			gift_name: orderDetails.gift_name,
			gift_last_name: orderDetails.gift_last_name,
			gift_phone: orderDetails.gift_phone,
			gift_message: orderDetails.gift_message
		})
		setShownModal(Modals.GIFT_DETAILS);
	}

	var editGift = async (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(giftForm, {
			gift_name: [{
				rule: 'empty', label: 'Nombre',
			}],
			gift_last_name: [{
				rule: 'empty', label: 'Apellido',
			}]
		});
		setModalPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		API.editGiftDetails(orderDetails.order_id, giftForm.gift_name, giftForm.gift_last_name, giftForm.gift_phone, giftForm.gift_message).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Datos actualizados!')

			var o = {...orderDetails};
			o.gift_name = giftForm.gift_name;
			o.gift_last_name = giftForm.gift_last_name;
			o.gift_phone = giftForm.gift_phone;
			o.gift_message = giftForm.gift_message;
			
			setOrderDetails(o);
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado editando los datos de regalo. (O-EGD-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var paymentDelete = ()=>{
		var ord = { ...orderDetails };
			ord.payments = ord.payments.filter(payment => payment.payment_id !== selectedPayment.payment_id);
			var amount_paid = ord.payments.reduce((a,b)=>a+b.amount, 0);
			if(amount_paid<ord.total){
				ord.paid = false;
				ord.date_paid = null;
			}
			setOrderDetails(ord);
			setShownModal(null);
	}

	var paymentPaid = ()=>{
		var ord = { ...orderDetails };
		var index = ord.payments.findIndex(p => p.payment_id === selectedPayment.payment_id);
		ord.payments[index].paid = true;
		ord.payments[index].date_paid = moment().unix();
		setOrderDetails(ord);
	}

	var showEditProductModal = (item: OrderItem)=>{
		setSelectedItem(item);
		setShownModal(Modals.EDIT_ITEM_MODAL);
	}

	var editItem = async(setLoading: SetLoading)=>{
		setLoading(true);
		selectedItem.cost = selectedItem.quantity * selectedItem.cost_single;

		API.editOrderItem(orderDetails.order_id, selectedItem).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Producto actualizado!')

			var o = {...orderDetails};

			var index = orderProducts.findIndex(item => item.item_id === selectedItem.item_id);
			orderProducts[index] = selectedItem;

			o.total = orderProducts.reduce((sum, p) => sum + p.cost, 0);
			o.paid = orderDetails.payments.reduce((sum, p) => sum + p.amount, 0) >= o.total;

			setOrderProducts(orderProducts);
			setOrderDetails(o);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado editando los productos. (O-OCE-1)']);
		}).finally(()=>{
			setLoading(false);
			setShownModal(null);
		})
	};

	var openDeleteItemModal = (item: OrderItem)=>{
		setSelectedItem(item);
		setShownModal(Modals.DELETE_ITEM);
	};

	var deleteProduct = async(setLoading: SetLoading)=>{
		setLoading(true);
		API.deleteOrderItem(orderDetails.order_id, selectedItem.item_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Producto eliminado!')

			var o = {...orderDetails};
			o.total -= (selectedItem.quantity * selectedItem.cost_single);
			o.paid = orderDetails.payments.reduce((sum, p) => sum + p.amount, 0) >= o.total;
			var op = orderProducts.filter(product => product.item_id !== selectedItem.item_id);
			setOrderDetails(o);
			setOrderProducts(op);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado eliminando el producto. (O-DOP-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	};

	var openAddItemModal = (item: newProduct)=>{
		setSelectedItem({
			product_id: item.product_id,
			quantity: 1,
			cost_single: item.price,
			cost: item.price * 1,
			name: item.product_name,
			size: item.size,
			product_cost: item.product_cost,
			product_ieps: item.tax_ieps,
			product_iva: 0.16
		})
		setNewProduct(true);
		setShownModal(Modals.EDIT_ITEM_MODAL);
	}

	var closeAddItemModal = ()=>{
		setSelectedItem(null);
		setNewProduct(false);
	}

	var addItem = async(setLoading: SetLoading) =>{
		setLoading(true);
		var { valid, prompts } = Validator(selectedItem, {
			quantity: [{
				rule: 'number', label: 'Cantidad',
			}],
			cost_single: [{
				rule: 'number', label: 'Precio',
			}],
			cost: [{
				rule: 'number', label: 'Subtotal',
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;

		API.addOrderItem(parseInt(order_id), {
			product_id: selectedItem.product_id,
			cost_single: selectedItem.cost_single,
			quantity: selectedItem.quantity
		}).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Producto agregado!')
			
			var o = {...orderDetails};
			var op = [
				...orderProducts,
				{
					item_id: res.data.item_id,		
					product_id: selectedItem.product_id,
					cost_single: selectedItem.cost_single,
					quantity: selectedItem.quantity,
					cost: selectedItem.quantity*selectedItem.cost_single,
					name: selectedItem.name,
					granel: false,
					size: selectedItem.size,
					deleted: false,
					product_cost: selectedItem.product_cost,
					product_ieps: selectedItem.product_ieps,
					product_iva: 0.16
				}
			];
			
			o.total = orderProducts.reduce((sum, i) => sum + i.cost, 0);
			o.paid = orderDetails.payments.reduce((sum, p) => sum + p.amount, 0) >= o.total;
			
			setOrderDetails(o);
			setOrderProducts(op);
			setAddedProducts([]);
			setShownModal(null);
		}).catch(err=>{
			Toast.error('Hubo un error inesperado agregando el producto. (O-AOP-1)')
		}).finally(()=>{
			setLoading(false);
		})
	}

	var deleteShippingLocation = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.deleteOrderShippingLocation(orderDetails.order_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Datos de envío eliminados!');
			setShippingDetails(null);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado al eliminar los datos de envío. (LCL-1)']);
		}).finally(()=>{	
			setLoading(false);
		})
	}

	var selectPaymentMethod = async(payment_method: any)=>{
		setCortes(null);
		if(payment_method === PdvPayments.EFECTIVO_PDV || payment_method === PdvPayments.TARJETA_PDV){
			setLoadingCortes(true);
			await API.getCurrentCortes().then(res=>{
				if(res.error) return setModalPrompts([res.message]);
				setCortes(res.data);
			}).catch(err=>{
				setModalPrompts(['Hubo un error inesperado al obtener los cortes. (LCL-1)']);
			}).finally(()=>{
				setLoadingCortes(false);
			})
		}
		var p = {...paidForm}
		p.payment_method = payment_method;
		setPaidForm(p)
	}

	var closeCreatePaymentModal = ()=>{
		setCortes(null);
		setShownModal(null);
	}

	var onCreateFormChange = bindFormChange(shippingForm, setShippingForm);
	var onEditChange = bindFormChange(orderDetails, setOrderDetails);
	var onEditResendForm = bindFormChange(resendForm, setResendForm);
	var onCreatePaidForm = bindFormChange(paidForm, setPaidForm);
	var onCreateInvoiceForm = bindFormChange(invoiceForm, setInvoiceForm);
	var onEditShippingDetails = bindFormChange(shippingDetailsForm, setShippingDetailsForm);
	var onEditOrderMethod = bindFormChange(newOrderMethod, setNewOrderMethod);
	var onEditGiftInfo = bindFormChange(giftForm, setGiftForm);
	var onEditItem = bindFormChange(selectedItem, setSelectedItem);
	var onEditShipment = bindFormChange(newShipment, setNewShipment);


	if(Number.isNaN(parseInt(order_id))){
		return <NotFound />
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!orderDetails){
		return <Header loading text='Cargando datos' />
	}

	var smsNotification = async (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator({ notificationPhone }, {
			notificationPhone: [{
				rule: 'minLength', params: [3], label: 'Teléfono'
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.orderNotificationSms(orderDetails.order_id, notificationPhone).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Notificación enviada!')
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado notificando al cliente. (O-CNE-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var partialPayment = async (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ partialPaymentAmount }, {
			partialPaymentAmount: [{
				rule: 'min', params: [1], label: 'Monto'
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		
		API.createPartialPayment(parseInt(order_id), partialPaymentAmount).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
		}).catch(err=>{
			setModalPrompts(['Hubo un error al generar el link de pago. (CPP-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var paidOrder = async (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		
		API.paidOrder(parseInt(order_id)).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Orden pagada!')

			var o = {...orderDetails};
			o.paid = true;
			setOrderDetails(o);
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error al generar el link de pago. (CPP-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var openShipmentModal = async()=>{
		try {
			var res = await API.shippingList(null, null, false);
			setShipmentMethods(res.data);
		}catch(e){
			setModalPrompts(['Hubo un error al obtener las formas de envío. (GSM-1)']);
		}
		setNewShipment({
			mehod_id: orderDetails.shipping_method,
			shipping_cost: orderDetails.shipping_cost
		})
		setShownModal(Modals.SHIPMENT);
	}

	var editShipmentMethod = async (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(newShipment, {
			mehod_id: [{
				rule: 'number', label: 'Forma de envío',
			}], 
			shipping_cost: [{
				rule: 'number', label: 'Cost de envío'
			}],
		});
		setModalPrompts(prompts);
		if(!valid) return;

		setLoading(true);	
		API.editOrderShipmentMethod(parseInt(order_id), newShipment.mehod_id, newShipment.shipping_cost).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Forma de envío actualizada!')

			var o = {...orderDetails};
			o.shipping_cost = newShipment.shipping_cost;
			o.shipping_method = newShipment.mehod_id;
			o.shipping_type = (shipmentMethods.find(method => method.method_id === newShipment.mehod_id) || {}).type_id;
			o.shipping_method_name = (shipmentMethods.find(method => method.method_id === newShipment.mehod_id) || {}).name;

			setOrderDetails(o);
			return setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error al modificar la forma de envío. (ESM-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}
	
	const toolbar_items = [
		{ text: 'Reenviar', onClick: showResendModal, icon: 'share' },
		!orderDetails.shipped ? (
			{ text: orderDetails.prepared ? 'Unready' : 'Ready', onClick: () => showModal(Modals.PREPARE), icon: 'box' }
		) : null,
		!orderDetails.shipped ? ({ 
			text: orderDetails.shipping_type==ShippingType.PICKUP ? 'Entregado' : 'Envío', 
			onClick: showShippingForm, 
			icon: orderDetails.shipping_type==ShippingType.PICKUP ? 'check circle' : 'truck' 
		}) : null,
		orderDetails.paid ? null : (
			{ text: 'Registrar pago', onClick: showPaidModal, icon: 'money' }
		),
		{ 
			text: 'Editar', 
			icon: 'pencil', 
			items: [
				{ text: 'Modificar datos', onClick: () => showModal(Modals.EDIT_INFO) },
				{ text: 'Fecha de envío', onClick: () => showModal(Modals.SHIPPING_DATE) },
				{ text: 'Agregar comentarios', onClick: () => showModal(Modals.COMMENTS) },
				orderDetails.shipped ? { text: 'Marcar como no enviada', onClick: () => showModal(Modals.UNSHIPPED) } : null,
				{ text: !shippingDetails ? 'Agregar dirección de envío' : 'Dirección de envío', onClick: showShippingDetails },
				{ text: 'Tipo de orden', onClick: () => showModal(Modals.EDIT_METHOD) },
				{ text: 'Datos de regalo', onClick: () => showGiftModal() },
				shippingDetails ? { text: 'Eliminar entrega', onClick: ()=>showModal(Modals.SHIPPING_LOCATION_DELETE) } : null,
				{ text: 'Forma de envío', onClick: openShipmentModal },
			]
		},
		{
			text: 'Herramientas',
			icon: 'wrench',
			items: [
				orderDetails.paid ? null : { text: 'Marcar pagada', onClick: () => showModal(Modals.PAID_ORDER), icon: 'money' },
				orderDetails.assigned_user ? null : { text: 'Asignar orden', onClick: showAssignModal, icon: 'user' },
				orderDetails.factura_external ? null : { text: 'Facturar', onClick: openInvoiceModal, icon: 'file alternate' },
				orderDetails.cancelled ? null : { text: 'Cancelar orden', onClick: () => showModal(Modals.CANCEL), icon: 'cancel' },
				// orderDetails.paid ? null : { text: 'Pago parcial', onClick: () => showModal(Modals.PARTIAL_PAYMENT), icon: 'money' },
			]
		},
	];

	var amount_paid = orderDetails.payments.filter(a=>a.paid).reduce((a,b)=>a+b.amount, 0);
	var shipping_date = moment.unix(orderDetails.shipping_date).startOf('day');
	var pickup_available = shipping_date.diff(moment().startOf('day'), 'day')<=0;

	var itemsHeaders = ['', 'Nombre', 'Precio', 'Cantidad', 'Subtotal', '']

	return <div className='co order-view-screen'>
		<Header text={`Orden ${orderDetails.order_id}`} />
		{!orderDetails.shipped && orderDetails.shipping_type && (
			<div style={{ maxWidth: 500, margin: 'auto', marginBottom: 15 }}>
				<Message centered type={orderDetails.shipping_type===ShippingType.SHIPPING ? 'info' : 'success'}>
					<i className={classNames("icon", {
						'truck': orderDetails.shipping_type===ShippingType.SHIPPING,
						'shopping basket': orderDetails.shipping_type!==ShippingType.SHIPPING
					})} style={{ fontSize: 40, marginBottom: 10 }}></i>
					<div className="header" style={{ marginBottom: 0, fontSize: 20 }}>
						{orderDetails.shipping_method_name}
					</div>
					{orderDetails.shipping_date ? <>
						Entrega: {moment.unix(orderDetails.shipping_date).format('DD/MMM/YY')}
					</> : 'Sin fecha de entrega'}
					{!!orderDetails.prepared && <>
						<br />
						<div className={classNames('fr label', {
							blue: orderDetails.shipping_type==ShippingType.SHIPPING,
							green: orderDetails.shipping_type!=ShippingType.SHIPPING,
						})} style={{ marginTop: 10 }}>
							<i className="check icon"></i> Lista para entrega
						</div>
					</>}
					{!orderDetails.paid && <>
						{!orderDetails.prepared && <br />}
						<div className={'fr red label'} style={{ marginLeft: orderDetails.prepared ? 5 : 0, marginTop: 10 }}>
							<i className="times circle icon"></i> No pagada
						</div>
					</>}
					{!!pickup_available && orderDetails.shipping_type===ShippingType.PICKUP && <>
						<br />
						<Button basic text='Notificar cliente' color='black' size='tiny' iconName={'sms'} style={{ marginTop: 5, width: 180 }} onClick={openNotificationModal}/>
					</>}
				</Message>
			</div>
		)}
		{!!orderDetails.paid && (amount_paid < orderDetails.total) && (
			<div style={{ maxWidth: 500, margin: 'auto', marginBottom: 15 }}>
				<Message type='error' header='Pago faltante' centered>
					Esta orden ha sido marcada como <b>pagada</b>, pero no ha sido pagada totalmente.
					<div style={{ height: 10 }} />
					<b>Total:</b> {addCommas(orderDetails.total)} <br />
					<b>Pagado:</b> {addCommas(amount_paid)} <br />
					<b>Faltante:</b> {addCommas(orderDetails.total-amount_paid)}
				</Message>
			</div>
		)}
		<div style={{ maxWidth: 800, margin: 'auto', marginBottom: 15 }}>
			<Toolbar items={toolbar_items.filter(item=>!!item)} />
		</div>
		{orderDetails.comments && orderDetails.comments.length>0 && (
			<Message style={{ maxWidth: 600, margin: 'auto', marginTop: 15 }} header='Comentarios Internos' centered>
				<pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '100%', fontFamily: 'inherit', marginBottom: 0, marginTop: 0 }}>{orderDetails.comments}</pre>
				<Button text='Editar' basic color='black' size='tiny' style={{ marginTop: 10 }} iconName='pencil' onClick={()=>showModal(Modals.COMMENTS)} />
			</Message>
		)}
		<Table 
			details={true}
			striped={true}
			title={'Detalles'} 
			style={{ maxWidth: 600, margin: 'auto', marginTop: 15 }}
		>
			<Table.Row data={['Orden', orderDetails.order_id]}/>
			<Table.Row data={['Hash', orderDetails.order_hash]}/>
			<Table.Row data={['Método', orderMethods.find(method => method.method_id === orderDetails.order_method)?.method_name]}/>
			<Table.Row data={['Entrega', orderDetails.shipping_method_name]}/>
			{!orderDetails.shipped && (
				<Table.Row data={['Fecha solicitada envío', moment.unix(orderDetails.shipping_date).format('DD/MMM/YY')]}/>
			)}
			<Table.Row data={['Estatus', <>
				<div className={classNames('fr tiny label', {
					red: !orderDetails.paid || orderDetails.cancelled,
					green: orderDetails.paid 
				})}>
					{orderDetails.cancelled ? 'Cancelada' : (orderDetails.paid ? 'Pagado' : 'No pagado')}
				</div>
				{!!orderDetails.shipped && (
					<div className="fr tiny label blue" style={{ marginLeft: 5 }}>
						{orderDetails.shipping_type==ShippingType.PICKUP ? 'Entregado' : 'Enviado'}
					</div>
				)}
				{(amount_paid<orderDetails.total) && (
					<div className='fr orange tiny label' style={{ marginLeft: 5 }}>
						Pago faltante
					</div>
				)}
			</>]}/>
			{orderDetails.date_created && (
				<Table.Row data={['Creación', moment.unix(orderDetails.date_created).format('DD/MMM/YY H:mm')]} />
			)}
			{orderDetails.date_paid && (
				<Table.Row data={['Fecha de pago', moment.unix(orderDetails.date_paid).format('DD/MMM/YY H:mm')]} />
			)}

			<Table.Divider />
			{!orderDetails.pdv_id && <>
				<Table.Row data={['Cliente', `${orderDetails.owner_name} ${(orderDetails.owner_last_name || '')}`]}/>
				<Table.Row data={['Email', orderDetails.owner_email ? orderDetails.owner_email : 'Sin Información']}/>
				<Table.Row data={['Teléfono', orderDetails.owner_phone ? orderDetails.owner_phone : 'Sin Información']}/>
			</>}
			<Table.Divider />

			{!!orderDetails.shipped && <>
				<Table.Row data={['Entrega', orderDetails.shipping_type==ShippingType.SHIPPING ? 'Envío a domicilio' : 'Recoger en tienda']}/>
				{!!orderDetails.shipping_date && (
					<Table.Row data={['Fecha solicitada envío', moment.unix(orderDetails.shipping_date).format('DD/MMM/YY')]}/>
				)}
				{orderDetails.date_shipped && (
					<Table.Row data={['Fecha enviada', moment.unix(orderDetails.date_shipped).format('DD/MMM/YY HH:mm')]}/>
				)}
				<Table.Divider />
			</>}
			<Table.Row data={['Total', `$${addCommas(orderDetails.total)}`]}/>
			{(amount_paid<orderDetails.total) && <>
				<Table.Row data={['Cantidad pagada', '$'+addCommas(amount_paid)]}/>
				<Table.Row data={['Cantidad faltante', <div style={{ color: 'brown', fontWeight: 'bold' }}>
					{'$'+addCommas(orderDetails.total-amount_paid)}
				</div>]}/>
			</>}
			{/* <Table.Row data={['Comisión', '$'+addCommas(orderDetails.commission || 0)]}/>
			<Table.Row data={['Ganancia', '$'+addCommas(orderDetails.total-(orderDetails.commission || 0))]}/> */}
		</Table>
		{shippingDetails ? (
			<Table
				details
				striped
				title='Dirección de envío'
				style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}
				button={(
					<Button text='Resumen' size='tiny' onClick={()=>showModal(Modals.SHIPPING_SUMMARY)}/>
				)}
			>
				<Table.Row data={['Nombre', <>
					{(!!orderDetails.gift_name && orderDetails.gift_name.length>0) && <Icon name='gift' />}
					{(!!orderDetails.gift_name && orderDetails.gift_name.length>0) ? `${orderDetails.gift_name} ${(orderDetails.gift_last_name || '')}` : `${orderDetails.owner_name} ${orderDetails.owner_last_name || ''}`}
				</>]}/>
				<Table.Row data={['Teléfono', orderDetails.gift_phone ? orderDetails.gift_phone : (shippingDetails.phone || orderDetails.owner_phone) ]}/>
				<Table.Row data={['Dirección', shippingDetails.street]}/>
				<Table.Row data={['Colonia', shippingDetails.neighborhood]}/>
				<Table.Row data={['No. Exterior', shippingDetails.exterior_number]}/>
				{(!!shippingDetails.interior_number && shippingDetails.interior_number.length>0) && (
					<Table.Row data={['No. Interior', shippingDetails.interior_number]}/>
				)}
				<Table.Row data={['Ciudad', shippingDetails.city]}/>
				<Table.Row data={['Código postal', shippingDetails.zipcode]}/>
				<Table.Row data={['Estado', shippingDetails.state]}/>
				<Table.Row data={['Referencia', shippingDetails.comments]}/>
				{orderDetails.gift_name && <>
					<Table.Divider />
					<Table.Row data={['Envía', `${orderDetails.owner_name} ${(orderDetails.owner_last_name || '')}`]}/>
					<Table.Row data={['Telefono envia', shippingDetails.phone || orderDetails.owner_phone]}/>
					<Table.Row data={['Mensaje de regalo', orderDetails.gift_message || 'Sin Mensaje']}/>
				</>}
			</Table>
		) : (orderDetails.gift_name || orderDetails.gift_message) ? (
			<Table
				details
				striped
				title='Datos de regalo'
				style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}
				button={(
					<Button size='tiny' color='black' iconName='pencil' text='Editar' onClick={() => setShownModal(Modals.GIFT_DETAILS)} />
				)}
			>
				<Table.Row data={['Recibe', <>
					<Icon name='gift' />
					{orderDetails.gift_name} {(orderDetails.gift_last_name || '')}
				</>]}/>
				<Table.Row data={['Envía', `${orderDetails.owner_name} ${(orderDetails.owner_last_name || '')}`]}/>
				<Table.Row data={['Mensaje de regalo', orderDetails.gift_message]}/>
			</Table>
		) : null}
		<Table
			striped
			title='Artículos'
			style={{ maxWidth: 700, margin: 'auto', marginTop: 15 }}
			headers={itemsHeaders}
			collapsingIndexes={[0, 2, 3, 4, 5]}
			centeredIndexes={[2, 3, 4, 5]}
			button={(
				<div style={{ textAlign: 'end'}}>
					<Button size='tiny' color='black' iconName='plus circle' text='Agregar' onClick={() => setShownModal(Modals.SEARCH_PRODUCT)} />
				</div>
			)}
		>
			{orderProducts.length==0 ? (
				<Table.Cell row empty colSpan={5}>
					Esta orden no tiene artículos
				</Table.Cell>
			):orderProducts.map(a=>(
				<Table.Row 
					key={a.item_id}
					collapsingIndexes={[0, 5]}
					centeredIndexes={[2, 3, 4, 5]}
				>
					<Table.Cell><Image src={`${API.CDN_URL}/products/${a.product_id}/0.webp`} fallback='/placeholder.webp' style={{ width: 50, borderRadius: 8, height: 50*1.25 }} /></Table.Cell>
					<Table.Cell>{a.name}</Table.Cell>
					<Table.Cell>{addCommas(a.cost_single)}</Table.Cell>
					<Table.Cell>{addCommas(a.quantity)}</Table.Cell>
					<Table.Cell>{addCommas(a.cost)}</Table.Cell>
					<Table.Cell>
						<Dropdown icon={(
							<div className="fr label" style={{ padding: '3px 10px' }}>
								<Icon name='ellipsis horizontal' size='small' style={{ margin: 0 }} />
							</div>
						)} className='icon'>
							<Dropdown.Menu>
								<Dropdown.Item target='_blank' as={Link} to={`/products/${a.product_id}`}>
									<Icon name="eye" style={{ marginRight: 3 }} /> Ver producto 
								</Dropdown.Item>
								<Dropdown.Item target='_blank' onClick={ () => openUsersModal(a) }>
									<Icon name="user" style={{ marginRight: 3 }} /> Usuarios 
								</Dropdown.Item>
								<Dropdown.Item target='_blank' onClick={ () => showEditProductModal(a) }>
									<Icon name="pencil" style={{ marginRight: 3 }} /> Editar 
								</Dropdown.Item>
								<Dropdown.Item target='_blank' onClick={ () => openDeleteItemModal(a) }>
									<Icon name="trash" style={{ marginRight: 3 }} /> Eliminar 
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Table.Cell>
				</Table.Row>
			))}
		</Table>
		<Table
			selectable
			striped
			title='Pagos'
			style={{ maxWidth: 700, margin: 'auto', marginTop: 15 }}
			headers={['ID', 'Método', 'Estatus', 'Fecha', 'Monto']}
			data={orderDetails.payments.map(a=>([
				a.payment_id,
				a.method_name,
				<div className={classNames('fr label', {
					green: a.paid,
					red: !a.paid,
				})}>
					{a.paid ? 'Pagado' : 'No pagado'}
				</div>,
				moment.unix(a.date_paid || a.date_created).format('DD/MMM/YY HH:mm'),
				addCommas(a.amount),
			]))}
			onClick={a=>openInfoModal(parseInt(a[0]))()}
			emptyText='Esta orden no tiene pagos'
		/>
		{orderDetails.invoices.length>0 && (
			<Table
				striped
				title='Facturas'
				style={{ maxWidth: 700, margin: 'auto', marginTop: 15 }}
				headers={['Folio', 'Proveedor', 'RFC', 'Fecha', 'Estatus', '']}
				collapsingIndexes={[0, 5]}
				data={orderDetails.invoices.map(i=>([
					i.invoice_number,
					i.provider_name,
					i.rfc,
					moment.unix(i.date_created).format('DD/MMM/YY HH:mm'),
					<div className={classNames('fr label', {
						green: !i.cancelled,
						red: i.cancelled,
					})}>
						{i.cancelled ? 'Cancelada' : 'Timbrada'}
					</div>,
					<Dropdown icon={(
						<div className="fr label" style={{ padding: '3px 10px' }}>
							<Icon name='ellipsis horizontal' size='small' style={{ margin: 0 }} />
						</div>
					)} className='icon'>
						<Dropdown.Menu>
							<Dropdown.Item target='_blank' onClick={ viewFactura(i.invoice_id) }>
								<Icon name="eye" style={{ marginRight: 3 }} /> Ver Factura 
							</Dropdown.Item>
							<Dropdown.Item target='_blank' onClick={ downloadFactura(i.invoice_id) }>
								<Icon name="download" style={{ marginRight: 3 }} /> Descargar 
							</Dropdown.Item>
							{!i.cancelled && <Dropdown.Item target='_blank' onClick={ () => openCancelInvoiceModal(i) }>
								<Icon name="cancel" style={{ marginRight: 3 }} /> Cancelar 
							</Dropdown.Item>}
						</Dropdown.Menu>
					</Dropdown>
				]))}
				emptyText='Esta orden no tiene facturas'
			/>
		)}
		{!orderDetails.invoices.find(a=>!a.cancelled) && orderDetails.invoiceLogs.length !== 0 && (
			<Table
				striped
				title='Errores de facturación'
				style={{ maxWidth: 700, margin: 'auto', marginTop: 15 }}
				headers={['ID', 'RFC', 'Error', 'Fecha']}
				collapsingIndexes={[1, 3]}
				data={orderDetails.invoiceLogs.map(i=>([
					i.log_id,
					i.rfc,
					i.message,
					moment.unix(i.date_created).format('DD/MMM/YY HH:mm'),
				]))}
				emptyText='Esta orden no tiene errores de facturación'
			/>
		)}
		{orderDetails.pdv_id===null && (
			<Table
				details
				title='Preparación'
				style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}
				data={[
					['Estatus', <div className={classNames('fr label', {
						blue: !orderDetails.prepared,
						green: orderDetails.prepared
					})}>
						{orderDetails.prepared ? 'Ready' : 'En preparación'}
					</div>],
					...(orderDetails.date_prepared ? [[
						'Fecha ready', moment.unix(orderDetails.date_prepared).format('DD/MMM/YY HH:mm'),
					]] : []),
					...(orderDetails.user_assigned ? [
						null,
						['Asignada', `${orderDetails.assigned_user.first_name} ${orderDetails.assigned_user.last_name}`],
						['Fecha asignación', moment.unix(orderDetails.date_assigned).format('DD/MMM/YY HH:mm')]
					] : []),
				]}
			/>
		)}
		<Modal open={shownModal==Modals.SHIPPING} size='mini' onClose={bindClick(setShownModal)}>
			<Modal.Header>{`${orderDetails.shipping_type===ShippingType.SHIPPING ? 'Enviar' : 'Entregar'} orden`}</Modal.Header>
			<Modal.Content>	
				<Header text={`${orderDetails.shipping_type===ShippingType.SHIPPING ? 'Enviar' : 'Entregar'} orden`}/>
				{orderDetails.shipping_type===ShippingType.SHIPPING && (
					<Checkbox toggle label='Incluir datos de paquetería' checked={shippingForm.include_tracking_number} onChange={onCreateFormChange('include_tracking_number', true)}/>
				)}
				{shippingForm.include_tracking_number && <>
					<Input style={{ marginTop: 15 }} label='Paquetería' value={shippingForm.shipping_handler} onChange={onCreateFormChange('shipping_handler')}/>
					<Input style={{ marginTop: 15 }} label='Número de guía' value={shippingForm.tracking_number} onChange={onCreateFormChange('tracking_number')}/>
				</>}
				<Field label='Notificación' style={{ marginTop: 15}}>
					<Dropdown selection value={shippingForm.notification} onChange={onCreateFormChange('notification', true)} placeholder='Notificación' options={[
						{ value: SendType.NONE, text: 'No notificar cliente' },
						{ value: SendType.EMAIL, text: 'Enviar Correo' },
						{ value: SendType.SMS, text: 'Enviar SMS' },
					]} />
				</Field>
				<Message type="error" list={modalPrompts}/>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setShownModal)} basic />
				<Button text={orderDetails.shipping_type===ShippingType.PICKUP ? 'Guardar' : 'Enviar'} onClick={updateShippingDetails} color='black'/>
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.SHIPPING_DATE} size='mini' onClose={bindClick(setShownModal)}>
			<Modal.Header>Fecha de envío</Modal.Header>
			<Modal.Content>
				<Input label='Fecha' value={shippingForm.shipping_date} onChange={onCreateFormChange('shipping_date')} calendar={{ 
					date: shippingForm.shipping_date,
					initialDate: moment().unix(),
					mode: 'date', 
					format: 'DD/MM/YYYY'
				}}/>
				<Message type="error" list={modalPrompts}/>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setShownModal)} basic />
				<Button text='Enviar' onClick={editShippingDate} color='black'/>
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.EDIT_INFO} size='mini' onClose={bindClick(setShownModal)}>
			<Modal.Header>Modificar datos</Modal.Header>
			<Modal.Content>
				<Input label='Nombre' value={orderDetails.owner_name} onChange={onEditChange('owner_name')}/>
				<Input label='Apellido' value={orderDetails.owner_last_name} onChange={onEditChange('owner_last_name')}/>
				<Input label='Correo electrónico' value={orderDetails.owner_email} onChange={onEditChange('owner_email')}/>
				<Input label='Teléfono' value={orderDetails.owner_phone} onChange={onEditChange('owner_phone')}/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setShownModal)} basic />
				<Button text='Guardar' onClick={update} color='black'/>
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.RESEND} size='mini' onClose={bindClick(setShownModal)}>
         <Modal.Header>Reenviar orden</Modal.Header>
         <Modal.Content>
				<Header text={`¿Deseas reenviar la notificación al cliente?`} subtext='Se le reenviará la notificación de compra'/>
				{!!orderDetails.owner_email && orderDetails.owner_email.length>=5 ? (
					<Field label='Tipo de reenvío'>
						<Dropdown selection value={resendForm.type} onChange={onEditResendForm('type', true)} placeholder='Tipo de reenvío' options={[
							{ value: ResendType.EMAIL, text: 'Correo electrónico' },
							{ value: ResendType.SMS, text: 'Mensaje SMS' },
						]} />
					</Field>
				) : (
					<Message text='Esta orden no tiene un correo electrónico asigando' centered style={{ marginBottom: 10 }} />
				)}
				{resendForm.type===ResendType.EMAIL ? (
					<Input label='Correo electrónico' value={orderDetails.owner_email} readonly />
				) : resendForm.type===ResendType.SMS ?(
					<Input label='Teléfono' value={resendForm.phone} onChange={onEditResendForm('phone')}  />
				) : null}
				<Message type="error" list={modalPrompts}/>
         </Modal.Content>
         <Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setShownModal)} basic />
				<Button text='Enviar' color='black' disabled={resendForm.type===null} onClick={resendOrder}/>
			</Modal.Actions>
		</Modal>
		<Modal size='tiny' open={shownModal===Modals.ADD_PAYMENT} onClose={closeCreatePaymentModal}>
			<Modal.Header>Registrar pago</Modal.Header>
			{!!paidForm && (
				<Modal.Content>
					<Input 
						readonly={!!paidForm.complete_payment} 
						label='Cantidad pagada' 
						value={paidForm.complete_payment ? (orderDetails.total-amount_paid) : paidForm.amount} 
						onChange={onCreatePaidForm('amount')}
						inputStyle={{ textAlign: 'center', fontWeight: 'bold', fontSize: 28 }}
					/>
					<Checkbox toggle style={{ marginTop: 5 }} checked={!!paidForm.complete_payment} label='Completar pago' onChange={onCreatePaidForm('complete_payment', true)}/>
					{!!paymentMethods ? (
						<Field label='Metodo de pago' style={{ marginTop: 15 }}>
							<Dropdown
								placeholder='Metodo de pago'
								fluid
								selection
								options={paymentMethods.map(method => ({
									key: method.method_id,
									text: method.method_name_localized,
									value: method.method_id
								}))}
								style={{marginBottom: 10}}
								value={paidForm.payment_method}
								onChange={(e, { value }) => selectPaymentMethod(value)}
							/> 
						</Field>
					) : (
						<Header size='small' loading text="Cargando formas de pago" />
					)}
					{!!cortes ? (
						<Field label='Corte' style={{ marginTop: 15 }}>
							<Dropdown
								placeholder='Corte'
								fluid
								selection
								options={cortes.map(c => ({
									key: c.corte_id,
									text: `${c.name} - ${c.corte_id}`,
									value: c.corte_id
								}))}
								style={{marginBottom: 10}}
								value={paidForm.corte_id}
								onChange={onCreatePaidForm('corte_id', true)}
							/> 
						</Field>
					) : loadingCortes ? (
						<Header size='small' loading text="Cargando cortes" />
					) : null }
					<Input label='Fecha de pago' value={paidForm.date_paid} onChange={onCreatePaidForm('date_paid')} calendar={{ 
						date: paidForm.date_paid, 
						mode: 'date', 
						format: 'DD/MM/YYYY',
						initialDate: moment().unix(),
					}}/>
					<Input label='Referencia de pago' comment='Opcional' value={paidForm.reference} onChange={onCreatePaidForm('reference')} />
					<Message type="error" list={modalPrompts}/>
				</Modal.Content>
			)}
			<Modal.Actions>
				<Button text='Cerrar' onClick={closeCreatePaymentModal} basic />
				<Button text='Registrar' color='black' onClick={createPayment}/>
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.PREPARE} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>{orderDetails.prepared ? 'Unready' : 'Marcar ready'}</Modal.Header>
			{!!orderDetails && <Modal.Content>
				{!orderDetails.prepared ? <Header text='¿Marcar orden como ready para entrega?' subtext='La orden ya está ready para ser entregada' subheaderStyle={{ marginTop: 8, marginBottom: 15 }} /> : <Header text='¿Marcar orden como unready?' subtext='La orden dejara de estar ready.' subheaderStyle={{ marginTop: 8, marginBottom: 15 }} />}
				<Table
					details
					titleSize='small'
					title='Detalles'
					data={[
						['Orden', orderDetails?.order_id],
						['Productos', orderProducts?.length],
						['Fecha ready', moment().format('DD/MMM/YY HH:mm')],
					]}
				/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<ModalActions>
				<Button text='Cerrar' basic style={{ minWidth: 20 }} onClick={bindClick(setShownModal)} />
				{!orderDetails.prepared ? <Button onClick={prepare} text='Preparar' color='black'/> : <Button onClick={unprepare} text='Unready' color='black'/>}
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.ASSIGN} size='mini' onClose={bindClick(showModal)}>
			<Modal.Header>Asignar a usuario</Modal.Header>
			<Modal.Content>
				{usersLoading ? <Header loading text='Cargando usuarios' /> :!usersLoading && !!users ? <>
					<Input placeholder='Buscar usuarios' style={{ marginTop: -10, marginBottom: 10 }} onChange={setUserSearch} value={userSearch} />
					<Items
						toggle
						single
						selectable
						data={users.filter(a=>{
							return normalizeSync(`${a.first_name} ${a.last_name}`).toUpperCase().includes(normalizeSync(userSearch).toUpperCase());
						}).sort(firstBy('first_name', 'asc'))}
						onSelected={a=>setSelectedUser(a.user_id)}
						selected={selectedUser}
						valueExtractor={a=>a.user_id}
						render={u=><>
							<div className="left">
								{u.first_name} {u.last_name}
							</div>
							<div className="right" style={{ fontSize: 12, color: 'gray' }}>
								{u.alias.toUpperCase()}
							</div>
						</>}
					/>
				</> : (
					<Header loading text='Cargando usuarios' />
				)}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(showModal)} basic />
				{<Button text='Guardar' color='black' onClick={assign} />}
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.COMMENTS} size='tiny' onClose={bindClick(showModal)}>
			<Modal.Header>Agregar comentarios</Modal.Header>
			<Modal.Content>
				<Input textarea textareaRows={10} label='Comentarios' value={comments} onChange={setComments}/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(showModal)} basic />
				{<Button text='Guardar' color='black' onClick={addComments} />}
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.INVOICE} size='tiny' onClose={bindClick(showModal)}>
			<Modal.Header>Facturar</Modal.Header>
			{invoiceForm && <Modal.Content>
				<Field label='Forma de pago'>
					<Dropdown
						placeholder='Forma de pago'
						search
						fluid
						selection
						options={invoicePayment.map(p => ({
							key: p.clave,
							text: p.description,
							value: p.clave,
							description: p.clave
						}))}
						style={{ marginBottom: 10 }}
						value={invoiceForm.payment_form}
						onChange={onCreateInvoiceForm('payment_form', true)}
					/>
				</Field>
				<Field label='Metodo de pago'>
					<Dropdown
						placeholder='Metodo de pago'
						fluid
						selection
						options={[
							{ value: "PUE", text: "Pago en una sola exhibición (de contado)", description: 'PUE' },
    						{ value: "PPD", text: "Pago en parcialidades o diferido (total o parcialmente a crédito).", description: 'PPD' }
						]}
						style={{marginBottom: 10}}
						value={invoiceForm.payment_method}
						onChange={onCreateInvoiceForm('payment_method', true)}
					/>
				</Field>
				{!!taxDataList ? (
					<Field label='Datos fiscales'>
						<Dropdown
							placeholder='Datos fiscales'
							fluid
							selection
							selectOnBlur={false}
							options={[
								{ key: -1, value: -1, text: <i>Agregar datos fiscales</i> },
								...taxDataList.map(tax => ({
									key: tax.tax_id,
									text: `${tax.legal_name.substring(0, 16)} (${tax.rfc})`,
									value: tax.tax_id
								}))
							]}
							style={{ marginBottom: 10 }}
							value={invoiceForm.tax_id}
							onChange={(e, d)=>{
								if(d.value===-1){
									showModal(Modals.TAX_DATA_MODAL);
									return;
								}

								return onCreateInvoiceForm('tax_id', true)(e, d);
							}}
						/>
					</Field>
				) : (
					<Header size='small' loading text="Cargando datos fiscales." />
				)}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(showModal)} basic />
				{<Button text='Facturar' color='black' onClick={createInvoice} />}
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.CANCEL_INVOICE} onClose={bindClick(setShownModal)} size='small'>
			<Modal.Header>Cancelar Factura</Modal.Header>
			{!!orderDetails && <Modal.Content>
				<Header text='¿Cancelar factura?' subheaderStyle={{ marginTop: 8, marginBottom: 15 }} />
				{currentInvoice &&<Table
					details
					titleSize='small'
					title='Detalles'
					data={[
						['Factura', currentInvoice.invoice_id],
						['Referencia', currentInvoice.external_id],
						['Fecha facturacion',  moment.unix(currentInvoice.date_created).format('DD/MMM/YY HH:mm')],
					]}
				/>}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<ModalActions>
				<Button text='Cerrar' basic onClick={bindClick(setShownModal)} />
				<Button onClick={cancelInvoice} text='Cancelar' color='red'/>
			</ModalActions>
		</Modal>
		<TaxDataModal
			open={shownModal===Modals.TAX_DATA_MODAL}
			onClose={bindClick(setShownModal)}
			onCreate={onTaxInfoCreated}
			deleted={1}
		/>
		<div style={{ height: 50 }} />
		<Modal open={shownModal===Modals.CANCEL} onClose={bindClick(setShownModal)} size='small'>
			<Modal.Header>Cancelar Orden</Modal.Header>
			{!!orderDetails && <Modal.Content>
				<Header text='¿Cancelar Orden?' subheaderStyle={{ marginTop: 8, marginBottom: 15 }} />
				{orderDetails &&<Table
					details
					titleSize='small'
					title='Detalles'
					data={[
						['Orden', orderDetails.order_id],
						['Hash', orderDetails.order_hash],
						['Estatus', <>
							<div className={classNames('fr tiny label', {
								red: !orderDetails.paid,
								green: orderDetails.paid,
							})}>
								{orderDetails.paid ? 'Pagado' : 'No pagado'}
							</div>
							{!!orderDetails.shipped && (
								<div className="fr tiny label blue" style={{ marginLeft: 5 }}>
									Enviado
								</div>
							)}
						</>],
						['Cliente', `${orderDetails.owner_name} ${(orderDetails.owner_last_name || '')}`],
						['Fecha facturacion',  moment.unix(orderDetails.date_created).format('DD/MMM/YY HH:mm')],
						['Total', addCommas(orderDetails.total)],
					]}
				/>}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<ModalActions>
				<Button text='Cerrar' basic style={{ minWidth: 20 }} onClick={bindClick(setShownModal)} />
				<Button onClick={cancelOrder} text='Cancelar' color='red'/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.SHIPPING_DETAILS} onClose={bindClick(setShownModal)} size='tiny'>
			<Modal.Header>Dirección de envío</Modal.Header>
			<Modal.Content>
				<Input label='Nombre' placeholder='Nombre' value={shippingDetailsForm.name} onChange={onEditShippingDetails('name')}/>
				<Input label='Dirección' placeholder='Dirección' value={shippingDetailsForm.street} onChange={onEditShippingDetails('street')}/>
				<Input label='Colonia' placeholder='Colonia' value={shippingDetailsForm.neighborhood} onChange={onEditShippingDetails('neighborhood')}/>
				<Field amount={2}>
					<Input label='No. Exterior' placeholder='No. Exterior' value={shippingDetailsForm.exterior_number} onChange={onEditShippingDetails('exterior_number')}/>
					<Input label='No. Interior' placeholder='No. Interior' value={shippingDetailsForm.interior_number} onChange={onEditShippingDetails('interior_number')}/>
				</Field>
				<Field amount={2}>
					<Input label='Ciudad' placeholder='Ciudad' value={shippingDetailsForm.city} onChange={onEditShippingDetails('city')}/>
					<Input label='Código postal' placeholder='Código postal' value={shippingDetailsForm.zipcode} onChange={onEditShippingDetails('zipcode')}/>
				</Field>
				<Input label='Estado' placeholder='Estado' value={shippingDetailsForm.state} onChange={onEditShippingDetails('state')}/>
				<Input label='Teléfono' placeholder='Nombre' value={shippingDetailsForm.phone} onChange={onEditShippingDetails('phone')}/>
				<Input label='Referencia' placeholder='Referencia' value={shippingDetailsForm.comments} onChange={onEditShippingDetails('comments')} textarea/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<ModalActions>
				<Button text='Cancelar' basic style={{ minWidth: 20 }} onClick={bindClick(setShownModal)} />
				<Button onClick={editShippingDetails} text='Guardar' color='black'/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.SHIPPING_SUMMARY} onClose={bindClick(setShownModal)} size='tiny'>
			<Modal.Header>Resumen datos de envío</Modal.Header>
			<Modal.Content>
				{shippingDetails && <Input textarea textareaRows={10} label='Resumen' value={getShippingSummary()} readonly/>}
				<Field style={{ textAlign: 'center' }}>
					<Button text='Map' onClick={openMaps} size='small'/>
				</Field>
			</Modal.Content>
			<ModalActions>
				<Button text='Cerrar' basic style={{ minWidth: 20 }} onClick={bindClick(setShownModal)} />
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.NOTIFICATION} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Notificar cliente</Modal.Header>
			<Modal.Content>
				<Header text={'Notificar cliente'} subtext='Se le enviará un SMS al cliente que su orden se encuentra lista para recoger.' style={{ marginTop: 0 }} />
				<Input label='Teléfono' placeholder='Teléfono' value={notificationPhone} onChange={setNotificationPhone}/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<ModalActions>
				<Button text='Cerrar' basic style={{ minWidth: 20 }} onClick={bindClick(setShownModal)} />
				<Button text='Enviar' color='black' style={{ minWidth: 20 }} onClick={smsNotification}/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.UNSHIPPED} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Cancelar entregada</Modal.Header>
			<Modal.Content>
				<Header text='¿Marcar orden como no enviada?' subtext='La orden dejara de estar marcada como enviada.' subheaderStyle={{ marginTop: 8, marginBottom: 15 }} />
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<ModalActions>
				<Button text='Cerrar' basic style={{ minWidth: 20 }} onClick={bindClick(setShownModal)} />
				<Button text='Guardar' color='black' style={{ minWidth: 20 }} onClick={unshipped}/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.EDIT_METHOD} onClose={bindClick(setShownModal)} size='small'>
			<Modal.Header>Editar tipo de orden</Modal.Header>
			<Modal.Content>
				<Header text={`Esta orden es de tipo: ${orderMethods.find(method => method.method_id === orderDetails.order_method)?.method_name}`} style={{ fontSize: 22, marginBottom: 20}} size='16'/>
				<Field label='Nuevo tipo de orden'>
					<Dropdown selection placeholder='Nuevo tipo de orden' value={newOrderMethod.method_id} onChange={onEditOrderMethod('method_id', true)}
						options={orderMethods.map(method => ({
							key: method.method_id,
							text: method.method_name,
							value: method.method_id
						}))}
					/>
				</Field>
				{newOrderMethod.method_id && <Table
					details
					titleSize='small'
					title='Detalles'
					data={[
						['Actual', orderMethods.find(method => method.method_id === orderDetails.order_method)?.method_name],
						['Nuevo', orderMethods.find(method => method.method_id === newOrderMethod.method_id)?.method_name],
					]}
				/>}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<ModalActions>
				<Button text='Cerrar' basic style={{ minWidth: 20 }} onClick={bindClick(setShownModal)} />
				<Button text='Guardar' color='black' style={{ minWidth: 20 }} onClick={editMethod}/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.GIFT_DETAILS} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Datos de regalo</Modal.Header>
			{giftForm && <Modal.Content>
				<Input label='Nombre' placeholder='Nombre' value={giftForm.gift_name} onChange={onEditGiftInfo('gift_name')}/>
				<Input label='Apellido' placeholder='Apellido' value={giftForm.gift_last_name} onChange={onEditGiftInfo('gift_last_name')}/>
				<Input label='Teléfono' placeholder='Teléfono' value={giftForm.gift_phone} onChange={onEditGiftInfo('gift_phone')}/>
				<Input textarea label='Mensaje' placeholder='Mensaje' value={giftForm.gift_message} onChange={onEditGiftInfo('gift_message')}/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<ModalActions>
				<Button text='Cerrar' basic style={{ minWidth: 20 }} onClick={bindClick(setShownModal)} />
				<Button text='Guardar' color='black' style={{ minWidth: 20 }} onClick={editGift}/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.EDIT_ITEM_MODAL} onClose={newProduct ? closeAddItemModal : bindClick(setShownModal)} size='tiny'>
			<Modal.Header>{newProduct ? 'Agregar Producto' : 'Editar Producto'}</Modal.Header>
			{selectedItem && <Modal.Content>
				<Header text={selectedItem.name} style={{ marginBottom: 20}}/>
				<Input label='Cantidad' inputStyle={{ textAlign: 'center', fontSize: 30, fontWeight: 'bold' }} value={selectedItem.quantity} onChange={onEditItem('quantity')}/>
				<Stat.Group style={{ alignItems: 'center' }}>
					<Stat label='Precio' value={(
						<Input value={selectedItem.cost_single} onChange={onEditItem('cost_single')} placeholder='Precio' inputStyle={{ textAlign: 'center', fontWeight: 'bold', fontSize: 28, padding: 5 }} style={{ marginBottom: 5 }} />
					)} />
					<Stat label='Subtotal' value={addCommas(((selectedItem.cost_single) || 0)*((selectedItem.quantity) || 0))} />
				</Stat.Group>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={newProduct ? closeAddItemModal : bindClick(setShownModal)} />
				{newProduct ? <Button text='Agregar' color='black' onClick={addItem}/> : <Button text='Guardar' color='black' onClick={editItem}/>}
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.DELETE_ITEM} onClose={bindClick(setShownModal)} size='tiny'>
			<Modal.Header>Eliminar Producto</Modal.Header>
			{selectedItem && <Modal.Content>
				<Header text='¿Eliminar producto de orden?' subheaderStyle={{ marginTop: 8, marginBottom: 15 }} />
				<Table
					details
					titleSize='small'
					style={{ marginTop: 20}}
					title='Detalles'
					data={[
						['Producto', selectedItem.name],
						['Cantidad', selectedItem.quantity],
						['Precio', '$' + addCommas(selectedItem.cost_single)],
						['Subtotal', '$' + addCommas(selectedItem.cost)],
					]}
				/>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<ModalActions>
				<Button text='Cerrar' basic onClick={bindClick(setShownModal)} />
				<Button text='Eliminar' color='red' onClick={deleteProduct}/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.ITEM_USER_INFO} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Usuarios</Modal.Header>
			{selectedItem && <Modal.Content>
				<UserLog user={selectedItem}/>
			</Modal.Content>}
			<ModalActions>
				<Button text='Cerrar' basic onClick={bindClick(setShownModal)} />
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.SHIPPING_LOCATION_DELETE} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Eliminar Datos de Envío</Modal.Header>
			{shippingDetails && <Modal.Content>
				<Header text='¿Eliminar datos de envio de orden?' subheaderStyle={{ marginTop: 8, marginBottom: 15 }} />
				<Table
					details
					titleSize='small'
					style={{ marginTop: 20}}
					title='Detalles'
					data={[
						['Calle', shippingDetails.street],
						['Número', shippingDetails.exterior_number],
						['Colonia', shippingDetails.neighborhood],
					]}
				/>
			</Modal.Content>}
			<ModalActions>
				<Button text='Cancelar' basic onClick={bindClick(setShownModal)} />
				<Button text='Eliminar' color='red' onClick={deleteShippingLocation}/>
			</ModalActions>
		</Modal>
		<PaymentInfoModal
			open={shownModal===Modals.PAYMENT_INFO}
			onClose={bindClick(setShownModal)}
			payment_id={selectedPayment?.payment_id}
			onPaymentDelete={(p) => paymentDelete()}
			onPaymentPaid={(p) => paymentPaid()}
			editable
		/>
		<SearchProductsModal
			open={shownModal===Modals.SEARCH_PRODUCT}
			onClose={bindClick(setShownModal)}
			onSelected={(item) => openAddItemModal(item) }
		/>
		<Modal open={shownModal===Modals.PARTIAL_PAYMENT} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Generar link de pago parcial</Modal.Header>
			<Modal.Content>
				<Field>
					<Input label='Monto' placeholder='Monto de pago parcial' value={partialPaymentAmount} onChange={setPartialPaymentAmount}/>
				</Field>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<ModalActions>
				<Button text='Cancelar' basic onClick={bindClick(setShownModal)} />
				<Button text='Generar' color='black' onClick={partialPayment}/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.PAID_ORDER} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Marcar orden como pagada</Modal.Header>
			{!!orderDetails && <Modal.Content>
				<Header text='¿Pagar Orden?' subheaderStyle={{ marginTop: 8, marginBottom: 15 }} />
				{orderDetails &&<Table
					details
					titleSize='small'
					title='Detalles'
					data={[
						['Orden', orderDetails.order_id],
						['Hash', orderDetails.order_hash],
						['Estatus', <>
							<div className={classNames('fr tiny label', {
								red: !orderDetails.paid,
								green: orderDetails.paid,
							})}>
								{orderDetails.paid ? 'Pagado' : 'No pagado'}
							</div>
							{!!orderDetails.shipped && (
								<div className="fr tiny label blue" style={{ marginLeft: 5 }}>
									Enviado
								</div>
							)}
						</>],
						['Cliente', `${orderDetails.owner_name} ${(orderDetails.owner_last_name || '')}`],
						['Fecha facturacion',  moment.unix(orderDetails.date_created).format('DD/MMM/YY HH:mm')],
						['Total', addCommas(orderDetails.total)],
					]}
				/>}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<ModalActions>
				<Button text='Cancelar' basic onClick={bindClick(setShownModal)} />
				<Button text='Guardar' color='black' onClick={paidOrder}/>
			</ModalActions>
		</Modal>
		<Modal open={shownModal===Modals.SHIPMENT} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Modificar forma de envío</Modal.Header>
			{shipmentMethods.length !== 0 &&<Modal.Content>
				<Field label='Forma de envío'>
					<Dropdown
						placeholder='Formas de envío'
						fluid
						selection
						options={shipmentMethods.map(method => ({
							key: method.method_id,
							text: method.name,
							value: method.method_id
						}))}
						style={{marginBottom: 10}}
						value={newShipment.mehod_id}
						onChange={onEditShipment('mehod_id', true)}
					/>
				</Field>
				<Field>
					<Input label='Costo de envío' placeholder='Costo de envío' value={newShipment.shipping_cost} onChange={onEditShipment('shipping_cost')}/>
				</Field>
				<Message type="error" list={modalPrompts} />
			</Modal.Content>}
			<ModalActions>
				<Button text='Cancelar' basic onClick={bindClick(setShownModal)} />
				<Button text='Guardar' color='black' onClick={editShipmentMethod}/>
			</ModalActions>
		</Modal>
	</div>	
}

export default OrderView;

import React, { ElementType, useEffect, useState } from 'react';
import { addCommas } from '../Util';
import { Image } from 'react-frontier';
import classNames from 'classnames';

import './style/product_card.scss';

type ProductProps<E extends React.ElementType> = {
	as?: E,
	className?: string,
	style?: React.CSSProperties,
	image: string,
	product: {
		product_id: number,
		message?: string,
		product_name: string,
		price_discounted?: number,
		price: number,
		size?: string,
	},
	placeholder?: boolean,
	placeholderActive?: boolean,
	placeholderImage?: string,
	selectable?: boolean,
	onClick?: ()=>void,
} & React.ComponentPropsWithoutRef<E>;

var Product = <E extends ElementType>(props: ProductProps<E>)=>{
	var { as, className, style, image, product, placeholder, placeholderActive, placeholderImage, onClick, ...restProps } = props;
	const Element = props.as || 'div';
	
	return <Element onClick={props.onClick} className={classNames('co product card', {
		placeholder: props.placeholder,
		active: props.placeholderActive,
		link: props.selectable,
	}, props.className)} style={props.style} {...restProps}>
		{!props.placeholder ? <>
			<div className="image">
				<Image src={props.image} fallback={props.placeholderImage || '/placeholder.webp'}/>
				{!!props.product.message && props.product.message.length>0 && (
					<div className="message">{props.product.message}</div>
				)}
			</div>
			<div className="info">
				<div className="name">{props.product.product_name}</div>
				<div className="price">
					{props.product.price_discounted ? <>
						<div className="final">
							${addCommas(props.product.price_discounted, true, true)}
						</div>
						<div className="discounted">
							${addCommas(props.product.price, true, true)}
						</div>
					</> : (
						`$${addCommas(props.product.price, true, true)}`
					)}
					<div className="size">{props.product.size}</div>
				</div>
			</div>
		</> : <>
			<div className="image"></div>
			<div className="info">
				<div className="name"></div>
				<div className="price"></div>
			</div>
		</>}
	</Element>
}

export default Product;

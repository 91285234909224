import React, { useEffect, useState } from 'react';
import API from '../API';
import { Shipping } from '../Classes';
import { Table, Header, Pagination, Button } from 'react-frontier';
import { addCommas } from '../Util';
import { Icon } from 'semantic-ui-react';


import { useTitle } from '../Hooks';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const PAGE_SIZE = 20;

var ShipmentMethods = ()=>{
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [methods, setMethods] = useState<Shipping[]>(null);
	var [page, setPage] = useState<number>(0);
	var [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		get();
		setTitle('Metodos de envío');
	}, []);

	var get = (get_page?: number, only_enabled: boolean=true)=>{
		var offset = (get_page > 0) ? Math.max(0, (get_page || page) * PAGE_SIZE) : 0;
		
		setLoading(true);
		API.shippingList(offset, PAGE_SIZE, only_enabled).then(list=>{
			if(list.error) return setLoadError(list.message);
			return setMethods(list.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener los metodos de envío. (GSMT-1)'); 
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onPageChange = (page: number)=>{
      setLoading(true)
		setPage(page);
		get(page);
	}
	
	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!methods){
		return <Header loading text='Cargando datos' />
	}

	return <div>
		<Table
			selectable
			title='Metodos de envío'
			style={{ maxWidth: 600, margin: 'auto' }}
			headers={['ID', 'Nombre', 'Costo', 'Tipo', 'Estatus']}
			emptyText={methods.length === 0 && 'No se encontraron registros.'}
			collapsingIndexes={[0, 2, 3, 4]}
			button={
				<Button text='Mostrar todos' size='tiny' color='black' onClick={()=>get(null, false)}/>
			}
			footer={
				methods.length >= PAGE_SIZE || page !== 0 ? (
					[<div style={{ textAlign: 'right' }}><Pagination page={page} onPageChange={onPageChange} /></div>]
				) : null
			}
		>
			{loading ? <Table.Row><Table.Cell className='empty' colSpan={7}><Header loading text='Cargando datos' /></Table.Cell></Table.Row> :
				methods.map(m=>(
					<Table.Row
						compact
						key={m.method_id}
						collapsingIndexes={[0, 2, 3, 4]}
						data={[
							m.method_id,
							m.name,
							addCommas(m.cost),
							m.type_id === 1 && m.local ? 'Local' : m.type_id === 1 ? 'Envio' : 'Pickup',
							<div className={classNames('fr tiny label', {
								red: !m.enabled,
								green: m.enabled 
							})}>
								{m.enabled ? 'Habilitado' : 'Deshabilitado'}
							</div>
						]}
						as={Link}
						to={`/shipments/methods/${m.method_id}`} 
					/>
			))}
		</Table>
	</div>
}

export default ShipmentMethods;
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Icon, Checkbox, Dropdown } from 'semantic-ui-react'
import { bindClick, addCommas, bindFormChange } from '../Util';
import { CartItem, CategoryCard, ProductCard } from '@cocoan/components'
import { Category as CategoryClass, PaymentMethod, SalesChannel, SetLoading, CreateOrderForm, ShippingMethod, RFC } from '../Classes';
import { WebProduct } from '@cocoan/components/Classes'
import { useTitle } from '../Hooks';
import { Stat, Button, Input, Field, Table, Message, Header } from 'react-frontier';
import { filterSearch, toCents } from '@cocoan/components/Util';
import { Link } from 'react-router-dom';
import { firstBy } from 'thenby';
import API from '../API';
import Validator from '../Validator';
import Toast from 'react-hot-toast';
import moment from 'moment';
import { TaxDataModal } from '../components';

import '../style/order_creator.scss'

enum Modals{
	ADD_PRODUCT = 1,
	COMPLETE_ORDER = 2,
	FINISHED_ORDER = 3,
}

interface CreatedOrder{
	order_id: number,
	order_key: string,
	order_hash: string,
	owner_name: string,
	owner_last_name: string,
	owner_email: string,
	owner_phone: string,
	referal: string,
	paid: boolean,
	total: number,
	token: string,
}

var NewOrderView = ()=>{
	var { setTitle } = useTitle();
	var addInputRef = useRef<HTMLInputElement>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [selectedCategory, setSelectedCategory] = useState<number>(null);
	var [categories, setCategories ] = useState<CategoryClass[]>(null);
	var [shownModal, setShownModal] = useState<Modals>(null);
	var [products, setProducts] = useState<{ category_id: number, products: WebProduct[] }[]>([]);
	var [searchProducts, setSearchProducts] = useState<WebProduct[]>(null);
	var [selectedProduct, setSelectedProduct] = useState<WebProduct>(null);
	var [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>(null);
	var [cart, setCart] = useState<(WebProduct & { amount: number, price_fix: number })[]>([]);
	var [editingItem, setEditingItem] = useState<number>(null);
	var [searchQuery, setSearchQuery] = useState<string>('');
	var [addAmount, setAddAmount] = useState<string>('');
	var [priceFix, setPriceFix] = useState<string>('');
	var [searchTimeout, setSearchTimeout] = useState<any>(null);
	var [createdOrder, setCreatedOrder] = useState<CreatedOrder>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [completeForm, setCompleteForm] = useState<CreateOrderForm>(null);
	const SIDEBAR_BREAKPOINT = 768;
	var [mobile, setMobile] = useState<boolean>(false);
	var [showCart, setShowCart] = useState<boolean>(false);
	var [taxDataList, setTaxDataList] = useState<RFC[]>([]);
	var [taxDataLoading, setTaxDataLoading] = useState<boolean>(false);
	var [taxDataModal, setTaxDataModal] = useState<boolean>(false);
	
	useEffect(() => {
		getCategories();
		setTitle(`Crear Orden`);
	}, []);

	useEffect(()=>{
		var onResize = ()=>{
			if(!mobile && window.innerWidth<=SIDEBAR_BREAKPOINT){
				setMobile(true);
			}else if(mobile && window.innerWidth>SIDEBAR_BREAKPOINT){
				setMobile(false);
			}
		}
		window.addEventListener('resize', onResize);
		setMobile(window.innerWidth<=SIDEBAR_BREAKPOINT)
		return ()=>{
			window.removeEventListener('resize', onResize);
		}
	}, [mobile]);

	useEffect(() => {
		if(completeForm && completeForm.order_method === SalesChannel.B2B && !completeForm.owner_email){
			getTaxInfoList();
		}
	}, [completeForm]);

	var getCategories = ()=>{
		API.getCategories().then(res=>{
			if(res.error) return setLoadError(res.message);
			setCategories(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando las categorías (LCL-1)');
		})
	}

	var getTaxInfoList = ()=>{
		setTaxDataLoading(true);
		API.getRfcList(1).then(res=>{
			if(res.error) return setLoadError(res.message);
			setTaxDataList(res.data);
			setTaxDataLoading(false);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando los datos fiscales (LCL-1)');
		}).finally(()=>{
		})
	}

	var onTaxInfoCreated = (form: RFC) => {
		var list = [...taxDataList];
		list.push(form);
		setTaxDataList(list);
		setTaxDataModal(false);
	}


	if(loadError && !categories){
		return <Header text='Error' subtext={loadError} />
	}

	var showModal = (modal: Modals)=>{
		return setShownModal(modal);
	}


	var getProducts = (category_id: number)=>{
		API.getCategoryProducts(category_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			var pr = [...products];
			pr.push({
				category_id,
				products: res.data.products
			});
			setProducts(pr);
		}).catch(err=>{
			return setLoadError('Hubo un error iensperado cargando los productos (LCL-1)');
		})
	}

	var viewCategory = (category_id: number)=>{
		return ()=>{
			setSelectedCategory(category_id);
			setSearchQuery('');
			if(category_id){
				var catprods = products.find(a=>a.category_id==category_id);
				if(!catprods){
					getProducts(category_id);
				}
			}
		}
	}

	var productClick = (p: WebProduct)=>{
		return ()=>{
			if(p.price_gram){
				setSelectedProduct(p);
				setAddAmount('');
				setPriceFix((p.price_gram || p.price).toFixed(2));
				showModal(Modals.ADD_PRODUCT);
				setTimeout(()=>{
					addInputRef.current?.focus();
				}, 200);
			}else{
				addProduct(p, 1, null)();
			}
		}
	}

	var addProduct = (p: WebProduct, amount: number, price_fix: number=null)=>{
		return ()=>{
			if(Number.isNaN(amount)){
				return Toast.error('La cantidad a agregar no es válida.');
			}
			if(price_fix){
				if(toCents(price_fix)==toCents(p.price_gram || p.price)){
					price_fix = null;
				}
			}

			var new_cart = cart ? [...cart] : [];
			var ix = (editingItem!==null) ? editingItem : new_cart.findIndex(a=>a.product_id==p.product_id && toCents(a.price_fix)===toCents(price_fix));

			if(ix==-1){
				new_cart.push({
					...p,
					amount,
					price_fix,
				});
			}else{
				if(editingItem!==null){
					new_cart[ix].amount = amount;
					new_cart[ix].price_fix = price_fix;
				}else{
					new_cart[ix].amount += amount;
				}
			}
			setCart(new_cart);
			setEditingItem(null);
			setShownModal(null);
		}
	}

	var removeItem = (ix: number)=>{
		return ()=>{
			if(!cart || cart.length==0) return;
			var c = [...cart];
			c.splice(ix, 1);
			setCart(c);
		}
	}

	var editItem = (ix: number)=>{
		return ()=>{
			var c = cart[ix];
			if(!c) return;
			setSelectedProduct(c);
			setAddAmount(c.amount.toString());
			setPriceFix((c.price_fix || c.price_gram || c.price).toString());
			setEditingItem(ix);
			showModal(Modals.ADD_PRODUCT);
			
			setTimeout(()=>{
				addInputRef.current?.focus();
			}, 200);
		}
	}

	var search = (query: string)=>{
		if(!query || query.length==0){
			return setSearchProducts(null);
		}
		API.searchProducts(query).then(res=>{
			if(res.error) return setLoadError(res.message);
			setSearchProducts(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando los productos. (LCL-1)');
		})
	}

	var onSearchQuery = (q: string)=>{
		setSearchQuery(q);
		if(!selectedCategory){
			if(searchTimeout) clearTimeout(searchTimeout);
			setSearchProducts(null);
			setSearchTimeout(setTimeout(()=>{
				search(q);
			}, 300));
		}
	}

	var getOrderTotal = ()=>{
		var cart_total = cart.reduce((a,b)=>a+(b.price_fix || b.price_gram || b.price || 0)*b.amount, 0);
		return cart_total;
	}

	var getPaymentMethods = ()=>{
		if(paymentMethods) return;
		API.getPaymentMethods().then(res=>{
			if (res.error) return Toast.error(res.message);
			return setPaymentMethods(res.data);
		}).catch(err=>{
			Toast.error('Hubo un error al obtener los metodos de pago. (LCL-1)');
		})
	}

	var addPaymentMethod = (method_id: number)=>{
		if(!completeForm || !paymentMethods) return;
		var method = paymentMethods.find(a=>a.method_id==method_id);
		if(!method) return;
		var total = getOrderTotal();
		var paid_missing = total-completeForm.payments.reduce((a,b)=>a+b.amount, 0);

		var frm = { ...completeForm };
		frm.payments.push({
			method_id,
			method_name: method.method_name_localized,
			amount: paid_missing,
			date_paid: moment().unix(),
		});
		setCompleteForm(frm);
	}

	var showComplete = ()=>{
		if(!paymentMethods){
			getPaymentMethods();
		}
		setCompleteForm({
			order_method: null,
			owner_email: '',
			owner_name: '',
			owner_last_name: '',
			owner_phone: '',
			shipping_method: null,
			send_email: true,
			paid: false,
			payments: [],
			shipping_date: null,
			referal: ''
		});
		showModal(Modals.COMPLETE_ORDER);
	}

	var removeOrderPayment = (ix: number)=>{
		return ()=>{
			var frm = { ...completeForm };
			frm.payments.splice(ix, 1);
			setCompleteForm(frm);
		}
	}

	var setPaymentValue = (ix: number, key: keyof CreateOrderForm["payments"][0])=>{
		return (amount: any)=>{
			var frm = { ...completeForm };
			frm.payments[ix][key] = (amount as never);
			setCompleteForm(frm);
		}
	}

	var sendComplete = (setLoading: SetLoading)=>{
		var cart_total = getOrderTotal();
		var { valid, prompts } = Validator(completeForm, {
			owner_name: [{ rule: 'minLength', params: [3], label: 'Nombre' }],
			referal: completeForm.order_method === SalesChannel.CALLCENTER ? [{ rule: 'empty', label: 'Referencia' }] : [],
			owner_phone: completeForm.owner_phone ? [{ rule: 'minLength', params: [8], label: 'Teléfono' }] : [],
			paid: [{
				rule: v=>{
					if(!v) return true;
					var paid_amount = completeForm.payments.reduce((a,b)=>a+b.amount, 0);
					return paid_amount>=0;
				},
				prompt: 'La cantidad pagada no puede ser negariva'
			}, { 
				rule: v=>{
					if(!v) return true;
					var paid_amount = completeForm.payments.reduce((a,b)=>a+b.amount, 0);
					return paid_amount<=cart_total;
				}, prompt: 'Los pagos realizados suman mas que la cantidad a pagar'
			}],
			order_method: [{ rule: 'empty', prompt: 'Favor de seleccionar el tipo de orden' }],
			shipping_method: [{ rule: 'empty', prompt: 'Favor de seleccionar la forma de envío' }],
			shipping_date: [{ rule: 'empty', prompt: 'Favor de seleccionar la fecha de entrega' }],
		});
		setModalPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		API.createOrder({
			...completeForm,
			payments: completeForm.paid ? completeForm.payments : []
		}, cart.map(a=>({
			product_id: a.product_id,
			amount: a.amount,
			price: (a.price_fix || a.price_gram || a.price),
		}))).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			showModal(Modals.FINISHED_ORDER)
			setCart([]);
			setCreatedOrder({
				order_id: res.data.order_id,
				order_key: res.data.order_key,
				order_hash: res.data.order_hash,
				owner_email: completeForm.owner_email,
				owner_phone: completeForm.owner_phone,
				owner_name: completeForm.owner_name,
				referal: completeForm.referal,
				owner_last_name: completeForm.owner_last_name,
				paid: res.data.paid,
				total: cart_total,
				token: res.data.token
			});
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado creando la orden. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var viewing_category = selectedCategory ? categories.find(a=>a.category_id===selectedCategory) : null;
	var category_products = selectedCategory ? products.find(a=>a.category_id===selectedCategory) : null;
	var viewing_products = !selectedCategory ? searchProducts : (
		category_products ? (
			searchQuery && searchQuery.length>2 ? (
				category_products.products.filter(filterSearch(searchQuery, 'product_name'))
			) : category_products.products
		) : null
	);
	var cart_total = getOrderTotal();

	var onFormChange = bindFormChange(completeForm, setCompleteForm);

	return <>
		<div className="co creator">
			<div className="contents">
				<div className="head">
					{!!selectedCategory && (
						<Button iconName='arrow left' basic color='black' style={{ minWidth: 20, padding: '5px 10px', marginRight: 10, textAlign: 'center' }} iconStyle={{ margin: 0 }} onClick={viewCategory(null)} />
					)}
					<div className="text">
						{viewing_category?.category_name || 'Categorías'}
					</div>
					{categories ? (
						<div className="search">
							<Input className='searchInput' placeholder={`Buscar ${viewing_category ? 'en categoría' : 'todo'}...`} icon='search' value={searchQuery} onChange={onSearchQuery} />
						</div>
					) : null}
					{mobile && <Button icon iconName='shopping basket' color='black' size='small' onClick={bindClick(setShowCart, true)}/>}
				</div>
				{selectedCategory || searchProducts ? (
					viewing_products ? (
						viewing_products.length>0 ? (
							<div className="products">
								{viewing_products.map(a=>(
									<ProductCard key={`prd-${a.product_id}`} selectable product={a} image={`${API.CDN_URL}/products/${a.product_id}/0.webp`} onClick={productClick(a)} />
								))}
							</div>
						) : (
							<Header iconName='frown open' text={'No se encontraron productos'} subtext={searchQuery && searchQuery.length>2 ? `Buscando "${searchQuery}"` : null} size='small' style={{ marginTop: 20 }} iconStyle={{ marginBottom: 10 }} />
						)
					) : (
						<Header loading text='Cargando productos' />
					)
				) : (
					!categories ? (
						<Header loading text='Cargando categorías' />
					) : !searchQuery || searchQuery.length<2 ? (
						<div className="categories">
							{categories.sort(firstBy('priority', 'desc')).map(a=>(
								<CategoryCard key={`cat-${a.category_id}`} category={a} image={`${API.CDN_URL}/categories/${a.category_id}.webp`} placeholderImage='/placeholder.webp' onClick={viewCategory(a.category_id)} />
							))}
						</div>
					) : (
						<Header loading text='Buscando productos' subtext={`Buscando "${searchQuery}"`} subheaderStyle={{ fontSize: 14 }} />
					)
				)}
			</div>
			<div className={`cart ${showCart ? 'show' : 'hide'}`}>
				<div className="head">Carrito {mobile && <Icon name='close' onClick={bindClick(setShowCart, false)}/>}</div>
				<div className="items">
					{cart.length==0 ? (
						<Header className='empty' text='Carrito vacío' iconName='box open' size='small' />
					) : cart.map((a, i)=>(
						<CartItem 
							key={`crt-p${a.product_id}-${toCents(a.price_fix)}`} 
							product={a}
							amount={a.amount}
							price={a.price_fix || a.price_gram || a.price}
							image={`${API.CDN_URL}/products/${a.product_id}/0.webp`}
							actions={<div>
								{(a.amount>1 || !!a.price_fix) && (
									<div className="meta">
										Precio: ${addCommas(a.price_fix || a.price_gram || a.price || 0)}
									</div>
								)}
								<div className="meta" style={{ marginBottom: 5 }}>
									Cantidad: {addCommas(a.amount, true, true)}
								</div>
								<Button size='tiny' text='Editar' iconName='pencil' onClick={editItem(i)} />
								<Button size='tiny' style={{ marginLeft: 5 }} icon basic color='red' iconName='trash' onClick={removeItem(i)} />
							</div>}
						/>
					))}
				</div>
				{cart && cart.length>0 && (
					<div className="totals">
						<div className="amount">
							<div className="label">Subtotal</div>
							<div className="value">${addCommas(cart_total)}</div>
						</div>
						<Button text='Completar' color='black' fluid size='big' onClick={showComplete} />
					</div>
				)}
			</div>
		</div>
		<Modal open={shownModal===Modals.ADD_PRODUCT} onClose={bindClick(setShownModal)} size='tiny'>
			<Modal.Header>{editingItem!==null ? 'Editar' : 'Agregar'} producto</Modal.Header>
			<Modal.Content>
				<Input label='Cantidad' inputStyle={{ textAlign: 'center', fontSize: 30, fontWeight: 'bold' }} ref={addInputRef} value={addAmount} onChange={setAddAmount} submitOnEnter onSubmit={addProduct(selectedProduct, parseFloat(addAmount), parseFloat(priceFix))} />
				<Stat.Group style={{ alignItems: 'center' }}>
					<Stat label='Precio' value={(
						<Input value={priceFix} onChange={setPriceFix} placeholder='Precio' inputStyle={{ textAlign: 'center', fontWeight: 'bold', fontSize: 28, padding: 5 }} style={{ marginBottom: 5 }} />
					)} />
					<Stat label='Subtotal' value={addCommas(((parseFloat(priceFix)) || 0)*(parseInt(addAmount) || 0))} />
				</Stat.Group>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClick(setShownModal)} />
				<Button text={!editingItem ? 'Agregar' : 'Editar'} color='black' onClick={addProduct(selectedProduct, parseFloat(addAmount), parseFloat(priceFix))} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.COMPLETE_ORDER} size='small'>
			<Modal.Header>Completar venta</Modal.Header>
			{!!completeForm && <Modal.Content>
				<Table
					fitted
					details
					style={{ borderBottom: '1px solid #EEE' }}
					data={[
						['Productos', cart.reduce((a,b)=>a+(b.price_gram ? 1 : b.amount), 0)],
						['Subtotal', addCommas(cart_total)]
					]}
				/>
				<div style={{ height: 15 }} />
				<Field label='Tipo de orden'>
					<Dropdown selection placeholder='Tipo de orden' value={completeForm.order_method} onChange={onFormChange('order_method', true)} options={[
						{ text: 'Online', value: SalesChannel.ONLINE },
						{ text: 'Business', value: SalesChannel.B2B },
						{ text: 'Personalizado', value: SalesChannel.PERSONALIZED },
						{ text: 'Callcenter', value: SalesChannel.CALLCENTER },
					]} />
				</Field>
				{completeForm.order_method === SalesChannel.B2B && taxDataLoading ? <Header loading text='Cargando datos' /> : completeForm.order_method === SalesChannel.B2B && !taxDataLoading ?
					<Field label='Datos Fiscales'>
						<Dropdown
							placeholder='Datos Fiscales'
							fluid
							selection
							options={[
								{
									key: null,
									text: 'Registrar datos fiscales',
									value: null
								},
								...taxDataList.map(t => ({
									key: t.tax_id,
									text: t.legal_name,
									value: t.tax_id,
								}))
						   ]}
							value={completeForm.tax_id}
							style={{marginBottom: 10}}
							onChange={(event, data) => {
								if(!data.value){
									setTaxDataModal(true);
								}else{
									var taxInfo = taxDataList.find(item => item.tax_id === data.value);
										setCompleteForm(prevCompleteForm => ({
										...prevCompleteForm,
										owner_email: taxInfo.email,
										owner_phone: taxInfo.phone
									}));
								}
							}}
						/> 
					</Field> : null
				}
				{completeForm.order_method === SalesChannel.CALLCENTER && (
					<Field label='Referencia'>
						<Dropdown selection placeholder='Referencia' value={completeForm.referal} onChange={onFormChange('referal', true)} options={[
							{ text: 'Facebook', value: 'Facebook' },
							{ text: 'Instagram', value: 'Instagram' },
							{ text: 'Whatsapp', value: 'Whatsapp' },
							{ text: 'Teléfono', value: 'Teléfono' },
							{ text: 'PDV Manzanares', value: 'PDV Manzanares' },
						]} />
					</Field>
				)}
				<Input value={completeForm.owner_name} label='Nombre comprador' onChange={onFormChange('owner_name')} />
				<Input value={completeForm.owner_last_name} label='Apellido comprador' onChange={onFormChange('owner_last_name')} />
				<Input value={completeForm.owner_email} label='Correo electrónico' onChange={onFormChange('owner_email')} style={{ marginTop: 10, marginBottom: 10 }} />
				<Input value={completeForm.owner_phone} label='Teléfono (Opcional)' onChange={onFormChange('owner_phone')} style={{ marginTop: 10, marginBottom: 10 }} />
				<Field amount={2}>
					<Field label='Envío'>
						<Dropdown
							selection
							placeholder='Tipo de envío'
							value={completeForm.shipping_method}
							onChange={onFormChange('shipping_method', true)}
							options={[
								{ text: 'Envío a domicilio', value: ShippingMethod.SHIPPING },
								{ text: 'Pickup', value: ShippingMethod.PICKUP },
							]}
						/>
					</Field>
					<Input label='Fecha entrega' className='fluid' value={completeForm.shipping_date} onChange={onFormChange('shipping_date')} calendar={{
						date: completeForm.shipping_date,
						initialDate: moment().unix(),
						mode: 'date',
					}} />
				</Field>

				<Checkbox label="Enviar correo para pago" toggle style={{ display: 'block', margin: '15px 0 5px 0' }} checked={completeForm.send_email} onChange={onFormChange('send_email', true)} />
				<Checkbox label="Incluir pago" toggle style={{ display: 'block', margin: '10px 0 15px 0' }} checked={completeForm.paid} onChange={onFormChange('paid', true)} />
				{completeForm.paid && (
					paymentMethods ? <>
						<Field label='Forma de pago'>
							<Dropdown value={null} onChange={(v, d)=>addPaymentMethod(d.value as number)} selection placeholder='Selecciona la forma de pago' options={paymentMethods.map(a=>({
								text: a.method_name_localized,
								value: a.method_id
							}))} />
						</Field>
						{completeForm.payments.length>0 && (
							<Table
								headers={['Método', 'Cantidad', 'Fecha', '']}
								centeredIndexes={[3]}
								data={completeForm.payments.map((a, i)=>([
									a.method_name,
									<Input inputStyle={{ padding: 8, textAlign: 'center' }} style={{ margin: 0 }} placeholder='Cantidad pagada' value={a.amount || ''} onChange={setPaymentValue(i, 'amount')} />,
									<Input inputStyle={{ padding: 8, textAlign: 'center' }} style={{ margin: 0 }} placeholder='Cantidad pagada' value={a.date_paid} onChange={setPaymentValue(i, 'date_paid')} calendar={{
										date: a.date_paid
									}} />,
									<Button size='tiny' iconName='remove' icon onClick={removeOrderPayment(i)} />
								]))}
								footer={(
									<Table.Row>
										<Table.Cell><b>Pagado</b></Table.Cell>
										<Table.Cell colSpan={3} style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{addCommas(completeForm.payments.reduce((a,b)=>a+b.amount, 0))}</Table.Cell>
									</Table.Row>
								)}
							/>
						)}
					</> : (
						<Header loading size='small' text='Cargando formas de pago' style={{ fontSize: 14 }} />
					)
				)}
				<Message list={modalPrompts} type='error' />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClick(setShownModal)} />
				<Button text='Completar' color='black' onClick={sendComplete} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===Modals.FINISHED_ORDER} onClose={bindClick(setShownModal)} size='mini'>
			<Modal.Header>Nueva orden</Modal.Header>
			{!!createdOrder && <Modal.Content>
				<Header iconName='face grin wide' text='¡Orden creada!' iconStyle={{ fontSize: 50 }} style={{ marginTop: -10, marginBottom: 15 }} />
				<Table
					striped
					details
					title='Información'
					titleSize='small'
					data={[
						['ID', createdOrder.order_id],
						['Comprador', createdOrder.owner_name + ' ' + createdOrder.owner_last_name],
						['Email', createdOrder.owner_email],
						null,
						['Estatus', createdOrder.paid ? 'Pagada' : 'Pendiente de pago'],
						['Total', addCommas(createdOrder.total)],
					]}
				/>
				<Button color='black' size='big' fluid text='Ver orden' style={{ marginTop: 15 }} as={Link} to={`/orders/${createdOrder.order_id}`} target='_blank' />
				{!createdOrder.paid && (
					<Button color="green" size='tiny' fluid text="Ver link de pago" style={{ marginTop: 5 }} as={Link} to={`${API.PUBLIC_URL}/checkout/link/${createdOrder.token}`} target='_blank' />
				)}
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" color='black' onClick={bindClick(setShownModal)} />
			</Modal.Actions>
		</Modal>
		<TaxDataModal
			open={taxDataModal}
			onClose={bindClick(setTaxDataModal)}
			deleted={1}
			onCreate={onTaxInfoCreated}
		/>
	</>
}
		
export default NewOrderView;
import moment from 'moment';
import React from 'react'
import { Table } from 'react-frontier';
import { Link } from 'react-router-dom';

interface UserLogProps {
	user: {
		user_creator?: any,
		user_creator_name?: string,
		date_created?: number,
		
		completed?: boolean,
		admin_completed_name?: string,
		admin_completed?: any,
		
		user_adder_name?: string,
		user_adder?: any,
		date_added?: number,
		
		cancelled?: boolean,
		admin_cancelled_name?: string,
		admin_cancelled?: any,
		
		deleted?: boolean | number,
		user_deleter_name?: string,
		user_deleter?: number,
		
		[key: string]: any,
	},
	style?: React.CSSProperties,
	disable_title?: boolean,
}


export default function UserLog(props: UserLogProps) {
	const user = props.user;
	const style = props.style || { maxWidth: 500, margin: 'auto', marginTop: 15 };
	
	return (
		<Table
			details
			striped
			style={style}
			title= {props.disable_title ? null : 'Usuarios'}
			data={[
				...((user.user_adder || user.user_creator) ? [
					['Creador', <>
						<Link target='_blank' className='normal'  to={`/users/${(user.user_adder || user.user_creator)}`} style={{ textDecoration: 'none', color: 'inherit'}}>{(user.user_creator_name || user.user_adder_name)?.toUpperCase()}</Link>
						<div className="meta">ID: {user.user_creator}</div>
					</>],
					['Fecha creación', <>
						{moment.unix(user.date_created).format('DD/MMM/YY HH:mm')}
					</>],
				] : []),
				...(user.completed ? [
					null,
					['Completador', <>
						<Link target='_blank' className='normal'  to={`/admins/${user.admin_completed}`} style={{ textDecoration: 'none', color: 'inherit'}}>{user.admin_completed_name?.toUpperCase()}</Link>
						<div className="meta">ID: {user.admin_completed}</div>
					</>],
					['Fecha completada', <>
						{moment.unix(user.date_completed).format('DD/MMM/YY HH:mm')}
					</>],
				] : []),
				...(user.cancelled ? [
					null,
					['Cancelador', <>
						<Link target='_blank' className='normal'  to={`/admins/${user.user_deleter}`} style={{ textDecoration: 'none', color: 'inherit'}}>{user.user_deleter_name?.toUpperCase()}</Link>
						<div className="meta">ID: {user.user_deleter}</div>
					</>],
					['Fecha cancelación', <>
						{moment.unix(user.date_cancelled).format('DD/MMM/YY HH:mm')}
					</>],
				] : []),
				...(user.deleted ? [
					null,
					['Cancelador', <>
						<Link target='_blank' className='normal'  to={`/admins/${user.user_deleter}`} style={{ textDecoration: 'none', color: 'inherit'}}>{user.user_deleter_name?.toUpperCase()}</Link>
						<div className="meta">ID: {user.user_deleter}</div>
					</>],
					['Fecha cancelación', <>
						{moment.unix(user.date_deleted).format('DD/MMM/YY HH:mm')}
					</>],
				] : []),
			]}
		/>
	)
}
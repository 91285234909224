import React, { useEffect, useRef, useState } from 'react';
import { NotFound, UserLog } from '../components'
import { Button, Input, Message, Header, Groupper, Field, Table } from 'react-frontier';
import { Modal, Icon, Dropdown, Checkbox, TextArea, ModalActions } from 'semantic-ui-react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { bindClick, bindFormChange } from '../Util';
import { EditProduct, Category, SetLoading, SatCode, UserLoginData } from '../Classes';
import { useTitle } from '../Hooks';
import MDEditor, { commands } from '@uiw/react-md-editor/nohighlight';
import API from '../API';
import Validator from '../Validator';
import Toast from 'react-hot-toast';
import classNames from 'classnames';
import moment from 'moment';

import '../style/categories.scss'

const IEPS_RATES = [
	0.00, 0.03,
	0.06, 0.07,
	0.08, 0.09,
	0.25, 0.265,
	0.3, 0.304,
	0.5, 0.53,
	1.6
];

var Product = ()=>{
	var { product_id } = useParams();
	var { setTitle } = useTitle();
	var imageInputRef = useRef<HTMLInputElement>(null);
	var [categories, setCategories] = useState<Category[]>(null);
	var [product, setProduct] = useState<EditProduct>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [categoriesModal, setCategoriesModal] = useState<boolean>(false);
	var [filter, setFilter] = useState<string>('');
	var [addingImage, setAddingImage] = useState<boolean>(false);
	var [viewingImage, setViewingImage] = useState<number>(null);
	var [settingPrimary, setSettingPrimary] = useState<boolean>(false);
	var [deleteImageModal, setDeleteImageModal] = useState<boolean>(false);
	var [sendingCategory, setSendingCategory] = useState<number>(null);
	var [deleteModal, setDeleteModal] = useState<boolean>(false);
	var [satCodes, setSatCodes] = useState<SatCode[]>(null);
	var [satCodeModal, setSatCodeModal] = useState<boolean>(false);
	var [satQuery, setSatQuery] = useState<string>('')
	var [userLogged, setUserLogged] = useState<UserLoginData>(null);
	const IS_CREATE = product_id==='create';
	const history = useNavigate();

	useEffect(() => {
		setUserLogged(API.getLogin());
		get();
	}, []);

	var get = async()=>{
		try {
			if(!IS_CREATE){
				var res = await API.getProduct(parseInt(product_id), true);
				if (res.error) return setLoadError(res.message);
				res.data.categories = res.data.categories.map((a: any)=>a.category_id);
				getCategories();
				setTitle(res.data.name);
				res.data.price_online ||= '0';
				res.data.price_pdv ||= '0';
				return setProduct(res.data);
			}else{
				getCategories();
				return setProduct({
					name: '', 
					description: '', 
					message: '', 
					priority: null, 
					price_online: null, 
					price_pdv: null, 
					price_gram: 0, 
					size: '', 
					granel: false, 
					only_local: false, 
					available_online: false,
					deleted: 0,
					available_pdv: false, 
					main_category: null, 
					categories: [],
					sat_code: null,
					tax_ieps: 0,
					product_cost: null
				});
			}
		} catch (error) {
			setLoadError('Hubo un error al obtener el producto o las categorias. (GCE-1)');
		}
	};

	var getCategories = ()=>{
		API.getAllCategories(true, true).then(res=>{
			if(res.error) return setLoadError(res.message);
			setCategories([...res.data.categories, ...res.data.collections].sort((a,b)=>a.category_id-b.category_id));
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener las categorias. (GCE-1)');
		});
	};

	var editCategory = async(category_id: number, category_name: string, remove: boolean)=>{
		setSendingCategory(category_id);
		API.productCategoryEdit(parseInt(product_id), category_id, remove).then(res=>{
			var pro = {...product};
			if(res.error) return Toast.error(res.message);
			if(remove){
				pro.categories = pro.categories.filter(c=>c!==category_id);
				Toast.success('Categoria eliminada!');
			}else{
				pro.categories.push(category_id)
				Toast.success('Categoria agreada!');
			}
			pro.categories = pro.categories.sort((a,b)=>a-b)
			setProduct(pro)
		}).catch(err=>{
			return Toast.error('Hubo un error al actualizar la categoria. (P-CGY-1)');
		}).finally(()=>{
			setSendingCategory(null);
		})
	};

	var update = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(product, {
			name: [{
				rule: 'minLength', params: [3], label: 'Nombre'
			}],
			price_online: [{
				rule: 'min', params: [0], label: 'Precio online'
			}],
			price_pdv: [{
				rule: 'min', params: [0], label: 'Precio punto de venta'
			}],
			main_category: [{
				rule: 'empty', label: 'Categoria principal'
			}],
			sat_code: [{
				rule: 'empty', label: 'Código del SAT'
			}],
			...(product.granel ? {
				price_gram: [{
					rule: 'min', params: [0], label: 'Precio x gramo'
				}],
			} : {}),
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);

		if(!IS_CREATE){
			API.updateProduct(parseInt(product_id), product).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Producto actualizado!');
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado creando el producto (GCE-3)']);
			}).finally(()=>{
				setLoading(false);
			});
		}
	};

	var addImage = ()=>{
		imageInputRef.current?.click();
	}

	var handleImage = (ev: React.ChangeEvent<HTMLInputElement>)=>{
		if(!ev.target.files || ev.target.files.length==0) return;
		if(ev.target.files.length>1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		var image = ev.target.files[0];
		if(image.size>(2.5*1024*1024)) return Toast.error('La imagen no puede pesar mas de 2.5MB.');

		setAddingImage(true);
		API.addProductImage(parseInt(product_id), image).then(res=>{
			if(res.error) return Toast.error(res.message);
			
			var pr = {...product};
			if(!pr.images) pr.images = { primary: null, images: [] };
			if(!pr.images.primary){
				pr.images.primary = res.data.image_url;
			}else{
				pr.images.images.push(res.data.image_url);
			}
			setProduct(pr);

			Toast.success('Se ha agregado la imagen del producto');
		}).catch(err=>{
			return Toast.error('Hubo un error subiendo el poster del evento. (LCL-3)');
		}).finally(()=>{
			setAddingImage(false);
		});
	}

	var viewImage = (image: number)=>{
		return ()=>{
			setViewingImage(image)
		}
	}

	var convertPrimary = (image: number)=>{
		return (setLoading: SetLoading)=>{
			setSettingPrimary(true);
			setLoading(true);
			API.setImagePrimary(product.product_id, product.images.images[image]).then(res=>{
				if(res.error) return Toast.error(res.message);
				var pr = {...product};
				pr.images = res.data;
				setProduct(pr);
				setViewingImage(null);
				Toast.success('Se ha cambiado la imagen primaria.');
			}).catch(err=>{
				return Toast.error('Hubo un error inesperado cambiando la imagen primaria. (LCL-1)');
			}).finally(()=>{
				setSettingPrimary(false);
				setLoading(false);
			})
		}
	}

	var deleteImage = (image: number)=>{
		return (setLoading: SetLoading)=>{
			setLoading(true);
			API.deleteProductImage(product.product_id, image===-1 ? product.images.primary : product.images.images[image]).then(res=>{
				if(res.error) return Toast.error(res.message);

				var pr = {...product};
				pr.images = res.data;
				setProduct(pr);

				Toast.success('Se ha eliminado la imagen seleccionada.');
				setDeleteImageModal(false);
				setViewingImage(null);
			}).catch(err=>{
				return Toast.error('Hubo un error inesperado eliminando la imagen. (LCL-1)');
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var create = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(product, {
			name: [{
				rule: 'minLength', params: [3], label: 'Nombre'
			}],
			price_online: [{
				rule: 'min', params: [0], label: 'Precio online'
			}],
			price_pdv: [{
				rule: 'min', params: [0], label: 'Precio punto de venta'
			}],
			sat_code: [{
				rule: 'empty', label: 'Código del SAT'
			}],
			price_gram: product.granel ? [{ rule: 'min', params: [1], label: 'Precio x gramo' }] : [],
			main_category: [{
				rule: 'empty', label: 'Categoria principal'
			}],
			tax_ieps: [{
				rule: 'number', label: 'IEPS'
			}],
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createProduct(product.name, product.description, product.message, product.priority, product.price_online, product.price_pdv, product.price_gram, product.size, product.granel, product.only_local, product.available_online, product.available_pdv, product.sat_code, product.main_category, product.categories, product.product_cost).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Producto creado!');
			return history(`/products/${res.data[0]}`);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando el producto (P-CRT-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	};

	var deleteProduct = (setLoading: (l: boolean)=>void)=>{;
		setLoading(true);
		API.deleteProduct(parseInt(product_id), !product.deleted).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Producto actualizado!');
			var p = {...product};
			p.deleted = !product.deleted;
			if(!p.deleted){
				p.date_deleted = moment().unix();
				p.user_deleter_name = userLogged.name_complete;
				p.user_deleter = userLogged.user_id;
			}
			setProduct(p);
			return setDeleteModal(false);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado actualizando el producto. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var searchSatCodes = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.searchSatCode(satQuery).then(res=>{
			if(res.error) return Toast.error(res.message);
			return setSatCodes(res.data);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado buscando los codigos. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var updateSatCode = (code: SatCode) => {
		setProduct({ ...product, sat_code: code.key });
		setSatCodeModal(false);
		setSatQuery('');
		setSatCodes(null);
	};

	var onEditChange = bindFormChange(product, setProduct);

	if(!IS_CREATE && Number.isNaN(parseInt(product_id))){
		return <NotFound />
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!product || !categories) {
		return <Header loading text='Cargando datos' />;
	}

	var search = categories.filter(a=>a.category_name.toLowerCase().includes(filter.toLowerCase()))

	return <div className='co product-edit-screen' style={{ paddingBottom: 20 }}>
		<Groupper title={!IS_CREATE ? 'Editar Producto' : 'Crear Producto'} width={600} titleStyle={{ marginBottom: 0 }} titleCentered actions={(
			<Button text={IS_CREATE ? "Crear" : "Guardar"} color="black" onClick={IS_CREATE ? create : update }/>
		)}>
			{!IS_CREATE && <>
				<div className="fr toolbar">
					<Link className="item" to={`${API.PUBLIC_URL}/product/${product.product_id}`} target='_blank'>
						<Icon name='eye' /> Ver producto
					</Link>
					<div className="item" onClick={bindClick(setDeleteModal, true)}>
						<Icon name={product.deleted ? 'sync' : 'remove'} />
						{!product.deleted ? 'Eliminar' : 'Restaurar'}
					</div>
				</div>
				<Groupper.Divider text='Imagenes' style={{ marginTop: 0 }} />
				<input type="file" style={{ display: 'none' }} ref={imageInputRef} accept="image/*" onChange={handleImage} />
				<div className="co product images">
					{!!product.images && !!product.images.primary && (
						<img src={product.images.primary} className="image primary" onClick={viewImage(-1)} />
					)}
					{addingImage && (
						<div className="image loading">
							<div className="fr loading inline"></div>
						</div>
					)}
					{product.images && product.images.images.map((a, i)=>(
						<img src={a} className="image" onClick={viewImage(i)} />
					))}
					<div className="image add" onClick={addImage}>
						<Icon name="plus" />
					</div>
				</div>
				<Groupper.Divider text='Detalles' />
			</>}
			<Input label='Nombre' value={product.name} onChange={onEditChange('name')}/>
			<Input label='Prioridad' onChange={onEditChange('priority')} value={product.priority}/>
			<Field label='Código del SAT'>
				<Input readonly value={product.sat_code} button={
					<Button icon iconName='pencil' size='tiny' onClick={() => setSatCodeModal(true)}/>
				}/>
			</Field>
			<div style={{padding: 5, fontWeight: 'bold' }}>Categoría principal</div>
			<Dropdown
				placeholder='Categoría principal'
				fluid
				selection
				options={categories.map(category => ({
					key: category.category_id,
					text: category.category_name,
					value: category.category_id
				}))}
				style={{marginBottom: 10}}
				value={product.main_category}
				onChange={onEditChange('main_category', true)}
			/>
			<div style={{padding: 5, fontWeight: 'bold' }}>IEPS</div>
			<Dropdown
				placeholder='IEPS'
				fluid
				selection
				options={IEPS_RATES.map(i => ({
					key: i,
					text: `${(i*100).toFixed(2)}%`,
					value: i,
				}))}
				style={{marginBottom: 10}}
				value={product.tax_ieps}
				onChange={onEditChange('tax_ieps', true)}
			/>
			<Field label='Disponibilidad'>
				<div style={{ display: 'flex', flexDirection: 'column', height: 70, justifyContent: 'space-between', marginTop: 5 }}>
					<Checkbox toggle label='Online' checked={!!product.available_online} onChange={onEditChange('available_online', true)}/>
					<Checkbox toggle label='Punto de venta' checked={!!product.available_pdv} onChange={onEditChange('available_pdv', true)}/>
					<Checkbox toggle label='Solo envio local' checked={!!product.only_local} onChange={onEditChange('only_local', true)}/>
				</div>
			</Field>
			<Field amount={2}>
				<Input label='Precio PDV' onChange={onEditChange('price_pdv')} value={product.price_pdv}/>
				<Input label='Precio Online' onChange={onEditChange('price_online')} value={product.price_online}/>
			</Field>
			<Input label='Costo' comment='Lo que le cuesta a COOCAN el producto' onChange={onEditChange('product_cost')} value={product.product_cost}/>
			<Input label='Mensaje' onChange={onEditChange('message')} value={product.message}/>
			<Input label='Tamaño' onChange={onEditChange('size')} value={product.size}/>
			<Field label='Descripción'>
				<MDEditor 
					preview='edit'
					style={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}
					value={product.description} 
					onChange={onEditChange('description')} 
					height={400}
					data-color-mode='light'
					extraCommands={[
						{
							...commands.codeEdit,
							icon: <Icon name='pencil' style={{ margin: 0, fontSize: 18 }} />
						},
						{
							...commands.codePreview,
							icon: <Icon name='eye' style={{ margin: 0, fontSize: 18 }} />
						},
					]}
					commands={[
						commands.bold,
						commands.italic,
						commands.strikethrough,
						commands.divider,
						commands.hr,
						commands.quote,
						commands.code,
						commands.divider,
						commands.unorderedListCommand,
						commands.orderedListCommand,
					]}
				/>	
			</Field>
			<div className="section head">Opciones PDV</div>
			<Checkbox toggle label='Granel' checked={!!product.granel} onChange={() => onEditChange('granel')(!product.granel)} />
			{!!product.granel && <Input label='Precio x gramo' style={{ marginTop: 10 }} onChange={onEditChange('price_gram')} value={product.price_gram}/>}
			{!IS_CREATE && (
				<Table
					striped
					className='top'
					title='Categorías'
					headers={['ID', 'Nombre', <Icon name='trash'/>]}
					style={{ marginTop: 15 }}
					button={(
						<Button size='tiny' text='Agregar' color='black' iconName='plus circle' onClick={bindClick(setCategoriesModal, true)}/>
					)}
				>
					{product.categories && product.categories.length !== 0 ? product.categories.map(a=>{
						var c = categories.find(b=>b.category_id==a);
						if(!c) return null;
						return <Table.Row
							key={c.category_id}
							collapsingIndexes={[0, 2]}
							data={[
								c.category_id,
								c.category_name,
								sendingCategory!==c.category_id ? (
									<Button size='tiny' style={{ minWidth: 40 }} iconName='trash' onClick={() => editCategory(c.category_id, c.category_name, true)}/>
								) : (
									<div className="fr loading inline" style={{ width: 30 }}></div>
								)
							]}
						/>
					}) : (
						<Table.Row>
							<Table.Cell className='empty' colSpan={5}>Esta categoria no cuenta con productos</Table.Cell>
						</Table.Row>
					)}
				</Table>
			)}
			<Message type="error" list={errorPrompts} style={{ marginTop: 15 }} />
		</Groupper>
		<UserLog user={product}/>
		{!IS_CREATE && <Modal open={categoriesModal} size='tiny' onClose={bindClick(setCategoriesModal)}>
			<Modal.Header >Agregar categorias</Modal.Header>
			<Modal.Content>
				<Input placeholder='Buscar categoria' value={filter} onChange={setFilter}/>
				{categories ? (
					<Table striped>
						{search.length !== 0 ? (
							search.map((c) => {
								const categoryIndex = product.categories ? product.categories.findIndex((a) => a === c.category_id) : -1;			
								return (
									<Table.Row
										key={c.category_id}
										collapsingIndexes={[0, 2]}
										data={[
											c.category_id,
											c.category_name,
											sendingCategory !== c.category_id ? (
												categoryIndex > -1 ? (
													<Button size='tiny' style={{ minWidth: 40 }} iconName='check' disabled />
												) : (
													<Button
														size='tiny'
														style={{ minWidth: 40 }}
														iconName='plus circle'
														color='green'
														onClick={() => editCategory(c.category_id, c.category_name, false)}
													/>
												)
											) : (
												<div className='fr loading inline' style={{ width: 30 }}></div>
											),
										]}
									/>
								);
							})
						) : (
							<Table.Row>
								<Table.Cell className='empty' colSpan={5}>No se encontraron categorías</Table.Cell>
							</Table.Row>
						)}
					</Table>
				) : null }
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setCategoriesModal)} />
			</Modal.Actions>
		</Modal>}
		<Modal open={viewingImage!==null && !deleteImageModal} size='small' onClose={settingPrimary ? null : bindClick(setViewingImage)}>
			<Modal.Header>Imagen</Modal.Header>
			{viewingImage!==null && <Modal.Content>
				<img 
					src={viewingImage===-1 ? product.images.primary : product.images.images[viewingImage]}
					style={{ width: '100%', maxWidth: 400, display: 'block', margin: 'auto' }} 
				/>
				{viewingImage!==-1 && (
					<Button style={{ display: 'block', margin: 'auto', width: 300, marginTop: 15 }} text='Convertir a primaria' onClick={convertPrimary(viewingImage)} />
				)}
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setDeleteImageModal, true)} color='red' text='Eliminar' disabled={settingPrimary} />
				<Button onClick={bindClick(setViewingImage)} text='Cerrar' disabled={settingPrimary} />
			</Modal.Actions>
		</Modal>
		<Modal open={viewingImage!==null && deleteImageModal} size='mini' onClose={bindClick(setDeleteImageModal)}>
			<Modal.Header>Eliminar imagen</Modal.Header>
			{viewingImage!==null && deleteImageModal && <Modal.Content>
				<div className="co product images" style={{ justifyContent: 'center' }}>
					<img src={viewingImage===-1 ? product.images.primary : product.images.images[viewingImage]} className={classNames("image", { primary: viewingImage===-1 })} />
				</div>
				<Header text='¿Eliminar imagen?' style={{ marginTop: 15, marginBottom: 0 }} subtext={viewingImage===-1 ? 'Se reemplazará la imagen primaria por la siguiente imagen' : null} />
			</Modal.Content>}
			<ModalActions>
				<Button onClick={bindClick(setDeleteImageModal)} text='Cancelar' basic />
				<Button onClick={deleteImage(viewingImage)} color='red' text='Eliminar' />
			</ModalActions>
		</Modal>
		<Modal open={deleteModal} onClose={() => setDeleteModal(false)} size='tiny'>
			<Modal.Header>Eliminar producto</Modal.Header>
			<Modal.Content>
				<Header text={`¿${!product.deleted ? 'Eliminar' : 'Restaurar'} producto?`} subtext='El producto se podrá restaurar' />
				<Table
					details
					titleSize='small'
					title='Detalles'
					style={{ marginTop: 20 }}
					data={[
						['ID', product.product_id],
						['Nombre', product.name],
						['Creación', moment.unix(product.date_created).format('DD/MMM/YY HH:mm')]
					]}
				/>
			</Modal.Content>
			<ModalActions>
				<Button onClick={() => setDeleteModal(false)} text='Cancelar' basic/>
				<Button onClick={deleteProduct} color={product.deleted ? 'green' : 'red'} text={product.deleted ? 'Restaurar' : 'Eliminar'} />
			</ModalActions>
		</Modal>
		<Modal open={satCodeModal} onClose={bindClick(setSatCodeModal)} size='tiny'>
			<Modal.Header>Buscar código del SAT</Modal.Header>
			<Modal.Content scrolling>
				<Field label='Buscar código'>
					<Input value={satQuery} placeholder='Código del sat' onChange={setSatQuery} submitOnEnter onSubmit={searchSatCodes} button={
						<Button icon iconName='search'  size='tiny' onClick={searchSatCodes}/>
					}/>
				</Field>
				{satCodes && (
					<Table
						headers={['Código', 'Descripción']}
						selectable
						emptyText={satCodes.length === 0 && 'No se encontrarion códigos.'}
					>
						{satCodes.map(c=>(
							<Table.Row
								key={c.key}
								collapsingIndexes={[0]}
								onClick={() => updateSatCode(c)}
								data={[
									c.key,
									c.description
								]}
							/>
						))}
					</Table>
				)}
			</Modal.Content>
			<ModalActions>
				<Button onClick={bindClick(setSatCodeModal)} text='Cerrar' basic/>
			</ModalActions>
		</Modal>
	</div>
}

export default Product;
import React, { useEffect, useState } from 'react';
import { Corte as CorteClass } from '../Classes';
import { Table, Header, Pagination } from 'react-frontier';
import { addCommas } from '../Util';
import { useTitle } from '../Hooks';
import { Link } from 'react-router-dom';
import API from '../API';
import moment from 'moment';

const PAGE_SIZE = 20;

var PdvCortes = (props: any)=>{
	var { pdv_id } = props;
	var { setTitle } = useTitle();
	var [cortes, setCortes] = useState<CorteClass[]>(null);
	var [page, setPage] = useState<number>(0);
	var [loadError, setLoadError] = useState<string>(null);
	
	useEffect(() => {
		get();
		setTitle( pdv_id + '- Info');
	}, []);
	
	var get = (get_page?: number)=>{
		var offset = (get_page > 0) ? Math.max(0, (get_page || page) * PAGE_SIZE) : 0;
		setCortes(null);
		API.getPdvCortes(parseInt(pdv_id), offset, PAGE_SIZE).then(cortes=>{
			if(cortes.error) return setLoadError(cortes.message);
			return setCortes(cortes.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener los cortes. (GPC-1)'); 
		})
	}
	
	var onPageChange = (page: number)=>{
		setPage(page);
		get(page);
	}

   if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!cortes){
		return <Header loading text='Cargando datos' />
	}
	
	return <Table
		title='Cortes'
		selectable
		headers={['Corte','Usuario inicio', 'Usuario Final', 'Inicio', 'Final', 'Total']}
		emptyText={cortes.length === 0 && 'No se encontraron registros.'}
		footer={
			cortes.length >= PAGE_SIZE || page !== 0 ? (
				<Table.Row>
					<Table.Cell colSpan={7} style={{ textAlign: 'right' }}>
						<Pagination page={page} onPageChange={onPageChange} hasNext={cortes.length>=PAGE_SIZE} />
					</Table.Cell>
				</Table.Row>
			) : null }
	>
		{cortes.map(a=>(
			<Table.Row
				compact
				selectable
				as={Link}
				to={`/pdvs/${pdv_id}/cortes/${a.corte_id}`}
				collapsingIndexes={[0, 3, 4, 5]}
				key={`PDVCRT-${a.corte_id}`}
				data={[
					a.corte_id,
					a.user_start,
					a.user_end,
					moment.unix(a.start_date).format('DD/MMM/YY HH:mm'),
					moment.unix(a.end_date).format('DD/MMM/YY HH:mm'),
					'$'+addCommas(a.total)
				]}
			/>
		))}
	</Table>
}
	
export default PdvCortes;
import React, { useEffect, useState } from 'react';
import { Pdv } from '../Classes';
import { Table, Header } from 'react-frontier';
import API from '../API';

import { useTitle } from '../Hooks';
import { Link } from 'react-router-dom';
import moment from 'moment';

var Pdvs = ()=>{
	var { setTitle } = useTitle();
	var [pdvs, setPdvs] = useState<Pdv[]>(null);
	var [loadError, setLoadError] = useState<string>(null);

	useEffect(() => {
		get();
		setTitle('Punto de venta');
	}, []);

	var get = ()=>{
		API.getPdvList().then(list=>{
			if(list.error) return setLoadError(list.message);
			return setPdvs(list.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener los puntos de venta. (GPDV-1)'); 
		})
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!pdvs){
		return <Header loading text='Cargando datos' />
	}

	return <div>
		<Table
			selectable
			title='Puntos de Venta'
			style={{ maxWidth: 600, margin: 'auto' }}
			headers={['ID', 'Nombre', 'Fecha']}
			emptyText={pdvs.length === 0 && 'No se encontraron registros.'}
		>
			{pdvs.map(a=>(
				<Table.Row
					compact
					key={`PDV-${a.pdv_id}`}
					collapsingIndexes={[0, 2]}
					data={[
						a.pdv_id,
						a.name,
						moment.unix(a.date_created).format('DD/MMM/YY'),
					]}
					as={Link}
					to={`/pdvs/${a.pdv_id}`} 
				/>
			))}
		</Table>
	</div>
}

export default Pdvs;

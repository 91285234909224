import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';
import Button from './Button';
import moment from 'moment';
import classNames from 'classnames';

type CalendarMode = 'date' | 'datetime';
type CalendarInternalMode = CalendarMode | 'hours' | 'minutes';
interface CalendarProps{
	mode?: CalendarMode
	format?: string,
}
interface CalendarInternalProps extends CalendarProps{
	date: number,
	onSelected: (unix: number)=>void,
}

var CALENDAR_MONTHS = [
	'Enero', 'Febrero', 'Marzo',
	'Abril', 'Mayo', 'Junio',
	'Julio', 'Agosto', 'Septiembre',
	'Octubre', 'Noviembre', 'Diciembre'
]

var CALENDAR_WEEKDAYS = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

var Calendar = (props: CalendarInternalProps)=>{
	var valid_date = props.date && !Number.isNaN(props.date);
	var mdate = valid_date ? moment.unix(props.date) : moment();
	
	var selected_month = valid_date ? mdate.get('month') : null;
	var selected_day = valid_date ? mdate.get('D') : null;
	var selected_year = valid_date ? mdate.get('year') : null;

	var [month, setMonth] = useState(mdate.get('month'));
	var [year, setYear] = useState(mdate.get('year'));
	var [currentMode, setCurrentMode] = useState<CalendarInternalMode>('date');
	var [selectedDate, setSelectedDate] = useState<number>(null);
	var [selectedHours, setSelectedHours] = useState<number>(null);

	var mode = props.mode || 'datetime';

	var today = new Date();
	var first_day = new Date(year, month, 1, 12);
	var last_day = new Date(year, month+1, 0, 12);
	var month_days : number[][] = [[]];
	for(var i=0; i<first_day.getDay(); i++){
		month_days[0].push(null);
	}
	var current_week = 0;
	for(var d=0; d<last_day.getDate(); d++){
		month_days[current_week].push(d+1);
		if(month_days[current_week].length>=7 && (d+1)<last_day.getDate()){
			current_week++;
			month_days.push([]);
		}
	}

	var nextMonth = ()=>{
		if(month>=11){
			setMonth(0);
			setYear(year+1);
		}else setMonth(month+1);
	}

	var prevMonth = ()=>{
		if(month<=0){
			setMonth(11);
			setYear(year-1);
		}
		else setMonth(month-1);
	}
	
	var onSelectedDate = (day: number)=>{
		return ()=>{
			if(day===null) return;
			if(mode==='datetime'){
				setSelectedDate(day);
				setCurrentMode('hours');
			}else{
				var dt = new Date(year, month, day, 12);
				props.onSelected(Math.floor(dt.getTime()/1000));
			}
		}
	}

	var onSelectedHours = (hrs: number)=>{
		return ()=>{
			if(hrs<0 || hrs>23) return;
			setSelectedHours(hrs);
			setCurrentMode('minutes');
		}
	}

	var onSelectedMinutes = (mins: number)=>{
		return ()=>{
			var dt = new Date(year, month, selectedDate, selectedHours, mins, mins===59 ? 59 : 0);
			props.onSelected(Math.floor(dt.getTime()/1000));
		}
	}

	var CALENDAR_MONTH = <>
		<thead>
			<tr>
				<th onClick={prevMonth}><Icon name="arrow left" /></th>
				<th colSpan={5} style={{ textAlign: 'center', fontSize: 14 }}>{CALENDAR_MONTHS[month]} {year}</th>
				<th onClick={nextMonth}><Icon name="arrow right" /></th>
			</tr>
			<tr>{CALENDAR_WEEKDAYS.map((a, i)=><th key={`cal-wkd-${i}`}>{a.substring(0, 2)}</th>)}</tr>
		</thead>
		<tbody>
			{month_days.map((a, wx)=>(
				<tr key={`cal-week-${wx}`}>
					{a.map((b, i)=>(
						<td key={`cal-day-${i}`} className={classNames({ 
							empty: b===null, 
							last: last_day.getDate()===b && i<6,
							today: b==today.getDate() && month==today.getMonth() && year==today.getFullYear(),
						})}>
							<div
								onClick={onSelectedDate(b)}
								className={classNames({
									date: true,
									empty: b===null,
									active: b==selected_day && month==selected_month && year==selected_year,
								})}
							>{b}</div>
						</td>
					))}
				</tr>
			))}
		</tbody>
	</>;

	var CALENDAR_HOURS = <>
		<thead>
			<tr>
				<th onClick={()=>setCurrentMode(currentMode==='hours' ? 'date' : 'hours')} colSpan={6} style={{ textAlign: 'center' }}>
					{currentMode==='hours' ? (
						moment(new Date(year, month, selectedDate)).format('DD/MMM/YYYY')
					) : (
						moment(new Date(year, month, selectedDate, selectedHours)).format('DD/MMM/YYYY HH:00')
					)}
				</th>
			</tr>
		</thead>
		<tbody>
			{currentMode==='hours' ? (
				[0, 5, 9, 13, 17, 21].map(a=>(
					<tr key={`cal-hr-${a}`}>
						{Array.from(Array(4).keys()).map(b=>(
							<td className={(a+b)==24 ? 'empty' : ''} key={`cal-hr-${a+b}`}>
								{(a+b)==24 ? null : (
									<div onClick={onSelectedHours(a+b)} className={`time`}>{('0'+(a+b)).slice(-2)}:00</div>
								)}
							</td>
						))}
					</tr>
				))
			) : <>
				{[0, 20, 30, 40].map(a=>(
					<tr key={`cal-min-r${a}`}>
						{Array.from(Array(4).keys()).map(b=>(
							<td key={`cal-min-${a}${b}`}>
								<div onClick={onSelectedMinutes(a+(b*5))} className={`time`}>{('0'+selectedHours).slice(-2)}:{('0'+(a+(b*5))).slice(-2)}</div>
							</td>
						))}
					</tr>
				))}
				<tr>
					<td colSpan={4}>
						<div className="time" onClick={onSelectedMinutes(59)}>
							{selectedHours}:59
						</div>
					</td>
				</tr>
			</>}
		</tbody>
	</>

	return <table className="fr calendar table" style={{ borderRadius: 8 }}>
		{currentMode==='date' ? (
			CALENDAR_MONTH
		) : currentMode==='hours' ? (
			CALENDAR_HOURS
		) : currentMode==='minutes' ? (
			CALENDAR_HOURS
		) : null}
	</table>
}

interface InputProps{
	value?: string | number,
	readonly?: boolean,
	name?: string,
	onChange?: (val: any)=>void,
	label?: string,
	placeholder?: string,
	error?: boolean
	className?: string,
	comment?: string,
	inputType?: React.HTMLInputTypeAttribute,
	icon?: SemanticICONS,
	calendar?: boolean,
	calendarProps?: CalendarProps,
	autoFocus?: boolean,
	style?: React.CSSProperties,
	inputStyle?: React.CSSProperties,
	labelStyle?: React.CSSProperties,
	commentStyle?: React.CSSProperties,
	form?: object,
	required?: boolean,
	valueFormat?: (val: string)=>string,
	onFocus?: (ev: React.FocusEvent<HTMLInputElement>)=>void,
	onSubmit?: (setLoading: (loading: boolean)=>void, ...params: any)=>void,
	submitOnEnter?: boolean,
	actionText?: string,
	button?: JSX.Element,
	type?: 'error' | 'warning' | 'normal',
	as?: string,
	onKeyUp?: (ev: React.KeyboardEvent<HTMLInputElement>)=>void,
	maxLength?: number,
	actionProps?: any,
	onClick?: ()=>void,
}

var Input = React.forwardRef((props: InputProps, ref: LegacyRef<HTMLInputElement>)=>{
	var [calendarOpen, setCalendarOpen] = useState<boolean>(false);
	var [loading, setLoading] = useState<boolean>(false);
	var popupRef = useRef<HTMLDivElement>();
	var containerRef = useRef<HTMLDivElement>();

	useEffect(()=>{
		var mdown : any = null;
		if(props.calendar && popupRef.current && containerRef.current){
			mdown = (ev: MouseEvent)=>{
				if(!popupRef.current.contains(ev.target as Node) && !containerRef.current.contains(ev.target as Node)){
					setCalendarOpen(false);
				}
			}
			document.addEventListener('mousedown', mdown);
		}
		var keyup : any = null;
		if(props.submitOnEnter){
			keyup = (ev: KeyboardEvent)=>{
				if(ev.key==='Enter'){
					onSubmit();
				}
			}
			document.addEventListener('keyup', keyup);
		}
		return ()=>{
			if(mdown){
				document.removeEventListener('mousedown', mdown);
			}
			if(keyup){
				document.removeEventListener('keyup', keyup);
			}
		}
	}, [popupRef.current, containerRef.current, calendarOpen, props.value]);
	
	var onFocus = (ev: React.FocusEvent<HTMLInputElement>)=>{
		if(props.onFocus) props.onFocus(ev);
		setCalendarOpen(true);
	}

	var onCalendarSelect = (unix: number)=>{
		if(props.onChange) props.onChange(unix);
		setCalendarOpen(false);
	}

	var onChange = (ev: React.ChangeEvent<HTMLInputElement>)=>{
		if(props.form && props.name){
			// props.onFormChange()
			// props.onFormChange()
			// return props.onFormChange(props.name, ev.target.value);
		}else if(props.onChange){
			return props.onChange(ev.target.value);
		}
	}

	var onSubmit = ()=>{
		if(loading || !props.onSubmit) return;
		return props.onSubmit(setLoading);
	}

	var InputElem = <input
		ref={ref} 
		type={props.inputType || 'text'} 
		readOnly={props.readonly || props.calendar || false} 
		placeholder={props.placeholder || props.label} 
		onChange={onChange} 
		onKeyUp={props.onKeyUp}
		value={props.calendar && props.value && !Number.isNaN(parseInt(props.value as string)) ? (
			moment.unix(parseInt(props.value as string)).format(props.calendarProps?.format || ('DD/MM/YY'+(props.calendarProps?.mode && props.calendarProps.mode=='date' ? '' : ' HH:mm')))
		) : (
			props.value!==null ? (
				props.valueFormat ? props.valueFormat(props.value.toString()) : props.value
			) : ''
		)} 
		onFocus={onFocus}
		autoFocus={props.autoFocus}
		style={props.inputStyle}
		maxLength={props.maxLength}
		onClick={props.onClick}
	/>
	return <div className={props.as || "fr field"} style={props.style}>
		{props.label && (
			<label style={props.labelStyle}>
				{props.label}
				{props.required && <span style={{ marginLeft: 3, color: 'brown' }}>*</span>}
			</label>
		)}
		<div ref={containerRef} className={classNames('fr input', props.type, props.className, {
			action: !!(props.onSubmit || props.button),
			error: !!props.error,
			icon: props.icon,
			loading,
		})}>
			{props.comment && <div className="comment" style={props.commentStyle}>{props.comment}</div>}
			{props.icon && <Icon name={props.icon} />}
			{!props.calendar || props.readonly ? (
				InputElem
			) : (
				<Popup style={{ padding: 0, borderRadius: 8 }} trigger={InputElem} position='bottom left' open={calendarOpen} openOnTriggerFocus openOnTriggerMouseEnter={false} openOnTriggerClick={false}>
					<div ref={popupRef}>
						<Calendar date={parseInt(props.value as string)} onSelected={onCalendarSelect} {...props.calendarProps} />
					</div>
				</Popup>
			)}
			{props.onSubmit && props.actionText ? (
				<Button onClick={onSubmit} disabled={loading} text={props.actionText} {...props.actionProps} />
			) : null}
			{props.button}
		</div>
	</div>
});

export default Input;
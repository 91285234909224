import React, { useEffect, useState } from 'react';
import { Table, Button, Header, TaxDataModal } from '../components';
import { useTitle } from '../Hooks';
import API from '../API';

import { RFC } from '../Classes';
import { bindClick } from '../Util';

var RfcsView = ()=>{
   var { setTitle } = useTitle();
   var [taxDataList, setTaxDataList] = useState<RFC[]>(null);
   var [loadError, setLoadError] = useState<string>(null);
	var [createModal, setCreateModal] = useState<boolean>(false);

   useEffect(() => {
      setTitle('Datos Fiscales');
      get();
	}, []);

   var get = async()=>{
      API.getRfcList().then(res=>{
         if(res.error) return setLoadError(res.message);
			return setTaxDataList(res.data);
      }).catch(err=>{
         return setLoadError('Hubo un error al obtener los rfcs. (GRE-1)'); 
      })
   }

	var handleRfcCreation = (form: RFC) => {
		var list = [...taxDataList];
		list.push(form);
		setTaxDataList(list);
		setCreateModal(false);
  	}

   if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!taxDataList){
		return <Header loading text='Cargando datos' />
	}


   return <div>
      <Table
         style={{ maxWidth: 800, margin: 'auto' }}
         striped
			selectable
         title={'Datos fiscales'}
         headers={['ID', 'RFC', 'Razon Social', 'Email','CFDI', 'regimen']}
         button={<Button size='tiny' color='black' iconName='plus circle' text='Agregar'  onClick={bindClick(setCreateModal, true)}/>}
      >
         {taxDataList.length !== 0 ?(
					taxDataList.map(t => (
						<Table.Row
							key={t.tax_id}
                     href={`/rfc/${t.tax_id}`}
							collapsingIndexes={[0]}
							data={[
								t.tax_id,
                        t.rfc,
                        t.legal_name,
                        t.email,
                        t.use_cfdi,
                        t.regimen
							]}
						/>
					))
				) : (
					<Table.Row><Table.Cell className='empty' colSpan={10}>No se encontraron registros</Table.Cell></Table.Row>
				)
			}
      </Table>
		<TaxDataModal
			open={createModal}
			onClose={bindClick(setCreateModal)}
			onCreate={handleRfcCreation}
			deleted={0}
		/>
   </div>
}

export default RfcsView;
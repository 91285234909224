import React, { useEffect, useState } from 'react';

interface NotFoundProps{
	code?: string,
	subtext?: string,
	style?: React.CSSProperties
}

var NotFound = (props: NotFoundProps)=>{
	return <div className="fr centered header" style={{ fontSize: 100, ...props.style }}>
		{props.code || '404'}
		<div className="sub header" style={{ fontSize: 20 }}>
			{props.subtext || 'Página no encontrada'}
		</div>
	</div>
}

export default NotFound;
import React, { useEffect, useState } from 'react';
import { Input, Header, Groupper, Items, Button } from '../components';
import { Order } from '../Classes';
import { addCommas, cropString, days } from '../Util';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { Link, useParams } from "react-router-dom";
import { useTitle } from '../Hooks';
import moment from 'moment';
import API from '../API';
import classNames from 'classnames';

import '../style/orders.scss'

const PAGE_SIZE = 10;

var Orders = ()=>{
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [ordersWeb, setOrdersWeb] = useState<Order[]>(null);
	var [ordersPdv, setOrdersPdv] = useState<Order[]>(null);
	var [page, setPage] = useState<number>(0);
	var [search, setSearch] = useState<string>('');
	var [loading, setLoading] = useState<boolean>(false);
	var [searchTimeout, setSearchTimeout] = useState<any>(null);

	useEffect(() => {
		get();
		setTitle('Ordenes')
	}, []);

	var get = ()=>{
		API.getOrders(true, true, PAGE_SIZE, page*PAGE_SIZE).then(res=>{
			if(res.error) return setLoadError(res.message);
			return (
				setOrdersWeb(res.data.web),
				setOrdersPdv(res.data.pdv)
			);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener las ordenes. (GOS-1)');
		});
	};

	var searchOrder = (val: string)=>{
		setLoading(true);
		API.searchOrder(val, null).then(res=>{
			if (res.error) return setLoadError(res.message);
			setOrdersWeb(res.data);
			return setLoading(false);
		}).catch(err=>{
			setLoading(false);
			return setLoadError('Hubo un error al buscar la orden . (SOS-1)');
		})
	}

	var onInputSearch = (val: string)=>{
		setSearch(val);
		if(searchTimeout) clearTimeout(searchTimeout);
		if(val.length<3) return;

		setSearchTimeout(setTimeout(()=>{
			searchOrder(val)
		}, 400));
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	};

	if(!ordersPdv || !ordersWeb){
		return <Header loading text='Cargando datos' />
	};

	var OrderItem = (order: Order)=>(
		<div className={classNames('container', {
			shipped: order.shipped
		})}>
			<div className='name'>
				<div>{order.owner_name}</div>
				{!!order.shipping_date && (
					<div className="date">
						<Icon name={classNames({
							'shopping basket': order.shipping_method!==1,
							'truck': order.shipping_method===1,
						}) as SemanticICONS} /> 
						{moment.unix(order.shipping_date).format('DD/MMM/YY')}
					</div>
				)}
				<div className="date">
					<i className="pencil icon"></i>
					{moment.unix(order.date_created).format('DD/MMM/YY')}
				</div>
			</div>
			<div className='location'>
				<div>{cropString(order.city || 'Monterrey', 12, true)}</div>
				<div className='price'>${addCommas(order.total)}</div>
			</div>
			<Icon name={classNames({
				'shopping basket': order.shipping_method!==1,
				'truck': order.shipping_method===1,
			}) as SemanticICONS} color='black' />
		</div>
	)

	var pending_orders = ordersWeb.filter(a=>!!!a.shipped);
	var shipped_orders = ordersWeb.filter(a=>!!a.shipped);
	
	return <div className='co orders'>
		<Groupper title='Ordenes' style={{ maxWidth: 800 }} right={ <Button color='black' text='Nueva Orden' iconName='plus circle' as={Link} asProps={{ to: '/orders/new' }}/> }>
			<Input placeholder='Buscar en ordenes' onChange={onInputSearch}/>
			<div className='container'>
				<Groupper title='Ventas Web' defaultStyle={false}>
					{pending_orders.length >0 && <>
						<div className="section centered head small top">Pendientes</div>
						<Items
							data={pending_orders}
							selectable={true}
							keyExtractor={(order) => `ORDNSH-${order.order_id}`}
							linkExtractor={order => `/orders/${order.order_id}`}
							render={OrderItem}
						/>
					</>
					}
					{shipped_orders.length>0 && <>
						<div className={classNames("section centered head small", {
							top: pending_orders.length==0
						})}>Completadas</div>
						<Items
							data={shipped_orders}
							selectable={true}
							keyExtractor={(order) => `ORDSH-${order.order_id}`}
							linkExtractor={order => `/orders/${order.order_id}`}
							render={OrderItem}
						/>
					</>}
					<div style={{ textAlign: 'center', marginTop: 20}}>
						<Button text='Ver mas ordenes' fluid as={Link} asProps={{ to: '/online/orders' }}/>
					</div>
				</Groupper>
				{search.length==0 ? (
					<Groupper title='Ventas PDV' defaultStyle={false}>
						{ordersPdv.length === 0 ? (
							<Header text='No se encontraron ordenes de PDV' style={{ fontSize: 16, marginTop: 15, fontWeight: 'normal', color: 'gray' }} />
						) : (
							<Items 
								style={{ marginTop: -20 }}
								striped 
								data={ordersPdv}
								selectable={true}
								keyExtractor={(order) => `PDVO-${order.order_id}`}
								linkExtractor={order => `/orders/${order.order_id}`}
								render={(order)=>(
									<div className='container pdv'>
										<div className='left'>
											<div style={{ color: 'gray', fontSize: 12, lineHeight: '12px' }}>CCN-{order.order_id}</div>
											<div>{moment.unix(order.date_created).format('DD/MMM/YY hh:mm a')}</div>
										</div>
										<div className="right">
											<div style={{ color: 'brown' }}>${addCommas(order.total)}</div>
										</div>
									</div>
								)}
							/>
						)}
					</Groupper>
				) : null}
			</div>
		</Groupper>
	</div>
}

export default Orders;
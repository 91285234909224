import React, { useEffect, useState } from 'react';
import { Button, Groupper, Header, Input, Table } from 'react-frontier';
import { Dropdown, Icon, Modal } from 'semantic-ui-react';
import { Bar as BarChart } from 'react-chartjs-2'
import { addCommas, bindChange, bindClick, bindFormChange } from '../Util';
import { SearchProductsModal } from '../components';
import { ProductDateReportData, SetLoading } from '../Classes';
import Toast from 'react-hot-toast';
import moment from 'moment';
import classNames from 'classnames';
import API from '../API';

import '../style/products_report.scss';

interface GrouppedData{
	label: string,
	sort: number,
	quantity: number,
	total: number,
	products: {
		product_id: number,
		product_name: string,
		quantity: number,
		total: number,
	}[]
}

enum GroupType{
	DAY = 1,
	WEEK = 2,
	MONTH = 3,
	YEAR = 4,
}

interface SelectedProduct{
	product_id: number,
	product_name: string
}

var ProductReport = ()=>{
	var [config, setConfig] = useState({
		date_start: moment().subtract(7, 'day').unix(),
		date_end: moment().unix(),
		group: GroupType.DAY,
	});
	var [data, setData] = useState<ProductDateReportData[]>(null);
	var [products, setProducts] = useState<SelectedProduct[]>([])
	var [productsModal, setProductsModal] = useState<boolean>(false);
	var [productSearchModal, setProductSearchModal] = useState<boolean>(false);
	var [loadError, setLoadError] = useState<string>(null);
	var [loading, setLoading] = useState<boolean>(false);

	var searchData = ()=>{
		if(!products || products.length==0){
			return Toast.error('Favor de seleccionar uno o mas productos');
		}
		setLoading(true);
		setLoadError(null);
		API.getProductDateReport(config.date_start, config.date_end, products.map(a=>a.product_id)).then(res=>{
			if(res.error) return setLoadError(res.message);
			setData(res.data);
		}).catch(()=>{
			setLoadError('Hubo un error inesperado cargando los datos (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var getGroupType = (type: GroupType)=>{
		switch(type){
			case GroupType.DAY: return 'Día';
			case GroupType.MONTH: return 'Mes';
			case GroupType.WEEK: return 'Semana';
			case GroupType.YEAR: return 'Año';
		}
	}

	var groupped_data : GrouppedData[] = [];
	if(data){
		for(var i of data){
			var mdate = moment(i.date, 'YYYY-MM-DD');
			var sort_key : string = null, sort_val : number = 0;
			if(config.group===GroupType.DAY){
				sort_key = mdate.format('DD/MM/YYYY');
				sort_val = mdate.startOf('day').unix();
			}else if(config.group===GroupType.WEEK){
				sort_key = mdate.startOf('week').format('DD/MM/YYYY');
				sort_val = mdate.startOf('week').unix();
			}else if(config.group===GroupType.MONTH){
				sort_key = mdate.format('MMM/YYYY');
				sort_val = mdate.startOf('month').unix();
			}else if(config.group===GroupType.YEAR){
				sort_key = mdate.get('year').toString();
				sort_val = mdate.get('year');
			}
			if(!sort_key) continue;

			var ix = groupped_data.findIndex(a=>a.label===sort_key);
			if(ix===-1){
				groupped_data.push({
					label: sort_key,
					sort: sort_val,
					quantity: i.quantity,
					total: i.total,
					products: [{
						product_id: i.product_id,
						product_name: i.product_name,
						quantity: i.quantity,
						total: i.total,
					}]
				})
			}else{
				groupped_data[ix].quantity += i.quantity;
				groupped_data[ix].total += i.total;

				var pix = groupped_data[ix].products.findIndex(a=>a.product_id===i.product_id);
				if(pix===-1){
					groupped_data[ix].products.push({
						product_id: i.product_id,
						product_name: i.product_name,
						quantity: i.quantity,
						total: i.total,
					})
				}else{
					groupped_data[ix].products[pix].quantity += i.quantity;
					groupped_data[ix].products[pix].total += i.total;
				}
			}
		}
	}

	var onConfigChange = bindFormChange(config, setConfig);

	return <div className="co product-report-screen">
		<Groupper width={1200} title={'Reporte de productos'} fitted>
			<Groupper.Divider className='toolbar'>
				<Input style={{ margin: 0 }} placeholder='Fecha inicio' value={config.date_start} onChange={onConfigChange('date_start')} calendar={{
					date: config.date_start,
					mode: 'date',
					format: 'DD/MM/YYYY'
				}} />
				<Icon name='arrow right' />
				<Input style={{ margin: 0 }} placeholder='Fecha fin' value={config.date_end} onChange={onConfigChange('date_end')} calendar={{
					date: config.date_end,
					minDate: config.date_start,
					mode: 'date',
					format: 'DD/MM/YYYY'
				}} />
				
				<div className="dropdown" onClick={bindClick(setProductsModal, true)}>
					<div className="amount">{products.length}</div>
					producto{products.length===1 ? '' : 's'}
					<Icon name='caret down' />
				</div>
				<Dropdown icon={null} trigger={<>
					<div className="amount">Agrupar:</div>
					{getGroupType(config.group)}
					<Icon name='caret down' />
				</>} value={config.group} onChange={onConfigChange('group', true)} options={[
					{ text: 'Día', value: GroupType.DAY },
					{ text: 'Semana', value: GroupType.WEEK },
					{ text: 'Mes', value: GroupType.MONTH },
					{ text: 'Año', value: GroupType.YEAR },
				]}>
				</Dropdown>
				<Button text='Buscar' color='black' onClick={searchData} loading={loading} />
			</Groupper.Divider>
			<div className={classNames("chart", {
				empty: !data || loading || loadError,
			})}>
				{loading ? (
					<Header text='Buscando datos' loading />
				) : loadError ? (
					<Header text='Error' subtext={loadError} iconName='frown open' />
				) : data ? (
					<BarChart
						width={1200}
						height={500}
						options={{
							plugins: {
								tooltip: {
									callbacks: {
										label: (item)=>{
											if(!groupped_data[item.dataIndex]) return [];
											return [
												`${addCommas(groupped_data[item.dataIndex].quantity, true, true)} productos`,
												`$${addCommas(groupped_data[item.dataIndex].total)}`
											];
										}
									}
								},
								legend: {
									display: false
								}
							}
						}}
						data={{
							labels: groupped_data.map(a=>a.label),
							// labels: ['lo111l'],
							datasets: [{
								label: 'Sales',
								borderColor: '#DDCB09',
								borderWidth: 1,
								borderRadius: 12,
								backgroundColor: '#FFEA0390',
								data: groupped_data.map(a=>a.total),
								// data: [10]
							}]
						}}
					/>
				) : (
					<Header text='Sin datos' subtext='Favor de seleccionar la configuración' iconName='smile' style={{ color: '#CCC' }} subheaderStyle={{ color: '#CCC' }} />
				)}
			</div>
		</Groupper>
		{!!data && (
			<Table title='Ventas' style={{ maxWidth: 600, margin: 'auto', marginTop: 15 }}>
				{groupped_data.map(a=><React.Fragment key={`SLS-${a.label}`}>
					<Table.Row>
						<Table.Cell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }} colSpan={2}>{a.label}</Table.Cell>
						<Table.Cell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{addCommas(a.quantity, true, true)}</Table.Cell>
						<Table.Cell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>${addCommas(a.total)}</Table.Cell>
					</Table.Row>
					{a.products.map(p=>(
						<Table.Row key={`SLS-${a.label}-${p.product_id}`} data={[p.product_id, p.product_name, addCommas(p.quantity, true, true), `$${addCommas(p.total)}`]} />
					))}
				</React.Fragment>)}
			</Table>
		)}
		
		<Modal size='tiny' open={productsModal} onClose={bindClick(setProductsModal)}>
			<Modal.Header>Productos en reporte</Modal.Header>
			<Modal.Content>
				{!products || products.length===0 ? (
					<Header text='Sin productos' subtext='Selecciona los productos a ver en el reporte' iconName='smile' actions={(
						<Button text='Agregar productos' style={{ lineHeight: '1em' }} color='black' iconName={'plus circle'} onClick={bindClick(setProductSearchModal, true)} />
					)} />
				) : (
					<Table
						fitted
						title='Productos'
						collapsingIndexes={[0, 2]}
						button={(
							<Button size='tiny' text='Agregar' iconName='plus circle' color='black' onClick={bindClick(setProductSearchModal, true)} />
						)}
						headers={['ID', 'Producto', '']}
						data={products.map(a=>([
							a.product_id,
							a.product_name,
							<Button size='tiny' icon iconName='remove' onClick={()=>{
								setProducts(products.filter(p=>p.product_id!==a.product_id))
							}} />
						]))}
					/>
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setProductsModal, false)} />
			</Modal.Actions>
		</Modal>
		<SearchProductsModal
			open={productSearchModal}
			onClose={bindClick(setProductSearchModal, false)}
			onSelected={p=>{
				setProducts([
					...(products.filter(a=>a.product_id!==p.product_id)),
					{
						product_id: p.product_id,
						product_name: p.product_name
					}
				]);
				setProductSearchModal(false);
			}}
		/>
	</div>
}

export default ProductReport;
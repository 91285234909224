import React, { useEffect, useState } from 'react';
import { Button, Pagination, Sidebar, Table, Input, Message, Field } from 'react-frontier';
import { FilterType, Icons, ListFilters, ListOrder, SalesChannel, ShippingMethod, SortType, ProductReport } from '../Classes';
import { addCommas, bindChange, cropString, getOrderMethodText, bindClick } from '../Util';
import { Header, Input as I } from '../components';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Dropdown, Icon, SemanticICONS, Modal, ModalActions} from 'semantic-ui-react';
import API from '../API';
import Toast from 'react-hot-toast'
import moment from 'moment';
import classNames from 'classnames';
import Validator from '../Validator';

import '../style/dashboard.scss';

type TableHeaderKey = 'order_id' | 'order_method' | 'shipping_type' | 'owner_name' | 'owner_email' | 'status' | 'date_created' | 'date_paid' | 'shipping_date' | 'total' | 'prepared' | 'date_shipped' | 'user_assigned' | 'shipping_status' | 'payment_status';
const TABLE_HEADERS = {
	order_id: 'ID',
	order_method: 'Canal',
	shipping_type: 'Tipo',
	owner_name: 'Nombre',
	owner_email: 'Correo',
	status: 'Estatus',
	date_created: 'Creación',
	date_paid: 'Pago',
	shipping_date: 'Envío',
	total: 'Total',
	prepared: 'Preparada',
	date_shipped: 'Enviado',
	user_assigned: 'Responsable',
	shipping_status: 'Estatus envío',
	payment_status: 'Estatus',
}
const DEFAULT_COLUMNS : TableHeaderKey[] = ['order_id', 'date_created', 'status', 'shipping_type', 'shipping_date', 'owner_name', 'user_assigned', 'total'];
const TABLE_HEADER_NOT_COLLAPSING : TableHeaderKey[] = [
	'owner_name', 'owner_email',
]

interface ItemProps{
	active: boolean,
	name: string,
	pending?: number,
	showPending?: boolean,
	sales: number,
	icon: Icons,
	placeholder?: boolean,
	onClick?: ()=>void,
}
var SidebarItem = (props: ItemProps)=>{
	var showSales = typeof props.sales !== 'undefined';

	return (
		<div className={classNames('item', {
			active: props.active,
		})} onClick={props.onClick}>
			<div className="name">
				<i className={classNames('icon', props.icon)}></i>
				{props.name}
			</div>
			{/* {!props.placeholder ? (
				<div className="meta">
					{(props.showPending || (!!props.pending && props.pending>0)) ? `${props.pending} pendiente${props.pending==1 ? '' : 's'}${showSales ? ',' : ''} ` : ''}
					{showSales ? `${props.sales} venta${props.sales==1 ? '' : 's'} hoy` : ''}
				</div>
			) : (
				<div className="meta placeholder"></div>
			)} */}
		</div>
	)
}

var OrderTableRow = (props: { order: ListOrder, columns: TableHeaderKey[] })=>{
	var a = props.order;
	var today = moment();
	var isToday = (b: number)=>moment.unix(b).isSame(today, 'day');
	var created_today = isToday(a.date_created);
	var paid_today = isToday(a.date_paid);
	var is_pdv = a.order_method===SalesChannel.PDV;

	var getOrderColor = (order: ListOrder)=>{
		if(order.order_method===SalesChannel.PDV) return 'black';
		if(order.shipped) return 'gray';
		if(order.shipping_method===ShippingMethod.SHIPPING || order.shipping_type === 1) return '#3193aa';
		else if(order.shipping_method===ShippingMethod.PICKUP || order.shipping_method===ShippingMethod.PICKUP_HOUR) return '#d0800a';
		return 'black';
	}

	const TABLE_DATA = {
		order_id: a.order_id,
		order_method: getOrderMethodText(a.order_method),
		shipping_type: (
			<div style={{ 
				color: getOrderColor(a)
			}}>
				<i style={{ marginRight: 10 }} className={classNames("icon", {
					'shopping basket': a.order_method!==SalesChannel.PDV && a.shipping_type!==1,
					'truck': a.order_method!==SalesChannel.PDV && a.shipping_type===1,
					'cash register': a.order_method===1,
				})} />
				{a.shipped ? (
					a.order_method===1 ? 'PDV' : (a.shipping_method===1 ? 'Enviada' : 'Recogida')
					) : (
					a.order_method===1 ? 'PDV' : (a.shipping_type===1 ? 'Envío' : 'Pickup')
				)}
			</div>
		),
		owner_name: a.owner_name ? cropString(`${a.owner_name} ${a.owner_last_name || ''}`, 60, true) : '',
		owner_email: a.owner_email,
		status: (
			<div className={classNames("fr small label", {
				green: (is_pdv || a.shipped),
				orange: !a.shipped && a.prepared && !is_pdv,
				blue: a.paid && !a.shipped && !a.prepared,
			})}>
				<i className={classNames("icon", {
					check: is_pdv || a.prepared || a.shipped,
					clock: !is_pdv && !a.shipped && !a.prepared,
					remove: !a.prepared && !a.paid,
				})}></i>
				{/* {!a.paid ? 'No pagado' : (is_pdv ? 'PDV' : (a.shipped ? 'Enviada' : (a.prepared ? 'Ready' : 'Pendiente')))} */}
				{a.shipped ? 'Enviada' : (a.prepared ? 'Ready' : (!a.paid ? 'No pagado' : 'Pendiente'))}
			</div>
		),
		payment_status: (
			<div className={classNames("fr small label", {
				green: a.paid
			})}>
				<i className={classNames("icon", {
					check: a.paid,
					remove: !a.paid
				})}></i>
				{a.paid ? 'Pagado' : 'No pagado'}
			</div>
		),
		shipping_status: (
			<div className={classNames("fr small label", {
				green: a.shipped,
				orange: !a.shipped && a.prepared,
			})}>
				<i className={classNames("icon", {
					check: a.shipped || a.prepared,
					box: !a.prepared && a.prepared,
					clock: !a.shipped && !a.prepared
				})}></i>
				{a.shipped ? 'Enviado' : (a.prepared ? 'Ready' : 'Pendiente')}
			</div>
		),
		date_created: (
			<span style={{ 
				color: created_today ? 'green' : 'black',
				fontWeight: created_today ? 'bold' : 'normal',
			}}>
				{moment.unix(a.date_created).format('DD/MMM/YY HH:mm')}
			</span>
		),
		date_paid: (
			<span style={{ 
				color: paid_today ? 'green' : 'black',
				fontWeight: paid_today ? 'bold' : 'normal',
			}}>
				{moment.unix(a.date_paid).format('DD/MMM/YY HH:mm')}
			</span>
		),
		shipping_date: (
			(a.shipped && a.date_shipped) ? (
				moment.unix(a.date_shipped).format('DD/MMM/YY HH:mm')
			) : a.shipping_date ? (
				moment.unix(a.shipping_date).format('DD/MMM/YY')
			) : (
				<i className="minus grey icon"></i>
			)
		),
		total: addCommas(a.total),
		prepared: (
			<div className={classNames("fr small label", {
				orange: a.paid && !a.shipped && a.prepared && !is_pdv,
				blue: a.paid && !a.shipped && !a.prepared,
			})}>
				<i className={classNames("icon", {
					check: is_pdv || a.prepared || a.shipped,
					clock: !is_pdv && !a.shipped && !a.prepared,
				})}></i>
				{a.prepared ? 'Ready' : 'Pendiente'}
			</div>
		),
		date_shipped: (
			a.shipped && a.date_shipped ? (
				moment.unix(a.date_shipped).format('DD/MMM/YY HH:mm')
			) : (
				<i className="minus grey icon"></i>
			)
		),
		user_assigned: (
			<div style={{ textAlign: 'center' }}>
				{a.user_assigned ? (
					a.user_assigned_alias.toUpperCase()
					) : (
					<Icon name='minus' color='grey' />
				)}
			</div>
		),
	}

	return <Table.Row compact as={Link} to={`/orders/${a.order_id}`}>
		{(props.columns || DEFAULT_COLUMNS).map((a, i)=>(
			<Table.Cell key={`TBLRWORD-${props.order.order_id}-${a}-${i}`} collapsing={TABLE_HEADER_NOT_COLLAPSING.indexOf(a)==-1}>
				{TABLE_DATA[a] || <i className="minus grey icon"></i>}
			</Table.Cell>
		))}
	</Table.Row>
}

const PAGE_SIZE = 20;

interface SidebarChannel{
	channel_name: string,
	filters: ListFilters[],
	show_pending?: boolean,
	sales?: number,
	pending?: number,
	icon: Icons,
	table?: TableHeaderKey[],
}

var Dashboard = ()=>{
	var navigate = useNavigate();
	var [selectedItem, setSelectedItem] = useState<number>(null);
	var [sortType, setSortType] = useState<SortType>(parseInt(localStorage.getItem("sortType")) || SortType.SHIPPING_DATE);
	var [data, setData] = useState<ListOrder[]>(null);
	var [page, setPage] = useState<number>(0);
	var [loadError, setLoadError] = useState<string>(null);
	var [searchParams, setSearchParams] = useSearchParams();
	var [searchModal, setSearchModal] = useState<boolean>(false);
	var [searchInput, setSearchInput] = useState<string>(null);
	var [searchQuery, setSearchQuery] = useState<string>(null);
	var [reportModal, setReportModal] = useState<boolean>(null);
	var [startDate, setStartDate] = useState<number>(moment().subtract(7, 'd').unix());
	var [endDate, setEndDate] = useState<number>(moment().unix());
	var [errorModalPrompts, setErrorModalPrompts] = useState<string[]>(null);
	var [report, setReport] = useState<ProductReport[]>(null);
	var [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		var pageParam = parseInt(searchParams.get('p'));
		if(!pageParam || Number.isNaN(pageParam)){
			pageParam = 0;
		}
		setPage(pageParam);

		var channelParam = parseInt(searchParams.get('c'));
		if(!channelParam || Number.isNaN(channelParam)){
			channelParam = 0;
		}
		localStorage.setItem("sortType", sortType.toString());
		setSelectedItem(channelParam);
		setData(null);
		navigate(`?c=${channelParam}&p=${pageParam}`, {
			replace: true
		});
		loadOrders(searchQuery, CHANNELS[channelParam].filters, sortType, pageParam*PAGE_SIZE, PAGE_SIZE);
	}, [sortType]);

	useEffect(()=>{
		if(data){
			loadOrders(searchQuery, CHANNELS[selectedItem || 0].filters, sortType, page*PAGE_SIZE, PAGE_SIZE);
		}
	}, [sortType]);

	useEffect(()=>{
		searchParams.set('p', page.toString());
		searchParams.set('c', (selectedItem || 0).toString());
		setSearchParams(searchParams, {
			replace: true
		});
	}, [page, selectedItem]);

	var loadOrders = (query: string, filters: ListFilters[], sort: SortType, offset: number=0, count: number=PAGE_SIZE)=>{
		setData(null);
		setLoadError(null);
		API.listCustom(query, filters, sort, offset, count).then(res=>{
			if(res.error) return setLoadError(res.message);
			setData(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando las ordenes (LCL-1)');
		});
	}

	var onPageChange = (pg: number)=>{
		setData(null);
		setPage(pg);
		loadOrders(searchQuery, CHANNELS[selectedItem || 0].filters, sortType, pg*PAGE_SIZE, PAGE_SIZE);
	}

	var getSortName = ()=>{
		if(!selectedItem===null) return 'Fecha creación';
		switch(sortType){
			case SortType.SHIPPING_DATE: return 'Fecha envío';
			case SortType.CREATION_DATE: return 'Fecha creación';
		}
	}

	var viewChannel = (ix: number, channel: SidebarChannel)=>{
		return ()=>{
			setSelectedItem(ix);
			setSearchQuery(null);
			setSearchInput(null);
			loadOrders(null, channel.filters, sortType)
		}
	}

	var handleReport = (download: boolean=false)=>{
		var { valid, prompts } = Validator({ startDate, endDate }, {
			startDate: [{ rule: 'number', label: 'Fecha inicio' }],
			endDate: [{ rule: 'number', label: 'Fecha fin' }],
		});
		setErrorModalPrompts(prompts);
		if(!valid) return;

		var mstart = moment.unix(startDate).format('DD/MM/YYYY');
		var mend = moment.unix(endDate).format('DD/MM/YYYY');
		
		if(!download){
			setReport(null);
			setLoading(true);
			API.searchProductsReport(CHANNELS[selectedItem || 0].filters, mstart, mend).then(res=>{
				if(res.error) return setErrorModalPrompts([res.message]);
				return setReport(res.data);
			}).catch(err=>{
				setErrorModalPrompts(['Hubo un error inesperado obteniendo el reporte (LCL-1)']);
			}).finally(()=>{
				setLoading(false);
			})
		}else{
			API.downloadProductsReport(CHANNELS[selectedItem || 0].filters, mstart, mend).then(res=>{
				if(res.error) return setErrorModalPrompts([res.message]);
			}).catch(err=>{
				setErrorModalPrompts(['Hubo un error inesperado descargando el reporte (LCL-1)']);
			});
		}
	}

	var closeReportModal = ()=>{
		setReport(null);
		setReportModal(false);
	}

	var CHANNELS : SidebarChannel[] = [
		{ 
			channel_name: 'Pendientes', 
			icon: 'clock',
			filters: [
				{ channel: SalesChannel.CALLCENTER, paid: FilterType.UNDEFINED, shipped: FilterType.FALSE },
				{ channel: SalesChannel.ONLINE, paid: FilterType.TRUE, shipped: FilterType.FALSE },
				{ channel: SalesChannel.B2B, paid: FilterType.UNDEFINED, shipped: FilterType.FALSE },
				{ channel: SalesChannel.PERSONALIZED, paid: FilterType.UNDEFINED, shipped: FilterType.FALSE },
			],
			table: ['order_id', 'order_method', 'date_created', 'status', 'shipping_type', 'shipping_date', 'owner_name', 'user_assigned', 'total'],
		},
		{
			channel_name: 'Mis ordenes',
			icon: 'user',
			filters: [
				{ channel: null, paid: FilterType.UNDEFINED, shipped: FilterType.FALSE, user_assigned: -1 }
			],
			table: ['order_id', 'date_created', 'status', 'shipping_type', 'shipping_date', 'owner_name', 'total']
		},
		{
			channel_name: 'Online Store',
			icon: 'globe',
			filters: [
				{ channel: SalesChannel.ONLINE, paid: FilterType.TRUE, shipped: FilterType.UNDEFINED }
			],
		},
		{
			channel_name: 'Call Center',
			icon: 'phone',
			filters: [
				{ channel: SalesChannel.CALLCENTER, paid: FilterType.UNDEFINED, shipped: FilterType.UNDEFINED },
			],
		},
		{
			channel_name: 'Empresariales',
			icon: 'building',
			filters: [
				{ channel: SalesChannel.B2B, paid: FilterType.UNDEFINED, shipped: FilterType.UNDEFINED }
			],
			table: ['order_id', 'date_created', 'payment_status', 'shipping_status', 'shipping_type', 'shipping_date', 'owner_name', 'user_assigned', 'total'],
		},
		{
			channel_name: 'Personalizados',
			icon: 'palette',
			filters: [
				{ channel: SalesChannel.PERSONALIZED, paid: FilterType.UNDEFINED, shipped: FilterType.UNDEFINED }
			],
			table: ['order_id', 'date_created', 'payment_status', 'shipping_status', 'shipping_type', 'shipping_date', 'owner_name', 'user_assigned', 'total'],
		},
		{
			channel_name: 'PDV',
			icon: 'cash register',
			filters: [
				{ channel: SalesChannel.PDV, paid: FilterType.TRUE }
			],
			table: ['order_id', 'date_created', 'status', 'owner_email', 'total']
		},
		{
			channel_name: 'Todas',
			icon: 'birthday cake',
			filters: [
				{ channel: null, paid: FilterType.TRUE }
			],
		},
	]

	var getTableHeaders = ()=>{
		var channel = CHANNELS[selectedItem || 0];
		var headers : string[] = [];
		for(var i of (channel.table || DEFAULT_COLUMNS)){
			headers.push(TABLE_HEADERS[i]);
		}
		return headers;
	}

	var searchOrder = async (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ searchInput }, {
			searchInput: [{ rule: 'minLength', params: [3], label: 'Busqueda' }],
		});
		if(prompts && prompts.length>0){
			Toast.error(prompts.join('\n'), {
				position: 'top-center',
				style: { marginTop: 30 },
			});
		}
		if(!valid) return;
		setSearchQuery(searchInput);
		setSearchModal(false);
		loadOrders(searchInput, CHANNELS[selectedItem].filters, sortType);
	}

	var removeSearch = ()=>{
		setSearchInput('');
		setSearchQuery('');
		loadOrders(null, CHANNELS[selectedItem].filters, sortType);
	}

	var selected_channel = CHANNELS[selectedItem || 0];
	
	return <div className="co dashboard">
		{searchModal && (
			<div className="co searchbar">
				<Input label={`Buscar en ${CHANNELS[selectedItem].channel_name}`} value={searchInput} onChange={setSearchInput} onSubmit={searchOrder} submitOnEnter />
				<Button size='tiny' text='Cerrar' onClick={bindClick(setSearchModal)} iconName={'remove'} basic />
			</div>
		)}
		<Sidebar title='Ordenes' header='Ordenes'>
			{CHANNELS.map((a,i)=>(
				<SidebarItem key={`chnl-${i}`} name={a.channel_name} active={i===selectedItem} icon={a.icon} sales={a.sales} pending={a.pending} placeholder={false} onClick={viewChannel(i, a)} showPending={a.show_pending} />
			))}
			<Button text="Crear orden" color="black" size="tiny" fluid style={{ maxWidth: 150, margin: '10px auto 0 auto' }} as={Link} to={'/orders/new'} />
			<Button text="Asignar ordenes" basic color="black" size="tiny" fluid style={{ maxWidth: 150, margin: '10px auto 0 auto' }} as={Link} to={'/orders/assign'} />
		</Sidebar>
		<div className="content">
			<div className="toolbar">
				<Header 
					centered={false} 
					text={selectedItem ? CHANNELS[selectedItem].channel_name : 'COCOAN'} 
					subtext={searchQuery ? `Buscando: ${searchQuery}` : null}
					style={{ margin: 0, overflow: 'hidden' }}
					contentStyle={{ padding: 0, overflow: 'hidden' }}
				/>
				<div className="actions">
					{!!searchQuery && (
						<Button text='Quitar busqueda' iconName='remove' size='tiny' color='black' basic onClick={removeSearch} />
					)}
					<Button text="Buscar" iconName="search" basic size="tiny" style={{ marginRight: 5 }} onClick={bindClick(setSearchModal, true)} />
					<Dropdown disabled={selectedItem===null} trigger={(
						<Button size="tiny" color="black" text={getSortName()} iconName={'sort amount up'} />
					)} icon={null} options={[
						{ text: 'Fecha envío', value: SortType.SHIPPING_DATE },
						{ text: 'Fecha creación', value: SortType.CREATION_DATE }
					]} onChange={bindChange(setSortType, true)} />
					<Button icon iconName="bar chart" color='black' size='tiny' onClick={bindClick(setReportModal, true)} style={{ marginLeft: 5 }} />
				</div>
			</div>
			{loadError ? (
				<Header text='Error' subtext={loadError} iconName='frown open' />
			) : data ? (
				<Table 
					striped
					selectable
					headers={getTableHeaders()}
					emptyText={data.length === 0 && 'No se encontraron ordenes.'}
					footer={(
						<tr>
							<td colSpan={9} style={{ textAlign: 'right' }}>
								<Pagination page={page} onPageChange={onPageChange} />
							</td>
						</tr>
					)}
				>
					{data.map(a=>(
						<OrderTableRow key={`ord-${a.order_id}`} order={a} columns={selected_channel.table} />
					))}
				</Table>
			) : (
				<Header text='Cargando ordenes' contentStyle={{ paddingTop: 5 }} loading />
			)}
		</div>
		<Modal open={reportModal} onClose={closeReportModal} size='tiny'>
			<Modal.Header>Reporte</Modal.Header>
			<Modal.Content>
				<Field amount={2}>
					<I label='Fecha de inicio' value={startDate} onChange={setStartDate} calendar calendarProps={{ mode: 'date', format: 'DD/MM/YYYY',}}/>
					<I label='Fecha de fin' value={endDate} onChange={setEndDate} calendar calendarProps={{ mode: 'date', format: 'DD/MM/YYYY',}}/>
				</Field>
				{report && (
					report.length === 0 ? 
					<Message type='error' text='No se encontraron registros relacionados.' /> : 
					<div style={{ textAlign: 'end' }}><Button text='Descargar' color='black' style={{ minWidth: 20 }} onClick={() => handleReport(true)}/></div>
				)}
				<Message list={errorModalPrompts} type={'error'} />
			</Modal.Content>
			<ModalActions>
				<Button text='Cerrar' basic style={{ minWidth: 20 }} onClick={closeReportModal} />
				<Button text='Enviar' color='black' style={{ minWidth: 20 }} onClick={() => handleReport(false)} loading={loading}/>
			</ModalActions>
		</Modal>
		{!!searchModal && (
			<div className="co dimmer" onClick={bindChange(setSearchModal)}></div>
		)}
	</div>
}

export default Dashboard;
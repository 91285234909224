import libphonenumber from 'libphonenumber-js';
import { SalesChannel } from "./Classes";

const bindClick = <T>(setVal: (val: T)=>void, val: T=null)=>{
	return ()=>{
		setVal(val);
	}
}

const bindChange = (setVal: (val: any, ...args: any)=>void, semantic: boolean=false)=>{
	return (e: { target: { value: string } } | any, data?: { value?: any, checked?: any })=>{
		if(semantic){
			return setVal(data.checked || data.value);
		}else{
			return setVal(e.target.value);
		}
	}
}

const bindSemantic = (setVal: (val: any)=>void)=>{
	return (e: any, data: { value?: any, checked?: any })=>{
		return setVal(data.checked || data.value);
	}
}

const bindSemanticForm = (val: any, setVal: (val: any)=>void)=>{
	return (e: any, data: { name?: string, value?: any, checked?: boolean })=>{
		if(data.name && typeof data.checked!=='undefined'){
			setVal({ ...val, [data.name]: data.checked });
		}else if(data.name){
			setVal({ ...val, [data.name]: data.value });
		}
	}
}

const bindToggle = (val: any, setVal: any)=>{
	return ()=>{
		setVal(!val);
	}
}

const bindFormChange = <T>(val: T, setVal: (val: any, ...args: any)=>void)=>{
	return (name: keyof T, semantic: boolean=false, target: boolean=false)=>{
		return (e: { target: { value: string } } | any, data?: { value?: any, checked: boolean } | any)=>{
			if(semantic){
				setVal({ ...val, [name]: typeof data.checked!=='undefined' ? data.checked : data.value });
			}else if(target){
				setVal({ ...val, [name]: e.target.value })
			}else{
				setVal({ ...val, [name]: e });
			}
		}
	}
}

const bindSimpleFormChange = <T>(val: T, setVal: (val: any)=>void)=>{
	return (name: keyof T)=>{
		return (v: any)=>{
			setVal({ ...val, [name]: v });
		}
	}
}

function addCommas(num: number, fixed: boolean=true, hideZero: boolean=false){
	if (!num && num !== 0) return 'ERR';
	if(typeof num==='string'){
		num = parseFloat(num as string);
	}
	var c = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	var dec = c.substring(c.length-2, c.length);
	return fixed ? (hideZero && dec == '00' ? c.slice(0, -3) : c) : c.slice(0, -3);
}

function unix(){
	return Math.floor(new Date().getTime()/1000);
}

function isActive(date_start: number, date_end: number){
	var now = unix();
	return now>=date_start && now<=date_end;
}

function uniqueArray<T>(array: T[]){
	return Array.from<T>(new Set(array));
}

function groupBy<T>(objs: T[], key: keyof T, inherit: (keyof T)[]=null){
	var k = uniqueArray(objs.map(a=>a[key]));
	var result = [];
	for(let i of k){
		var r : any = {}
		r[key] = i;
		r['values'] = objs.filter(a=>a[key]==i);
		result.push(r);
	}
	if(inherit && Array.isArray(inherit)){
		for(let i of result){
			for(let j of inherit){
				i[j] = i['values'][0][j];
			}
		}
	}
	return result as any[];
}

function validatePassword(val: string){
	return (/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*\.\,\+\*])[a-zA-Z0-9!@#$%^&*\.\,\+\*]{8,32}$/).test(val);
}

function partition<T>(a: T[], n: number) : T[][]{
	var array = [...a];
	return array.length ? [array.splice(0, n)].concat(partition(array, n)) : [];
}

function cropString(str: string, len: number, elip: boolean=false){
	if(!str) return null;
	var elip = elip && str.length>len;
	return str.trim().substring(0, len-(elip ? 3 : 0)).trim() + (elip ? '...' : '');
}

function randomRange(min: number, max: number){
	return Math.floor(Math.random() * (max-min+1)+min);
}

function commission(total: number, method: number){
	switch(method){
		case 2: return (total*0.029+2.5+((total*0.029+2.5)*0.16)).toFixed(2);
		case 10: return (total*0.035+((total*0.035)*0.16)).toFixed(2);
		default: return 'DESCONOCIDO';
	}
}

function days(date: number){
	const currentTime = new Date().getTime();
	const givenTime = new Date(date * 1000);
	const timeDifference = currentTime - givenTime.getTime();
	const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	if(daysDifference > 1){
		return daysDifference + 'd'
	}else{
		return 'Hoy'
	}
}

function validatePhone(phone: string){
	if(!phone || phone.length<10) return false;
	var valid = libphonenumber(phone.toString(), 'MX');
	return valid && valid.isValid();
}

function orderMethodLocale(order_method: SalesChannel){
	switch(order_method){
		case SalesChannel.B2B: return 'Empresarial';
		case SalesChannel.ONLINE: return 'Online';
		case SalesChannel.PDV: return 'PDV';
		case SalesChannel.PERSONALIZED: return 'Personalizada';
		default: return 'UNKNWN';
	}
}

interface AddressComponents {
	long_name: string;
	short_name: string;
	types: string[];
}

interface Geometry {
	location: {
		 lat: any;
		 lng: any;
	}
}

interface PlaceResult {
	formatted_address: string;
	address_components: AddressComponents[];
	geometry: Geometry;
}

function formatPlaceResult(placeResult: PlaceResult): { [key: string]: any } {
	var formattedData: { [key: string]: any } = {}

	if (placeResult.address_components && Array.isArray(placeResult.address_components)) {
		placeResult.address_components.forEach(component => {
			formattedData[component.types[0]] = component.long_name;
		});
	}

	formattedData['coordinates'] = {
		lat: placeResult.geometry.location.lat(),
		lng: placeResult.geometry.location.lng(),
	}

	formattedData['formatted_address'] = placeResult.formatted_address;
	return formattedData;
}

function getOrderMethodText(order_method: number){
	switch (order_method){
	case 1:
		return 'PDV';
	case 2:
		return 'Online';
	case 20:
		return 'Empresarial';
	case 21:
		return 'Personalizada';
	case 22:
		return 'Call Center';
	default:
		return '???';
	}
}

export {
	addCommas,
	bindChange,
	bindClick,
	bindFormChange,
	bindSemantic,
	bindSemanticForm,
	bindSimpleFormChange,
	bindToggle,
	commission,
	cropString,
	days,
	formatPlaceResult,
	getOrderMethodText,
	groupBy,
	isActive,
	orderMethodLocale,
	partition,
	randomRange,
	uniqueArray,
	unix,
	validatePassword,
	validatePhone,
}
import React, { useEffect, useState, useRef } from "react";
import { Groupper, Button, Table, Header, Input, Message } from "../components";
import { bindClick, bindFormChange } from '../Util';
import { ClosedDate, CreateCloseDate, SetLoading } from "../Classes";
import { useTitle } from '../Hooks';
import { Icon, Modal, ModalActions, Checkbox } from "semantic-ui-react";
import moment from "moment";
import Validator from '../Validator';
import API from "../API";
import Toast from "react-hot-toast";

var Shipments = ()=>{
   var { setTitle } = useTitle();
   var [loadError, setLoadError] = useState<string>(null);
   var [dates, setDates] = useState<ClosedDate[]>(null);
   var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
   var [dateModal, setDateModal] = useState<boolean>(false);
   var [selectedDate, setSelectedDate] = useState<ClosedDate>(null);
   var [dateForm, setDateForm] = useState<CreateCloseDate>({ date: null, shipping: false, pickup: false })

   useEffect(() => {
		get();
      setTitle('Envios cerrados')
	}, []);

   var get = ()=>{
      API.getClosedDates().then(res=>{
         if(res.error) return setLoadError(res.message);
         return setDates(res.data);
      }).catch(err=>{
         return setLoadError('Hubo un error al obtener las fechas. (GCD-1)');
      })
   }

   var createDate = (setLoading: SetLoading)=>{
      if(!dateForm.pickup && !dateForm.shipping) return setErrorPrompts(['Debes seleccionar un tipo de fecha.']);
      var { valid, prompts } = Validator(dateForm, {
			date: [{
				rule: 'empty', params: [3], label: 'Fecha'
			}],
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		setLoading(true);
      API.createClosedDate(dateForm.date, dateForm.shipping, dateForm.pickup).then(res=>{
         if(res.error) return setErrorPrompts([res.message]);
         setDateModal(false);
         get();
         return Toast.success('Fecha agregada!');
      }).catch(err=>{
         setErrorPrompts(['Hubo un error inesperado agregando la fecha (CCD-1)']);
      }).finally(()=>{
         setLoading(false);
      })
   }

   var deleteDate = async(setLoading: SetLoading)=>{
      setLoading(true);
      API.deleteClosedDate(selectedDate.date_id).then(res=>{
         if(res.error) return Toast.error(res.message);
         var newDates = dates.filter(d => d.date_id !== selectedDate.date_id);
			setSelectedDate(null);
         setDates(newDates);
         return Toast.success('Fecha eliminada!')
      }).catch(err=>{
         return Toast.error('Hubo un error inesperado al eliminar la fecha. (DCD-1)')
      }).finally(()=>{
         setLoading(false);
      })
   }

   var deleteConfirmation = (date: ClosedDate)=>{
      setSelectedDate(date)
   }

   var onCreateForm = bindFormChange(dateForm, setDateForm);

   if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(dates===null){
		return <Header loading text='Cargando datos' />
	}

   return <div>
      <Groupper title="Fechas deshabilitadas" style={{ maxWidth: 600}} right={(
			<Button size="tiny" text="AGREGAR" color="black" onClick={() => setDateModal(true)} iconName="plus circle"/>
		)}>
         <Table striped
            title="Shipping"
            style={{ marginTop: -20 }}
            headers={['Fecha', <Icon name="close" style={{ marginLeft: 20}}/>]}
            collapsingIndexes={[1]}
         >
            {!dates || dates.filter(a => a.shipping_type === 1).length === 0 ? (
					<Table.Row>
						<Table.Cell className='empty' colSpan={2}>No hay fechas deshabilitadas</Table.Cell>
					</Table.Row>
				) : dates.filter(a => a.shipping_type === 1).map(d=>(
					<Table.Row>
						<Table.Cell>{moment.unix(d.date).format('DD/MMM/YY')}</Table.Cell>
						<Table.Cell collapsing>
							<Button size="tiny" iconName="close" onClick={() => deleteConfirmation(d)}/>
						</Table.Cell>
					</Table.Row>
				))}
         </Table>
         <Table striped
            title="Pickup"
            headers={['Fecha', <Icon name="close" style={{ marginLeft: 20}}/>]}
            collapsingIndexes={[1]}
         >
            {!dates || dates.filter(a => a.shipping_type !== 1).length === 0 ? (
					<Table.Row>
						<Table.Cell className='empty' colSpan={2}>No hay fechas deshabilitadas</Table.Cell>
					</Table.Row> 
				) : dates.filter(a => a.shipping_type !== 1).map(d=>(
					<Table.Row>
						<Table.Cell>{moment.unix(d.date).format('DD/MMM/YY')}</Table.Cell>
						<Table.Cell collapsing>
							<Button size="tiny" iconName="close" onClick={()=> deleteConfirmation(d)}/>
						</Table.Cell>
					</Table.Row>
				))}
         </Table>
      </Groupper>
      <Modal open={dateModal} onClose={bindClick(setDateModal)} size="mini">
         <Modal.Header>Deshabilitar fecha</Modal.Header> 
         <Modal.Content>
            <Input label='Fecha' value={dateForm.date} onChange={onCreateForm('date')} calendar calendarProps={{ mode: 'date', format: 'DD/MM/YYYY',}}/>
            <Checkbox toggle style={{ display: 'block', marginTop: 15 }} label='Shipping' checked={!!dateForm.shipping} onChange={onCreateForm('shipping', true)}/>
            <Checkbox toggle style={{ display: 'block', marginTop: 15 }} label='Pickup' checked={!!dateForm.pickup} onChange={onCreateForm('pickup', true)}/>
            <Message type="error" list={errorPrompts} style={{ marginTop: 15 }} />
         </Modal.Content>
         <ModalActions>
				<Button onClick={bindClick(setDateModal)} text='Cancelar' basic/>
				<Button text="Agregar" color="black" onClick={createDate}/>
			</ModalActions>
      </Modal>
		<Modal open={!!selectedDate} onClose={bindClick(setSelectedDate, null)} size="mini">
         <Modal.Header>Confirmación</Modal.Header> 
      	{selectedDate && <Modal.Content>
				<Header text={`¿Eliminiar fecha de ${selectedDate.shipping_type === 1 ? 'shipping' : 'pickup'}?`} />
				<Table 
					details
					titleSmall
					title="Detalles"
					style={{ marginTop: 20 }}
					data={[
						['Fecha', moment.unix(selectedDate.date).format('DD/MMM/YY')],
						['Tipo', selectedDate.shipping_type === 1 ? 'Shipping' : 'Pickup'],
					]}
				/>
         </Modal.Content>}
         <ModalActions>
				<Button onClick={bindClick(setSelectedDate, null)} text='Cancelar' basic />
				<Button text="Eliminar" color="red" onClick={deleteDate}/>
			</ModalActions>
      </Modal>
   </div>
}

export default Shipments;
import React, { useEffect, useState } from 'react';
import { Groupper, Input, Header, Button, Message } from 'react-frontier';
import { Pdv } from '../Classes';
import { useTitle } from '../Hooks';
import { bindFormChange } from '../Util';
import API from '../API';
import moment from 'moment';
import Validator from '../Validator';
import Toast from 'react-hot-toast';

var PdvInfo = (props: any)=>{
	var { pdv_id } = props;
	var { setTitle } = useTitle();
	var [info, setInfo] = useState<Pdv>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	
	useEffect(() => {
		get();
		setTitle(`PDV ${pdv_id}`);
	}, []);
	
	var get = ()=>{
		API.getPdv(pdv_id).then(pdv=>{
			if(pdv.error) return setLoadError(pdv.message);
			setTitle(`PDV ${pdv.data.pdv_id}`);
			return setInfo(pdv.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener el de venta. (GPDV-1)'); 
		})
	}
	
	var edit = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(info, {
			name: [{
				rule: 'minLength' , params: [3], label: 'Nombre'
			}],
			pdv_id: [{
				rule: 'number', label: 'ID'
			}]
		})
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editPdv(info.pdv_id, info.name).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			return Toast.success('Punto de venta actualizado!')
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado editando el punto de venta (U-CRT-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}
	
	var onEdit = bindFormChange(info, setInfo);
	
	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}
	
	if(!info){
		return <Header loading text='Cargando datos' />
	}
	
	return <Groupper
		title={'PDV #' + info.pdv_id}
		width={500}
		actions = {
			<Button text='Guardar' color='black' onClick={edit}/>
		}
		>
		<Input label='ID' value={info.pdv_id} readonly />
		<Input label='Nombre' value={info.name} onChange={onEdit('name')}/>
		<Input label='Token' value={info.access_token} readonly />
		<Input label='Creacion' value={moment.unix(info.date_created).format('DD/MMM/YY')} readonly />
		<Message type="error" list={errorPrompts} />
	</Groupper>
}

export default PdvInfo;
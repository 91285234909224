import SearchCoreModal, { SearchCoreProps } from './SearchCoreModal';
import { Category } from '../Classes';
import API from '../API';

var SearchCategoryModal = (props: SearchCoreProps<Category>)=>{
	return <SearchCoreModal
		title='Buscar Categoria'
		{...props}
		searchData={(query, page, page_size)=>{
			return API.searchCategory(query, page * page_size, page_size);
		}}
		pageSize={10}
		table={[
			{ name: 'ID', key: 'category_id', collapsing: true },
			{ name: 'Nombre', key: 'category_name', collapsing: true },
		]}
	/>
}

export default SearchCategoryModal;
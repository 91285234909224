import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import NotFound from './NotFound';
import { useTitle } from '../Hooks';

interface SidebarRoute{
	url: string,
	render: (...params: any)=>JSX.Element
}

interface SidebarItem{
	text: string,
	icon?: string,
	url: string,
	target?: '_blank' | '_self' | '_parent' | '_top',
	selectable?: boolean,
}

interface SidebarProps{
	breakpoint?: number,
	header?: string,
	routes: SidebarRoute[],
	items: SidebarItem[],
	routeParams?: any,
	getTitle?: (r: SidebarRoute, ix: number)=>string,
	contentHeader?: JSX.Element,
}

var SidebarContainer = (props: SidebarProps)=>{
	const SIDEBAR_BREAKPOINT = props.breakpoint || 675;
	var location = useLocation();
	var [mobile, setMobile] = useState<boolean>(false);
	var [sectionIndex, setSectionIndex] = useState<number>(null);
	var [drawer, setDrawer] = useState<boolean>(false);
	var { setTitle } = useTitle();

	useEffect(()=>{
		var onResize = ()=>{
			if(!mobile && window.innerWidth<=SIDEBAR_BREAKPOINT){
				setMobile(true);
			}else if(mobile && window.innerWidth>SIDEBAR_BREAKPOINT){
				setMobile(false);
			}
		}
		window.addEventListener('resize', onResize);
		setMobile(window.innerWidth<=SIDEBAR_BREAKPOINT)
		
		var new_sect = getSectionFromUrl(location.pathname);
		var found_index = null;
		for(var i=0; i<props.routes.length; i++){
			var sect = getSectionFromUrl(props.routes[i].url)
			if(sect===new_sect){
				found_index = i;
				break;
			}
			if(!sect) continue;
			var sect_reg = new RegExp(sect.replace(/\:([a-z0-9_]+)/gi, (m, p)=>`([^\/]+)`) + '$', 'gi');
			if(sect_reg.test(new_sect)){
				found_index = i;
				break;
			}
		}

		if(sectionIndex!==found_index){
			setDrawer(false);
			setSectionIndex(found_index);
			if(props.getTitle){
				var title = props.getTitle(props.routes[found_index], found_index);
				if(title){
					setTitle(title);
				}
			}
		}
		return ()=>{
			window.removeEventListener('resize', onResize);
		}
	}, [mobile, location.pathname]);

	var getSectionFromUrl = (url: string)=>{
		return url;
		// var reg = new RegExp(props.locationRegexp);
		// var reg_res = reg.exec(url);
		// return reg_res && reg_res.groups.section && reg_res.groups.section.length>0 ? reg_res.groups.section : null;
	}

	var getParamsFromUrl = (template: string, url: string)=>{
		var reg = template.replace(/\:([a-z0-9_]+)/g, (m, p)=>`(?<${p}>[a-z0-9_]+)`);
		var reg_res = new RegExp(reg+'$', 'gi').exec(url);
		return reg_res?.groups || {}
	}

	var isItemActive = (url: string)=>{
		var current_section = getSectionFromUrl(location.pathname);
		var check_section = getSectionFromUrl(url);

		return current_section===check_section;
	}

	var toggleDrawer = ()=>{
		setDrawer(!drawer);
	}

	var Content = null, content_params = {};
	if(sectionIndex!==null){
		if(props.routes[sectionIndex].render){
			Content = props.routes[sectionIndex].render;
			content_params = getParamsFromUrl(props.routes[sectionIndex].url, location.pathname);
		}
	}
	
	return <div className={`fr ${mobile ? 'mobile' : ''} sidebar`} style={{ marginTop: -10 }}>
		{!mobile ? (
			<div className="sidebar" style={{ position: 'fixed' }}>
				{!!props.header && (
					<div className="header item">{props.header}</div>
				)}
				{props.items.map((a, i)=>(
					<Link className={classNames('item', {
						active: a.selectable!==false && isItemActive(a.url),
					})} replace={false} to={a.url} key={a.url} target={a.target || '_self'}>
						{!!a.icon && (
							<i className={`${a.icon} icon`}></i>
						)}
						{a.text}
					</Link>
				))}
			</div>
		) : (
			<div className={`drawer ${drawer ? 'active' : ''}`}>
				<div className="header" onClick={toggleDrawer}>
					<div className="text">{props.header}</div>
					<i className="caret down icon"></i>
				</div>
				{props.items.map((a, i)=>(
					<Link className={classNames('item', {
						active: a.selectable!==false && isItemActive(a.url),
					})} replace={false} to={a.url} key={a.url} target={a.target || '_self'}>
						{!!a.icon && (
							<i className={`${a.icon} icon`}></i>
						)}
						{a.text}
					</Link>
				))}
			</div>
		)}
		<div className="contents">
			{sectionIndex===null || Content===null ? (
				<NotFound />
			) : <>
				{props.contentHeader}
				<Content params={content_params} {...props.routeParams} />
			</>}
		</div>
		{drawer && (
			<div className="overlay" onClick={toggleDrawer}></div>
		)}
	</div>
}

export default SidebarContainer;
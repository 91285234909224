import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react'

interface ToolbarItemProps extends PropsWithChildren{
	style?: React.CSSProperties;
	href?: string,
	text?: string,
	onClick?: ()=>void,
	icon?: string,
	className?: string,
	if?: boolean,
	target?: '_self' | '_blank' | '_parent' | '_top',
	items?: {
		text: string,
		className?: string,
		if?: boolean,
		onClick?: ()=>void,
		href?: string,
		target?: '_self' | '_blank' | '_parent' | '_top',
	}[]
}

const ToolbarItem : React.FC<ToolbarItemProps> = (props: ToolbarItemProps)=>{
	if(typeof props.if!=='undefined' && !props.if) return null;
	var contents = (props.children && !props.items) ? props.children : <>
		{!!props.icon && <i className={`${props.icon} icon`}></i>}
		{props.text}
	</>
	if(props.items){
		var items = props.items.filter(a=>!!a).map((a,i)=>(
			(typeof a.if==='undefined' || !!a.if) ? (
				<Dropdown.Item as={a.href ? Link : null} text={a.text} onClick={a.onClick} className={a.className} key={`drptl-${props.text}-${i}`} {...(a.href ? { to: a.href, target: (a.target || '_self') } : {})} />
			) : null
		))
		if(items.length==0) return null;
		return <Dropdown className={classNames("item", props.className)} trigger={<div>{contents}</div>}>
			<Dropdown.Menu>{items}</Dropdown.Menu>
		</Dropdown>
	}else{
		return props.href ? (
			<Link className={classNames('item', props.className)} to={props.href} target={props.target || '_self'}>{contents}</Link>
		) : (
			<div className={classNames('item', props.className)} onClick={props.onClick}>{contents}</div>
		)
	}
}

type ToolbarSubComponents = {
	Item: typeof ToolbarItem
}

interface ToolbarProps extends PropsWithChildren{
	divided?: boolean,
	items?: ToolbarItemProps[]
	style?: React.CSSProperties,
}

const Toolbar : React.FC<ToolbarProps> & ToolbarSubComponents = (props: ToolbarProps)=>{
	return <div style={props.style} className={classNames('fr toolbar', {
		divided: props.divided,
	})}>
		{props.items && props.items.length>0 && props.items.filter(a=>!!a).map((a, i)=>(
			<ToolbarItem {...a} key={`TLBRI-${i}`} />
		))}
		{props.children}
	</div>
}

Toolbar.Item = ToolbarItem;

export default Toolbar;
import { useParams } from "react-router-dom";
import { SidebarContainer } from '../components';
import PdvInfo from './PdvInfo';
import PdvCortes from './PdvCortes';
import Corte from "./Corte";

var Pdv = ()=>{
   var { pdv_id, corte_id } = useParams();

   return <div>
      <SidebarContainer
         header={'PDV: ' + pdv_id}
         routes={[
            { url: `/pdvs/:pdv_id`, render: ()=><PdvInfo	pdv_id={pdv_id} /> },
            { url: `/pdvs/:pdv_id/cortes`, render: ()=><PdvCortes	pdv_id={pdv_id} /> },
            { url: `/pdvs/:pdv_id/cortes/:corte_id`, render: () => <Corte corte_id={corte_id}/> },
         ]}
         items={[
            { text: 'Informacion', icon: 'info', url: `/pdvs/${pdv_id}` },
            { text: 'Cortes', icon: 'list', url: `/pdvs/${pdv_id}/cortes`, },
         ]}
      />
   </div>
}

export default Pdv;
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Message, Header, Groupper, Table, Field, UserLog } from '../components';
import { Modal, Icon, Dropdown, Checkbox, TextArea, ModalActions } from 'semantic-ui-react';
import { useParams } from "react-router-dom";
import { bindClick, bindSimpleFormChange } from '../Util';
import { Category as CategoryClass, Product, SetLoading, UserLoginData, UserAccess} from '../Classes';
import { useTitle } from '../Hooks';
import { WebProduct } from '@cocoan/components/Classes';
import MDEditor, { commands } from '@uiw/react-md-editor/nohighlight';
import Toast from 'react-hot-toast';
import classNames from 'classnames';
import Validator from '../Validator';
import moment from 'moment';
import API from '../API';

import '../style/categories.scss'

var Category = ()=>{
	var { category_id } = useParams();
	var { setTitle } = useTitle();
	var imageInputRef = useRef<HTMLInputElement>(null);
	var [category, setCategory] = useState<CategoryClass>(null);
	var [products, setProducts] = useState<WebProduct[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [productsModal, setProductsModal] = useState<boolean>(false);
	var [filter, setFilter] = useState<string>('');
	var [searching, setSearching] = useState<boolean>(false);
	var [searchTimeout, setSearchTimeout] = useState<any>(null);
	var [sendingProduct, setSendingProduct] = useState<number>(null);
	var [addingImage, setAddingImage] = useState<boolean>(false);
	var [viewingImage, setViewingImage] = useState<number>(null);
	var [deleteImageModal, setDeleteImageModal] = useState<boolean>(false);
	var [deleteModal, setDeleteModal] = useState<boolean>(false);
	var [userLogged, setUserLogged] = useState<UserLoginData>(null);

	useEffect(() => {
		setUserLogged(API.getLogin());
		get();
	}, []);
	

	var get = async()=>{
		API.getCategory(parseInt(category_id), true).then(res=>{
			if (res.error) return setLoadError(res.message);
			setTitle(res.data.category_name);
			return setCategory(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error al obtener la categoria. (GC-1)');
		});
	};

	var searchProducts = (query: string)=>{
		setSearching(true);
		setProducts(null);
		API.searchProducts(query).then(res=>{
			if (res.error) return setLoadError(res.message);
			setProducts(res.data);
			return setSearching(false);
		}).catch(err=>{
			setSearching(false);
			return setLoadError('Hubo un error al obtener la categoria. (GC-1)');
		})
	}

	var onSearchChange = (val: string)=>{
		setFilter(val);
		if(searchTimeout) clearTimeout(searchTimeout);
		if(val.length<3){
			return setProducts(null);
		}
		setSearchTimeout(setTimeout(()=>{
			searchProducts(val)
		}, 200));
	}

	var update = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(category, {
			category_name:[{
				rule: 'minLength', params: [3], label: 'Nombre'
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.updateCategory(category.category_id, category.category_name, category.description, category.online, category.pdv, category.collection, category.products).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Categoria actualizado!');
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado actualizando la categoria (C-UPD-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	};

	var addImage = ()=>{
		imageInputRef.current?.click();
	}

	var viewImage = (image: number)=>{
		return ()=>{
			setViewingImage(image)
		}
	}

	var deleteImage = (image: number)=>{
		return (setLoading: SetLoading)=>{
			setLoading(true);
			API.deleteCategoryImage(category.category_id).then(res=>{
				if(res.error) return Toast.error(res.message);
				var cr = {...category};
				cr.image = null;
				setCategory(cr);
				Toast.success('Se ha eliminado la imagen.');
				setDeleteImageModal(false);
				setViewingImage(null);
			}).catch(err=>{
				return Toast.error('Hubo un error inesperado eliminando la imagen. (LCL-1)');
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var handleImage = (ev: React.ChangeEvent<HTMLInputElement>)=>{
		if(!ev.target.files || ev.target.files.length==0) return;
		if(ev.target.files.length>1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		var image = ev.target.files[0];
		if(image.size>(2.5*1024*1024)) return Toast.error('La imagen no puede pesar mas de 2.5MB.');

		setAddingImage(true);
		API.addCategoryImage(parseInt(category_id), image).then(res=>{
			if(res.error) return Toast.error(res.message);

			var cr = {...category};
			cr.image = res.data.image_url;
			setCategory(cr);
			Toast.success('Se ha agregado la imagen de la categoria');
		}).catch(err=>{
			return Toast.error('Hubo un error subiendo la imagen de la categoria. (LCL-2)');
		}).finally(()=>{
			setAddingImage(false);
		});
	}

	var onEditChange = bindSimpleFormChange(category, setCategory);

	var openModal = async()=>{
		return setProductsModal(true)
	}

	var addProduct = (product_id: number, product_name: string)=>{
		return ()=>{
			setSendingProduct(product_id);
			API.addCategoryProduct(category.category_id, product_id).then(res=>{
				if (res.error) return Toast.error(res.message);
				var cat = { ...category };
				cat.products.push({
					product_id: product_id,
					name: product_name,
					available_online: null,
					available_pdv: null,
					deleted: 0,
					inventory: 1,
					only_local: null,
					price_online: null,
					price_pdv: null,
					priority: null,
					tax_ieps: null,
					product_cost: null,
				})
				Toast.success('Producto agregado!');
				cat.products = cat.products.sort((a,b)=>a.product_id-b.product_id);
				setCategory(cat);
			}).catch(err=>{
				return Toast.error('Hubo un error agregando el producto a la categoria. (LCL-1)');
			}).finally(()=>{
				setSendingProduct(null);
			})
		}
	}

	var removeProduct = (product_id: number)=>{
		return ()=>{
			setSendingProduct(product_id);
			API.removeCategoryProduct(category.category_id, product_id).then(res=>{
				if (res.error) return Toast.error(res.message);
				var cat = {...category};
				cat.products = cat.products.filter(p=>p.product_id!==product_id);
				Toast.success('Producto eliminado!');
				setCategory(cat)
			}).catch(err=>{
				return Toast.error('Hubo un error eliminando el producto de la categoria. (LCL-1)');
			}).finally(()=>{
				setSendingProduct(null);
			})
		}
	}

	var deleteCategory = (setLoading: (l: boolean)=>void)=>{
		setLoading(true);
		API.deleteCategory(parseInt(category_id), !category.deleted).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Categoria actualizada!');
			category.deleted = !category.deleted;
			if(category.deleted){
				category.date_deleted = moment().unix();
				category.user_deleter_name = userLogged.name_complete;
			}
			return setDeleteModal(false);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado actualizando la categoria. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!category){
		return <Header loading text='Cargando datos' />
	}

	return <div>
		<Groupper title={`Editar ${category.collection ? 'coleción' : 'categoría'}`} width={500} titleCentered actions={(
			<div>
				{!category.deleted && (userLogged.access === UserAccess.ADMIN || userLogged.access === UserAccess.SUPER_ADMIN) ? (
					<Button text="Eliminar" color='red' style={{ marginRight: 5 }} onClick={bindClick(setDeleteModal, true)}/>
				) : category.deleted ? (
					<Button text="Restaurar" color='green' style={{ marginRight: 5 }} onClick={bindClick(setDeleteModal, true)}/>
				) : null}
				<Button text="Guardar" color="black" onClick={ update }/>
			</div>
		)}>
			<input type="file" style={{ display: 'none' }} ref={imageInputRef} accept="image/*" onChange={handleImage} />
			<div className="co single product portrait images">
				{!!category.image ? (
					<img src={category.image} className="image" onClick={viewImage(-1)} />
				) : (
					<div className={classNames('image', {
						add: !addingImage,
						loading: addingImage,
					})} onClick={addImage}>
						{addingImage ? (
							<div className="fr loading inline"></div>
						) : (
							<Icon name="plus" />
						)}
					</div>
				)}
			</div>
			<div className="section head" style={{ marginTop: 10 }}>Detalles</div>
			<Input label='Nombre' onChange={onEditChange('category_name')} value={category.category_name}/>
			<div style={{padding: 5, fontWeight: 'bold' }}>Tipo</div>
			<Dropdown fluid selection
				placeholder='Seleccionar tipo'
				options={[
					{ text: 'Categoría', value: 0 }
				]}
				value={category.collection}
				style={{marginBottom: 10}}
				onChange={(event, data) => onEditChange('collection')(data.value)}
			/>
			<Field label='Descripción'>
				<MDEditor 
					preview='edit'
					style={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}
					value={category.description} 
					onChange={onEditChange('description')} 
					height={300}
					data-color-mode='light'
					extraCommands={[
						{
							...commands.codeEdit,
							icon: <Icon name='pencil' style={{ margin: 0, fontSize: 18 }} />
						},
						{
							...commands.codePreview,
							icon: <Icon name='eye' style={{ margin: 0, fontSize: 18 }} />
						},
					]}
					commands={[
						commands.bold,
						commands.italic,
						commands.strikethrough,
						commands.divider,
						commands.hr,
						commands.quote,
						commands.code,
						commands.divider,
						commands.unorderedListCommand,
						commands.orderedListCommand,
					]}
				/>		
			</Field>
			<Field label='Disponibilidad' style={{ marginTop: 15}}>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='En linea' checked={category.online} onChange={() => onEditChange('online')(!category.online)}/>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Punto de venta' checked={category.pdv} onChange={() => onEditChange('pdv')(!category.pdv)}/>
			</Field>
			<Table striped
				title='Productos'
				button={<Button size='tiny' text='Agregar' color='black' iconName='plus circle' onClick={openModal} />}
				centeredIndexes={[2]}
				headers={['ID', 'Nombre', <Icon name='wrench'/>]}
			>
				{category.products.length !== 0  ? (category.products.map(p => (
					<Table.Row
						key={p.product_id}
						collapsingIndexes={[0,2,3]}
						data={[
							p.product_id,
							p.name,
							sendingProduct!==p.product_id ? (
								<Button size='tiny' style={{ minWidth: 30 }} iconName='trash' onClick={removeProduct(p.product_id)}/>
							) : (
								<div className="fr loading inline" style={{ width: 30 }}></div>
							)
						]}
					/>
				))) : (
					<Table.Row>
						<Table.Cell className='empty' colSpan={5}>Esta categoria no cuenta con productos</Table.Cell>
					</Table.Row>
				)}
			</Table>
			<Message type="error" list={errorPrompts} />
		</Groupper>
		<UserLog user={category}/>
		<Modal open={productsModal} size='tiny' onClose={bindClick(setProductsModal)}>
			<Modal.Header>Agregar productos</Modal.Header>
			<Modal.Content>
				<Input label='Buscar producto' value={filter} onChange={onSearchChange} />
				{searching ? (
					<Header loading text='Cargando datos' />
				) : products && !searching ? (
					<Table fitted style={{ marginTop: 15 }} striped centeredIndexes={[2]} headers={[
						'ID', 'Producto', <Icon name='wrench'/> 
					]} >
						{products.length !== 0 ? (
							products.map(p => (
								<Table.Row
									key={`ADD-PRDS-${p.product_id}`}
									collapsingIndexes={[0,2,3]}
									data={[
										p.product_id,
										p.product_name,
										sendingProduct!==p.product_id ? (
											category.products.findIndex(a=>a.product_id===p.product_id)>-1 ? (
												<Button size='tiny' style={{ minWidth: 30 }} iconName='check' disabled />
											) : (
												<Button size='tiny' iconName='plus circle' style={{ minWidth: 30 }} color='green' onClick={addProduct(p.product_id, p.product_name)}/>
											)
										) : (
											<div className="fr loading inline" style={{ width: 30 }}></div>
										)
									]}
								/>
							))) : (
								<Table.Row>
									<Table.Cell className='empty' colSpan={5}>No se encontraron resultados</Table.Cell>
								</Table.Row>
							)}
					</Table>
				) : null}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setProductsModal)} color="black" />
			</Modal.Actions>
		</Modal>
		<Modal open={viewingImage!==null && !deleteImageModal} size='small' onClose={bindClick(setViewingImage)}>
			<Modal.Header>Imagen</Modal.Header>
			{viewingImage!==null && <Modal.Content>
				<img 
					src={category.image}
					style={{ width: '100%', maxWidth: 400, display: 'block', margin: 'auto', border: '2px solid #CCC', borderRadius: 12 }} 
				/>
			</Modal.Content>}
			<Modal.Actions>
				<Button onClick={bindClick(setDeleteImageModal, true)} color='red' text='Eliminar' />
				<Button onClick={bindClick(setViewingImage)} text='Cerrar' />
			</Modal.Actions>
		</Modal>
		<Modal open={viewingImage!==null && deleteImageModal} size='mini' onClose={bindClick(setDeleteImageModal)}>
			<Modal.Header>Eliminar imagen</Modal.Header>
			{viewingImage!==null && deleteImageModal && <Modal.Content>
				<div className="co product portrait single images" style={{ justifyContent: 'center' }}>
					<img src={category.image} className='image' />
				</div>
				<Header text='¿Eliminar imagen?' style={{ marginTop: 15, marginBottom: 0 }}/>
			</Modal.Content>}
			<ModalActions>
				<Button onClick={bindClick(setDeleteImageModal)} text='Cancelar' basic />
				<Button onClick={deleteImage(viewingImage)} color='red' text='Eliminar' />
			</ModalActions>
		</Modal>
		<Modal open={deleteModal} onClose={bindClick(setDeleteModal)} size='mini'>
			<Modal.Header>Confirmación</Modal.Header>
			<Modal.Content>
				<Header text={`¿ ${!category.deleted ? 'Eliminar' : 'Restaurar'} categoria?`} />
				<Table
					details
					titleSmall
					title='Detalles'
					style={{ marginTop: 20 }}
					data={ [['Nombre', category.category_name]] }
				/>
			</Modal.Content>
			<ModalActions>
				<Button onClick={bindClick(setDeleteModal)} text='Cancelar' basic/>
				<Button onClick={deleteCategory} color={category.deleted ? 'green' : 'red'} text={category.deleted ? 'Restaurar' : 'Eliminar'} />
			</ModalActions>
		</Modal>
	</div>
}
export default Category;
import React, { useEffect, useState } from 'react';
import { Groupper, Button, Input, Field, Message, Table } from 'react-frontier';
import { useTitle } from '../Hooks';
import { addCommas } from '../Util';
import API from '../API';
import moment from 'moment';
import Validator from '../Validator';
import { Icon } from 'semantic-ui-react';

interface DateSaleMethod{
	total: number,
	orders: number,
}

interface DateSales{
	date: string,
	total: number,
	orders: number,
	pdv: DateSaleMethod,
	online: DateSaleMethod,
	business: DateSaleMethod,
	personalized: DateSaleMethod,
	other: DateSaleMethod,
}

interface Payments{
	method_id: number,
	method_name_localized: string,
	total_method: number,
	total_method_count: number
}

var DatesReport = () =>{
	var { setTitle } = useTitle();
	var [startDate, setStartDate] = useState<number>(moment().subtract(7, 'd').unix());
	var [endDate, setEndDate] = useState<number>(moment().unix());
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [report, setReport] = useState<DateSales[]>(null);
	var [payments, setPayments] = useState<Payments[]>(null);
	
	useEffect(()=>{
		setTitle('Ventas por día');
	}, []);
	
	var downloadReport = ()=>{
		var { valid, prompts } = Validator({ startDate, endDate }, {
			startDate: [{ rule: 'number', label: 'Fecha inicio' }],
			endDate: [{ rule: 'number', label: 'Fecha fin' }],
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		
		var mstart = moment.unix(startDate).format('DD/MM/YYYY');
		var mend = moment.unix(endDate).format('DD/MM/YYYY');
		API.downloadDatesReport(mstart, mend).then(res=>{
			
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado descargando el reporte (LCL-1)']);
		});
	}
	
	var showReport = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ startDate, endDate }, {
			startDate: [{ rule: 'number', label: 'Fecha inicio' }],
			endDate: [{ rule: 'number', label: 'Fecha fin' }],
			
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		
		var mstart = moment.unix(startDate).format('DD/MM/YYYY');
		var mend = moment.unix(endDate).format('DD/MM/YYYY');

		setLoading(true);
		API.showDatesReport(mstart, mend).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			setPayments(res.data.payments);
			return setReport(res.data.dates);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado descargando el reporte (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	const DEFAULT_DATE_SALE_METHOD = { total: 0, orders: 0 }
	var report_totals : DateSales = {
		date: null,
		total: 0,
		orders: 0,
		pdv: { ...DEFAULT_DATE_SALE_METHOD },
		online: { ...DEFAULT_DATE_SALE_METHOD },
		business: { ...DEFAULT_DATE_SALE_METHOD },
		personalized: { ...DEFAULT_DATE_SALE_METHOD },
		other: { ...DEFAULT_DATE_SALE_METHOD },
	}
	if(report && report.length>0){
		for(var i of report){
			report_totals.orders += i.orders;
			report_totals.total += i.total;

			report_totals.pdv.orders += i.pdv.orders;
			report_totals.pdv.total += i.pdv.total;
			report_totals.online.orders += i.online.orders;
			report_totals.online.total += i.online.total;
			report_totals.business.orders += i.business.orders;
			report_totals.business.total += i.business.total;
			report_totals.personalized.orders += i.personalized.orders;
			report_totals.personalized.total += i.personalized.total;
			report_totals.other.orders += i.other.orders;
			report_totals.other.total += i.other.total;
		}
	}

	var payments_total = { payments: 0, total: 0 };
	if(payments && payments.length>0){
		for(var p of payments){
			payments_total.total += p.total_method;
			payments_total.payments += p.total_method_count;
		}
	}
	
	return <div>
		<Groupper title='Ventas por día' width={500} actions={<>
			<Button text='Descargar' color='green' style={{ marginRight: 10 }} iconName='file alternate outline' onClick={downloadReport}/>
			<Button text='Buscar' color='black' onClick={showReport}/>
		</>}>
			<Field amount={2}>
				<Input label='Fecha de inicio' value={startDate} onChange={setStartDate} calendar={{
					date: startDate,
					mode: 'date', 
					format: 'DD/MM/YYYY'
				}} />
				<Input label='Fecha de fin' value={endDate} onChange={setEndDate} calendar={{
					date: endDate,
					mode: 'date', 
					format: 'DD/MM/YYYY'
				}} />
			</Field>
			{!!report && report.length === 0 ? <Message type='error' text='No se econtraron registros relacionados.' /> : null }
			<Message list={errorPrompts} type={'error'} />
		</Groupper>
		{!!report && report.length !== 0 && <>
			<Table 
				divided
				striped
				collapsingIndexes={[0, 1, 3, 5, 7, 9, 11]}
				centeredIndexes={[1, 3, 5, 7, 9, 11, 12]}
				headers={['Fecha', 'PDV', 'Total PDV', 'Web', 'Total web', 'Business', 'Total Business', 'Personalizadas', 'Total Personalizadas', 'Otros', 'Total Otros', 'Ordenes', 'Total']}
				style={{ margin: '15px auto', maxWidth: 900, overflow: 'hidden' }}
				>
				{report.sort((a,b)=>new Date(a.date).getTime()-new Date(b.date).getTime()).map(d => (
					<Table.Row
						key={d.date}
						collapsingIndexes={[0, 1, 3, 5, 7, 9, 11]}
						centeredIndexes={[1, 3, 5, 7, 9, 11, 12]}
						data={[
							moment(d.date).format('DD/MMM/YYYY'),
							addCommas(d.pdv.orders, false),
							addCommas(d.pdv.total),
							addCommas(d.online.orders, false),
							addCommas(d.online.total),
							addCommas(d.business.orders, false),
							addCommas(d.business.total),
							addCommas(d.personalized.orders, false),
							addCommas(d.personalized.total),
							addCommas(d.other.orders, false),
							addCommas(d.other.total),
							addCommas(d.orders, false),
							addCommas(d.total),
						]}
					/>
				))}
				<Table.Row
					collapsingIndexes={[0, 1, 3, 5, 7, 9, 11]}
					centeredIndexes={[0, 1, 3, 5, 7, 9, 11, 12]}
					style={{ backgroundColor: '#EEE' }}
					data={[
						<Icon name='minus' />,
						addCommas(report_totals.pdv.orders, false),
						addCommas(report_totals.pdv.total),
						addCommas(report_totals.online.orders, false),
						addCommas(report_totals.online.total),
						addCommas(report_totals.business.orders, false),
						addCommas(report_totals.business.total),
						addCommas(report_totals.personalized.orders, false),
						addCommas(report_totals.personalized.total),
						addCommas(report_totals.other.orders, false),
						addCommas(report_totals.other.total),
						<b>
							{addCommas(report_totals.orders, false)}
						</b>,
						<b>
							{addCommas(report_totals.total)}
						</b>,
					]}
				/>
			</Table>
			<Table 
				divided
				striped
				title='Métodos de pago'
				titleCentered
				headers={['ID','Metodo','Pagos','Total']}
				centeredIndexes={[2]}
				style={{ margin: '15px auto', maxWidth: 500, overflow: 'hidden' }}
				>
				{payments.map(p => (
					<Table.Row
						key={p.method_id}
						collapsingIndexes={[0,2,3]}
						centeredIndexes={[2]}
						data={[
							p.method_id,
							p.method_name_localized,
							addCommas(p.total_method_count, false),
							`$${addCommas(p.total_method)}`
						]}
					/>
				))}
				<Table.Row 
					collapsingIndexes={[0,2,3]}
					centeredIndexes={[2]}
					style={{ backgroundColor: '#EEE' }}
					data={[
						'',
						'',
						<b>{addCommas(payments_total.payments, false)}</b>,
						<b>${addCommas(payments_total.total)}</b>,
					]}
				/>
			</Table>
			<Table
				title='Totales'
				details
				titleCentered
				striped
				divided
				style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}
			>
				<Table.Row collapsingIndexes={[0]} data={['Ordenes', addCommas(report_totals.orders, false)]} />
				<Table.Row collapsingIndexes={[0]} data={['Total ventas', `$${addCommas(report_totals.total)}`]} />
				<Table.Divider />
				<Table.Row collapsingIndexes={[0]} data={['Ordenes PDV', addCommas(report_totals.pdv.orders, false)]} />
				<Table.Row collapsingIndexes={[0]} data={['Total PDV', `$${addCommas(report_totals.pdv.total)}`]} />
				<Table.Divider />
				<Table.Row collapsingIndexes={[0]} data={['Ordenes Online', addCommas(report_totals.online.orders, false)]} />
				<Table.Row collapsingIndexes={[0]} data={['Total Online', `$${addCommas(report_totals.online.total)}`]} />
				<Table.Divider />
				<Table.Row collapsingIndexes={[0]} data={['Ordenes Business', addCommas(report_totals.business.orders, false)]} />
				<Table.Row collapsingIndexes={[0]} data={['Total Business', `$${addCommas(report_totals.business.total)}`]} />
				<Table.Divider />
				<Table.Row collapsingIndexes={[0]} data={['Ordenes Personalizadas', addCommas(report_totals.personalized.orders, false)]} />
				<Table.Row collapsingIndexes={[0]} data={['Total Personalizadas', `$${addCommas(report_totals.personalized.total)}`]} />
				<Table.Divider />
				<Table.Row collapsingIndexes={[0]} data={['Ordenes Otros', addCommas(report_totals.other.orders, false)]} />
				<Table.Row collapsingIndexes={[0]} data={['Total Otros', `$${addCommas(report_totals.other.total)}`]} />
			</Table>
		</>}
	</div>
}

export default DatesReport;
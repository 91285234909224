import React, { useEffect, useState } from 'react';
import { Groupper } from 'react-frontier';
import { Link } from 'react-router-dom';

const REPORT_ITEMS = [
	{ text: 'Ventas por día', href: 'reports/dates' },
	{ text: 'Productos por día', href: 'reports/products' },
	{ text: 'Reporte de Productos', href: 'reports/sales' },
	{ text: 'Reporte de Facturas', href: 'reports/invoices' },
	{ text: 'Reporte de Ordenes', href: 'reports/orders' }
]

var ReportsDashboard = ()=>{
	return <Groupper width={400} title={'Reportes'}>
		<div className="fr selectable items" style={{ marginBottom: 10 }}>
			{REPORT_ITEMS.map((a, i)=>(
				<Link className="item" key={`RPT-ITM-${i}`} to={a.href}>
					{a.text}
				</Link>
			))}
		</div>
	</Groupper>
}

export default ReportsDashboard;
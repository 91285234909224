import React, { useEffect, useState } from 'react';

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  hideOnFail?: boolean;
  fallback?: string;
  nameLabel?: string;
  priceLabel?: string;
}

const Image = (props: ImageProps) => {
  const [error, setError] = useState<boolean>(false);
  const [fallbackError, setFallbackError] = useState<boolean>(false);

  const handleError = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (props.fallback) {
      if (error) {
        ev.currentTarget.src = null;
        setFallbackError(true);
      } else {
        ev.currentTarget.src = props.fallback;
      }
    }
    setError(true);
  };

  useEffect(() => {
    setError(false);
  }, [props.src]);

  if (props.hideOnFail && error) return null;

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div style={{ position: 'relative' }}>
        <img onClick={props.onClick} onError={handleError} src={props.src} style={props.style} alt={props.alt}/>
		{props.nameLabel && (
			<div style={{
				position: 'absolute',
				bottom: '15px',
				left: '50%',
				transform: 'translateX(-50%)',
				width: '90%',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				color: 'white',
				margin: '0',
				borderRadius: '4px',
				textAlign: 'left',
				paddingLeft: '10px'
			}}>
				{props.nameLabel}
			</div>
			)}
        {props.priceLabel && (
          <div style={{
            position: 'absolute',
            top: 5,
            left: 5,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '5px',
            borderRadius: '5px',
          }}>
            {props.priceLabel}
          </div>
        )}
      </div>
    </div>
  );
};

export default Image;
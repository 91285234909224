import React, { useEffect, useState } from 'react';
import { Header, Button, Message, Items, Input } from 'react-frontier';
import { Icon, Modal, ModalActions, Dropdown, SemanticICONS } from 'semantic-ui-react';
import { Table } from '../components';
import { SalesChannel, ShippingMethod, User } from '../Classes';
import { useTitle } from '../Hooks';
import { bindClick, addCommas, orderMethodLocale, cropString } from '../Util';
import { Link } from 'react-router-dom';
import { firstBy } from 'thenby';
import { normalizeSync } from 'normalize-diacritics';
import Toast from 'react-hot-toast';
import API from '../API';
import moment from 'moment';
import Validator from '../Validator';
import classNames from 'classnames';

interface AssignOrder{
	order_id: number,
	owner_name:  string
	owner_email: string,
	order_method: SalesChannel,
	shipping_method: ShippingMethod,
	prepared: boolean,
	user_assigned: number,
	user_assigned_name: string,
	user_assigned_alias: string,
	total: number,
	shipping_date: number,
	date_prepared: number,
	date_assigned: number,
	date_created: number,
}

var getOrderColor = (order: AssignOrder)=>{
	if(order.shipping_method===ShippingMethod.SHIPPING) return '#3193aa';
	else if(order.shipping_method===ShippingMethod.PICKUP || order.shipping_method===ShippingMethod.PICKUP_HOUR) return '#d0800a';
	return 'black';
}

var AssignOrders = () =>{
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [selectedOrders, setSelectedOrders] = useState<number[]>([]);
	var [usersModal, setUsersModal] = useState<boolean>(false);
	var [users, setUsers] = useState<User[]>(null);
	var [selectedUser, setSelectedUser] = useState<number>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [selectedOrder, setSelectedOrder] = useState<AssignOrder>(null);
	var [unassignModal, setUnassignModal] = useState<boolean>(false);
	var [orders, setOrders] = useState<AssignOrder[]>(null);
	var [userSearch, setUserSearch] = useState<string>('');
	var [usersLoading, setUsersLoading] = useState<boolean>(false);

	useEffect(()=>{
		get();
		setTitle('Asignar Ordenes');
	}, []);


	var get = () => {
		API.getPendingOrders().then(res=>{
			if(res.error) return setLoadError(res.message);
			setOrders(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error al obtener las ordenes. (GAO-1)');
		});
	};

	var openAssignModal = async() =>{
		if(!users){
			setUsersLoading(true);
			try{
				var res = await API.getActiveUsers();
				if(res.error) return alert(res.message);
				setUsers(res.data);
				setUsersLoading(false);
			}catch(e){
				alert('Hubo un error cargando los usuarios');
			}
		}
		setSelectedUser(null);
		setUsersModal(true);
	}

	var addOrder = (order_id: number)=>{
		return ()=>{
			var ords = [...selectedOrders];
			var ix = ords.indexOf(order_id);
			if(ix==-1){
				ords.push(order_id);
			}else{
				ords = ords.filter(a=>a!==order_id);
			}
			setSelectedOrders(ords);
		}
	};

	var assign = async (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ user_id: selectedUser, orders: selectedOrders }, {
			user_id: [{
				rule: 'empty', label: 'Usuario'
			}],
			orders: [{
				rule: 'minLength', params: [1], prompt: 'Favor de seleccionar 1 o mas ordenes para asignar'
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		
		API.assingOrders(selectedUser, selectedOrders).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			var assigned_user = users.find(u => u.user_id === selectedUser);
			var ords = [...orders];
			for(var i of selectedOrders){
				var oix = ords.findIndex(a=>a.order_id==i);
				if(oix==-1) continue;
				ords[oix].user_assigned = assigned_user.user_id;
				ords[oix].user_assigned_name = `${assigned_user.first_name} ${assigned_user.last_name}`;
				ords[oix].user_assigned_alias = assigned_user.alias;
				ords[oix].date_assigned = moment().unix();
			}
			setOrders(ords);
			setUsersModal(false);
			setSelectedOrders([])
			Toast.success('Ordenes asignadas!')
		}).catch(err=>{
			setErrorPrompts(['Hubo un error al asignar las ordenes. (AOE-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var unassign = async (setLoading: (l: boolean)=>void)=>{
		setLoading(true);

		API.unassingOrder(selectedOrder.order_id).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);

			var ords = [...orders];
			var ix = ords.findIndex(a=>a.order_id==selectedOrder.order_id);
			if(ix>-1){
				ords[ix].user_assigned = null;
				ords[ix].user_assigned_name = null;
				ords[ix].user_assigned_alias = null;
				ords[ix].date_assigned = null;
			}

			setUnassignModal(false);
			setSelectedOrder(null);
			Toast.success('Asignación removida!')
		}).catch(err=>{
			Toast.error('Hubo un error al remover la asignación, (RAU-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var selectOrder = (order: AssignOrder)=>{
		setSelectedOrder(order);
		setUnassignModal(true);
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!orders){
		return <Header loading text='Cargando datos' />
	}

	var ShippingIcon = (props: { order: AssignOrder })=> <>
		<i style={{ marginRight: 10, color: getOrderColor(props.order) }} className={classNames("icon", {
			'shopping basket': props.order.order_method!=SalesChannel.PDV && props.order.shipping_method!==1,
			'truck': props.order.order_method!=SalesChannel.PDV && props.order.shipping_method===1,
		})} />
		<span style={{ color: getOrderColor(props.order) }}>
			{props.order.order_method==1 ? 'PDV' : (props.order.shipping_method==1 ? 'Envío' : 'Pickup')}
		</span>
	</>

	var unassignedOrders = orders.filter(a=>!a.user_assigned).sort(firstBy((a, b)=>{
		return (a.shipping_date || 99999999999)-(b.shipping_date || 99999999999);
	}, 'asc'));
	var assignedOrders = orders.filter(a=>!!a.user_assigned);

	return <div>
		<Table
			title='Ordenes sin asignar'
			style={{ maxWidth: 900, margin: 'auto' }}
			headers={['', 'Orden', 'Tipo', 'Método', 'Cliente', 'Creación', 'Envío', 'Total']}
			emptyText={unassignedOrders.length === 0 && 'No hay ordenes pendientes.'}
			button={(
				<Button text='Asignar' size='small' color='black' onClick={openAssignModal} disabled={selectedOrders.length === 0}/>
			)}
		>
			{unassignedOrders.map(o=>{
				var selected = selectedOrders.indexOf(o.order_id)>-1;
				return (
					<Table.Row
						key={`ORDAS-${o.order_id}`}
						selectable
						onClick={addOrder(o.order_id)}
						collapsingIndexes={[0, 1, 2, 3, 5, 6, 7]}
						data={[
							<i style={{ fontSize: 20, margin: 0 }} className={classNames('icon circle', {
								'check': selected,
								outline: !selected,
							})}></i>,
							o.order_id,
							<ShippingIcon order={o} />,
							orderMethodLocale(o.order_method),
							cropString(o.owner_name, 24, true),
							moment.unix(o.date_created).format('DD/MMM/YY HH:mm'),
							o.shipping_date ? (
								moment.unix(o.shipping_date).format('DD/MMM/YY')
							) : (
								<Icon name='minus' color='grey' />
							),
							addCommas(o.total)
						]}
					/>
				)
			})}
		</Table>
		<Table
			title='Ordenes asignadas'
			style={{ maxWidth: 900, margin: 'auto', marginTop: 15 }}
			emptyText={assignedOrders.length === 0 && 'No hay ordenes asignadas pendientes.'}
			headers={['Orden', 'Usuario', 'Tipo', 'Metodo', 'Cliente', 'Creación', 'Envío', 'Total', '']}
		>
			{assignedOrders.sort(firstBy('user_assigned', 'asc').thenBy('shipping_date', 'asc')).map(o=>(
				<Table.Row
					key={o.order_id}
					collapsingIndexes={[0, 1, 2, 3, 5, 6, 7, 8]}
					data={[
						o.order_id,
						o.user_assigned_alias.toUpperCase(),
						<ShippingIcon order={o} />,
						orderMethodLocale(o.order_method),
						cropString(o.owner_name, 24, true),
						moment.unix(o.date_created).format('DD/MMM/YY HH:mm'),
						moment.unix(o.shipping_date).format('DD/MMM/YY'),
						addCommas(o.total),
						<Dropdown icon={(
							<div className="fr label" style={{ padding: '3px 10px' }}>
								<Icon name='ellipsis horizontal' size='small' style={{ margin: 0 }} />
							</div>
						)} className='icon'>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => selectOrder(o)} icon={'user'}>
									<Icon name='user' style={{ marginRight: 3, marginLeft: -1 }} /> Remover asignación
								</Dropdown.Item>
								<Dropdown.Item as={Link} to={`/orders/${o.order_id}`} target='_blank'>
									<Icon name="eye" style={{ marginRight: 3 }} /> Ver orden 
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					]}
				/>
			))}
		</Table>
		<Modal open={usersModal} size='mini' onClose={bindClick(setUsersModal)}>
			<Modal.Header>Asignar a usuario</Modal.Header>
			<Modal.Content>
				{usersLoading ? <Header loading text='Cargando usuarios' /> :!usersLoading && !!users ? <>
					<Input placeholder='Buscar usuarios' style={{ marginTop: -10, marginBottom: 10 }} onChange={setUserSearch} value={userSearch} />
					<Items
						toggle
						single
						selectable
						data={users.filter(a=>{
							return normalizeSync(`${a.first_name} ${a.last_name}`).toUpperCase().includes(normalizeSync(userSearch).toUpperCase());
						}).sort(firstBy('first_name', 'asc'))}
						onSelected={a=>setSelectedUser(a.user_id)}
						selected={selectedUser}
						valueExtractor={a=>a.user_id}
						render={u=><>
							<div className="left">
								{u.first_name} {u.last_name}
							</div>
							<div className="right" style={{ fontSize: 12, color: 'gray' }}>
								{u.alias.toUpperCase()}
							</div>
						</>}
					/>
				</> : (
					<Header loading text='Cargando usuarios' />
				)}
				<Message type="error" list={errorPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' onClick={bindClick(setUsersModal)} basic />
				{<Button text='Asginar' color='black' onClick={assign}/>}
			</Modal.Actions>
		</Modal>
		<Modal open={unassignModal} onClose={bindClick(setUnassignModal)} size='mini'>
			<Modal.Header>Confirmación</Modal.Header>
			{selectedOrder && <Modal.Content>
				<Header text={'Remover asignación de '+ selectedOrder.order_id} />
				<Table
					details
					titleSmall
					title='Detalles'
					style={{ marginTop: 20 }}
					data={[
						['Orden', selectedOrder.order_id], 
						['Usuario actual', selectedOrder.user_assigned_name],
						['Fecha asignación', moment.unix(selectedOrder.date_assigned).format('DD/MMM/YY HH:mm')]
					]}
				/>
			</Modal.Content>}
			<ModalActions>
				<Button onClick={bindClick(setUnassignModal)} text='Cancelar' basic/>
				<Button onClick={unassign} text='Desasignar' color='black'/>
			</ModalActions>
		</Modal>
	</div>
}

export default AssignOrders; 	
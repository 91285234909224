import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

var TitleContext = createContext<{ setTitle: (title: string)=>void }>({ setTitle: (v: string)=>null });
var useTitle = () => useContext(TitleContext);

interface TitleProps extends PropsWithChildren{
	default?: string,
	suffix?: string,
}

var TitleProvider = (props: TitleProps) => {
	var [titleHistory, setTitleHistory] = useState<{ pathname: string, title: string }[]>([]);
	var location = useLocation();

	useEffect(()=>{
		var suffix = props.suffix || '';
		var title = titleHistory.find(a=>a.pathname===location.pathname);
		if(!title){
			document.title = suffix;
		}else{
			document.title = `${title.title} - ${suffix}`;
		}
	}, [location, titleHistory]);

	var changeTitle = (title: string)=>{
		if(!location.key) return;
		var hs = [...titleHistory];
		hs = hs.filter(a=>a.pathname!==location.pathname);
		hs.push({ pathname: location.pathname, title });
		setTitleHistory(hs);
	}

	return (
		<TitleContext.Provider value={{ setTitle: changeTitle }}>
			{props.children}
		</TitleContext.Provider>
	)
}

export{
	TitleProvider,
	useTitle,
}
import React, { useEffect, useState } from 'react';
import { useTitle } from '../Hooks';
import { ReportOrder, SalesChannel, ShippingType } from '../Classes';
import { Groupper, Button, Input, Field, Message } from '../components';
import { addCommas, bindChange, bindFormChange } from '../Util';
import { Table } from 'react-frontier';
import { Dropdown, Checkbox, Icon, SemanticICONS } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Validator from '../Validator';
import API from '../API';
import moment from 'moment';
import classNames from 'classnames';

export interface OrderStatus{
	paid: boolean,
	ready: boolean,
	shipped: boolean
}

var OrdersReport = () =>{
	var { setTitle } = useTitle();
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [startDate, setStartDate] = useState<number>(moment().unix());
	var [endDate, setEndDate] = useState<number>(moment().add(7, 'd').unix());
	var [channels, setChannels] = useState<number[]>([SalesChannel.ONLINE]);
	var [orderStatus, setOrderStatus] = useState<OrderStatus>({paid: false, ready: false, shipped: false });
	var [orders, setOrders] = useState<ReportOrder[]>(null);
	
	useEffect(()=>{
		setTitle('Reporte de ordenes');
	}, []);
	
	var showReport = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ startDate, endDate, channels }, {
			startDate: [{ rule: 'number', label: 'Fecha inicio' }],
			endDate: [{ rule: 'number', label: 'Fecha fin' }],
			channels:  [{ rule: 'minLength', params: [1], label: 'Opciones de datos'}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		
		var mstart = moment.unix(startDate).format('DD/MM/YYYY');
		var mend = moment.unix(endDate).format('DD/MM/YYYY');
		
		setLoading(true);
		API.showOrdersReport(mstart, mend, channels, orderStatus).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			return setOrders(res.data);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado descargando el reporte (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}
	
	var onEditStatus = bindFormChange(orderStatus, setOrderStatus);
	
	if(orders){
		var productsSummary = orders
		.filter(order => !order.shipped)
		.reduce((acc, order) => {
			order.products.forEach(product => {
				var existingProduct = acc.find(p => p.name === product.product_name);
				if (existingProduct) {
					existingProduct.quantity += product.quantity;
				} else {
					acc.push({
						name: product.product_name,
						quantity: product.quantity,
						cost_single: product.cost_single,
						product_id: product.product_id 
					});
				}
			});
			return acc;
		}, []);
		
		var productsDateSummary = orders
		.filter(order => !order.shipped)
		.reduce((acc, order) => {
			order.products.forEach(product => {
				acc.push({ 
					name: product.product_name, 
					quantity: product.quantity, 
					cost_single: product.cost_single,
					shipping_date: order.shipping_date,
					product_id : product.product_id
				});
			});
			return acc;
		}, []);
		productsDateSummary = productsDateSummary.sort((a, b) => b.shipping_date - a.shipping_date);
	}
	
	return <div>
		<Groupper title='Reporte de Ordenes' width={500} actions={(
			<Button text='Buscar' color='black' onClick={showReport}/>
		)}>
			<Field amount={2}>
				<Input label='Fecha de inicio' value={startDate} onChange={setStartDate} calendar calendarProps={{ mode: 'date', format: 'DD/MM/YYYY',}}/>
				<Input label='Fecha de fin' value={endDate} onChange={setEndDate} calendar calendarProps={{ mode: 'date', format: 'DD/MM/YYYY',}}/>
			</Field>
			<Field label='Tipo'>
				<Dropdown 
					selection 
					value={channels}
					multiple
					placeholder='Opciones de datos' options={[
						// { value: SalesChannel.PDV, text: 'PDV' },
						{ value: SalesChannel.ONLINE, text: 'Online' },
						{ value: SalesChannel.PERSONALIZED, text: 'Personalizados' },
						{ value: SalesChannel.B2B, text: 'Empresariales' },
						{ value: SalesChannel.CALLCENTER, text: 'Call Center' },
					]} 
					onChange={bindChange(setChannels, true)}
				/>
			</Field>
			<Field label='Disponibilidad'>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Pagada' checked={orderStatus.paid} onChange={() => onEditStatus('paid')(!orderStatus.paid)}/>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Ready' checked={orderStatus.ready} onChange={() => onEditStatus('ready')(!orderStatus.ready)}/>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Entregada' checked={orderStatus.shipped} onChange={() => onEditStatus('shipped')(!orderStatus.shipped)}/>
			</Field>
			{!!orders && orders.length === 0 && (
				<Message type='error' text='No se econtraron registros relacionados.' /> 
			)}
			<Message list={errorPrompts} type={'error'} />
		</Groupper>
		{!!orders && orders.length > 0 && <>
			{orders.map(o =>{
				var product_count = o.products.reduce((a,b)=>a+b.quantity, 0);
				return <>
					<Table
						key={o.order_id}
						striped
						details
						title={`Orden ${o.order_id}`}
						style={{ maxWidth: 500, margin: 'auto', marginTop: 15, borderBottomLeftRadius: 0 , borderBottomRightRadius: 0 }}
						data={[
							['Orden', (
								<Link className='fr small label' target={'_blank'} to={`/orders/${o.order_id}`} style={{ display: 'inline', padding: '5px 20px' }}>{o.order_id}</Link>
							)],
							['Cliente', o.owner_name ? `${o.owner_name} ${(o.owner_last_name || '')}` : 'Sin Datos'],
							[o.shipping_type === ShippingType.PICKUP ? 'Pickup' : 'Envío', o.shipping_date ? moment.unix(o.shipping_date).format('DD/MMM/YY') : 'Sin Fecha'],
							['Entrega', <>
								<div className={classNames("fr small label", {
									blue: o.shipping_type===ShippingType.SHIPPING,
									green: o.shipping_type===ShippingType.PICKUP,
								})} style={{ padding: '1px 10px' }}>
									<Icon name={classNames({
										truck: o.shipping_type===ShippingType.SHIPPING,
										'shopping basket': o.shipping_type===ShippingType.PICKUP,
									}) as SemanticICONS} />
									{o.shipping_type_name}
								</div>
							</>],
							// ...(o.shipping_cost && o.shipping_cost !== 0 ? [['Costo de envío', '$' + addCommas(o.shipping_cost)]] : []),
							// ['Total', '$'+ addCommas(o.total)],
						]}
					/>
					<Table
						style={{ maxWidth: 500, margin: 'auto', borderBottomLeftRadius: 0 , borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
						headers={['Regalo', 'Pagado', 'Ready', 'Entregado', 'Productos']}
						centeredIndexes={[0, 1, 2, 3, 4]}
						collapsingIndexes={[3, 4]}
						celled
					>
						<Table.Row>
							<Table.Cell centered><Icon name={o.gift_message ? 'check' : 'close'}/></Table.Cell>
							<Table.Cell centered><Icon name={o.paid ? 'check' : 'close'}/></Table.Cell>
							<Table.Cell centered><Icon name={o.ready ? 'check' : 'close'}/></Table.Cell>
							<Table.Cell centered><Icon name={o.shipped ? 'check' : 'close'}/></Table.Cell>
							<Table.Cell centered>{addCommas(product_count, false)}</Table.Cell>
						</Table.Row>
					</Table>
					<Table
						title='ARTÍCULOS'
						style={{ maxWidth: 500, margin: 'auto', borderTopLeftRadius: 0, borderTopRightRadius: 0 , marginBottom: 20 }}
						headers={['ID', 'Nombre', 'Cantidad']}
						collapsingIndexes={[0, 2, 3, 4]}
						striped
					>
						{o.products.map(p => (
							<Table.Row
								compact
								selectable
								as={Link} 
								target={'_blank'}
								to={`/products/${p.product_id}`}
							>
								<Table.Cell>{p.product_id}</Table.Cell>
								<Table.Cell>{p.product_name}</Table.Cell>
								<Table.Cell centered>{p.quantity}</Table.Cell>
								{/* <Table.Cell>{'$' + addCommas(p.cost_single)}</Table.Cell>
								<Table.Cell>{'$' + addCommas(p.cost_single*p.quantity)}</Table.Cell> */}
							</Table.Row>
						))}
						{/* <Table.Row>
							<Table.Cell style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}>Total</Table.Cell>
							<Table.Cell style={{ fontWeight: 'bold' }} colSpan={1} centered>
								{'$' + addCommas(o.products.reduce((acc, p) => acc + (p.cost_single * p.quantity), 0))}
							</Table.Cell>
						</Table.Row> */}
					</Table>
				</>
			})}
		</>}
		{!!orders && orders.length !== 0 && (
			<Table
				title='Resumen'
				details
				striped
				style={{ maxWidth: 500, margin: 'auto', marginTop: 15 , marginBottom: 15 }}
				data={[
					['Ordenes', orders.length],
					['Productos totales', orders.reduce((acc, order) => { return acc + order.products.reduce((subAcc, product) => subAcc + product.quantity, 0); }, 0)],
					['Productos no enviados', orders.reduce((acc, order) => !order.shipped ? acc + order.products.reduce((subAcc, product) => subAcc + product.quantity, 0) : acc, 0)],
					['Productos enviados', orders.reduce((acc, order) => order.shipped ? acc + order.products.reduce((subAcc, product) => subAcc + product.quantity, 0) : acc, 0)],
					['Ordenes enviadas', orders.reduce((count, order) => count + (order.shipped ? 1 : 0), 0)],
					['Ordenes no enviadas', orders.reduce((count, order) => count + (!order.shipped ? 1 : 0), 0)],
					// ['Total','$' + addCommas(orders.reduce((acc, order) => acc + order.total, 0))],
				]}
			/>
		)}
		{productsSummary && productsSummary.length !== 0 && (
			<Table
				title='Productos pendientes'
				style={{ maxWidth: 700, margin: 'auto', marginTop: 15 }}
				striped
				headers={['ID', 'Nombre', 'Cantidad']}
				collapsingIndexes={[0, 2, 3, 4]}
				centeredIndexes={[0]}
				selectable
			>
				{productsSummary.map(p => (
					<Table.Row
						compact
						as={Link} 
						target={'_blank'}
						to={`/products/${p.product_id}`}
					>
						<Table.Cell>{p.product_id}</Table.Cell>
						<Table.Cell>{p.name}</Table.Cell>
						<Table.Cell centered>{p.quantity}</Table.Cell>
						{/* <Table.Cell>{'$' + addCommas(p.cost_single)}</Table.Cell>
						<Table.Cell>{'$' + addCommas(p.cost_single*p.quantity)}</Table.Cell> */}
					</Table.Row>
				))}
				<Table.Row>
					<Table.Cell style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}>Total</Table.Cell>
					<Table.Cell style={{ fontWeight: 'bold' }} centered>
						{addCommas(productsSummary.reduce((a,b)=>a+b.quantity, 0), false)}
					</Table.Cell>
				</Table.Row>
			</Table>
		)}
		{productsDateSummary && productsDateSummary.length !== 0 && (
			<Table
				title='Productos pendientes por fecha'
				style={{ maxWidth: 700, margin: 'auto', marginTop: 15, marginBottom: 50 }}
				striped
				headers={['ID','Fecha','Nombre', 'Cantidad']}
			>
				{productsDateSummary.map(p => (
					<Table.Row
						compact
						selectable
						as={Link} 
						target={'_blank'}
						centeredIndexes={[3]}
						collapsingIndexes={[0, 1, 3]}
						to={`/products/${p.product_id}`}
						data={[
							p.product_id,
							p.shipping_date ? moment.unix(p.shipping_date).format('DD/MMM/YY') : 'Sin Fecha',
							p.name,
							p.quantity
						]}
					/>
				))}
				<Table.Row>
					<Table.Cell style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={3}>Total</Table.Cell>
					<Table.Cell style={{ fontWeight: 'bold' }} centered>
						{addCommas(productsDateSummary.reduce((a,b)=>a+b.quantity, 0), false)}
					</Table.Cell>
				</Table.Row>
			</Table>
		)}
	</div>
}
			
export default OrdersReport;
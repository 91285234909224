import React, { useEffect, useState } from 'react';
import { Button, Groupper, Input, Message } from 'react-frontier';
import Validator from '../Validator';
import API from '../API';

import '../style/login.scss';

interface ScreenParams{
	onLogin?: ()=>void;
}

var Login = (props: ScreenParams)=>{
	var [username, setUsername] = useState('');
	var [password, setPassword] = useState('');
	var [loading, setLoading] = useState<boolean>(false);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);

	var sendLogin = ()=>{
		var { valid, prompts } = Validator({ username, password }, {
			username: [{
				rule: 'empty', label: 'Correo electrónico/Alias'
			}],
			password: [{
				rule: 'minLength', params: [5], label: 'Contraseña'
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.login(username, password).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			props.onLogin();
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado haciendo login (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	useEffect(()=>{
		var login = API.getLogin();
		if(login && props.onLogin) return props.onLogin();
	}, []);

	return <div className="co login-screen">
		<Groupper actions={(
			<Button text="Iniciar sesión" color="black" loading={loading} onClick={sendLogin}/>
		)}>
			<div className="head">
				<img src={`${API.CDN_URL}/static/logo/Logo_Black_Small.png`} />
			</div>
			<Input label='Usuario' value={username} onChange={setUsername} />
			<Input label='Contraseña' value={password} onChange={setPassword} inputType="password" submitOnEnter onSubmit={sendLogin} />
			<Message type="error" list={errorPrompts} />
		</Groupper>
	</div>
}

export default Login;
import React, { PropsWithChildren, CSSProperties } from 'react';
import classnames from 'classnames';

interface ComponentProps extends PropsWithChildren{
	text?: string,
	list?: string[],
	header?: string,
	centered?: boolean,
	type?: 'error' | 'success' | 'warning' | 'info' | 'orange',
	style?: CSSProperties
}
var Button = (props: ComponentProps)=>{
	if(typeof props.list!=='undefined' && (props.list===null || props.list.length==0) && props.type=='error'){
		return null;
	}
	return <div className={classnames('fr message', props.type)} style={{ ...(props.centered ? { textAlign: 'center' } : {}), ...(props.style || {})}}>
		{props.header ? (
			<div className="header" style={{ marginBottom: (props.text || props.list || props.children) ? 5 : 0 }}>{props.header}</div>
		) : null}
		{props.text}
		{props.list ? (
			props.list.length==1 && !props.text ? (
				props.list[0]
			) : props.list.length>0 ? (
				<ul className="list">
					{props.list.map((a, i)=>(
						<li key={`msg-itm-${i}`}>{a}</li>
					))}
				</ul>
			) : null
		) : null}
		{props.children}
	</div>
}

export default Button;
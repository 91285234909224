import React, { useEffect, useState } from 'react';
import { Image } from 'react-frontier'
import { WebCategory } from '../Classes';
import { randomRange } from '../Util';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './style/category_card.scss';

interface CategoryProps{
	category?: WebCategory,
	placeholder?: boolean,
	placeholderActive?: boolean,
	placeholderImage?: string,
	color?: string,
	image?: string,
	href?: string,
	onClick?: (category_id: number)=>void,
}
var CategoryCard = (props: CategoryProps)=>{
	var [randomPlaceholder, setRandomPlaceholder] = useState<number>(randomRange(40, 87));
	if(props.placeholder){
		return <div className={classNames('co category card placeholder', { active: props.placeholderActive })}>
			<div className="image"></div>
			<div className="content">
				<div className="placeholder" style={{ width: `${randomPlaceholder}%` }}></div>
				<div className="placeholder" style={{ width: `${randomPlaceholder-20}%` }}></div>
			</div>
		</div>
	}

	const Element = props.href ? Link : 'div'

	return <Element 
		className="co category card"
		to={props.href}
		onClick={()=>{
			if(props.onClick) props.onClick(props.category.category_id);
		}}
	>
		<div className="image">
			<Image src={props.image} fallback={props.placeholderImage || '/placeholder_portrait.webp'} />
			<div className="overlay" style={{ backgroundColor: '#151717' }}></div>
		</div>
		<div className="content">
			<div className="name">
				{props.category.category_name}
			</div>
		</div>
	</Element>
}
export default CategoryCard;
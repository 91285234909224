import React, { useEffect, useState } from 'react';
import SearchCoreModal, { SearchCoreProps } from './SearchCoreModal';
import { newProduct } from '../Classes';
import API from '../API';
import { Icon } from 'semantic-ui-react';
import { Image } from 'react-frontier';
import { addCommas } from '../Util';

var SearchProductsModal = (props: SearchCoreProps<newProduct>)=>{
	return <SearchCoreModal
		title='Buscar Producto'
		{...props}
		searchData={(query, page, page_size)=>{
			return API.searchProducts(query, page * page_size, page_size);
		}}
		pageSize={5}
		table={[
			{ name: <Icon name="image" />, key: 'product_id_img', collapsing: true,  render: (id)=>{
				// return <img src={`${API.CDN_URL}/products/${id}/0.webp`} style={{ width: 50, height: 50*1.25, borderRadius: 8, objectFit: 'cover' }} />
				return <Image src={`${API.CDN_URL}/products/${id}/0.webp`} style={{ width: 50, height: 50*1.25, borderRadius: 8, objectFit: 'cover' }} fallback={API.PLACEHOLDER_IMG} />
			}},
			{ name: 'ID', key: 'product_id', collapsing: true },
			{ name: 'Nombre', key: 'product_name' },
			{ name: 'Precio', key: 'price', collapsing: true, render: addCommas },
		]}
	/>
}

export default SearchProductsModal;
import React, { useEffect, useState } from 'react';
import { Groupper, Table, Header } from 'react-frontier';
import { Corte as CorteClass } from '../Classes';
import { addCommas } from '../Util';
import { Link } from 'react-router-dom';
import { useTitle } from '../Hooks';
import API from '../API';
import moment from 'moment';

import '../style/corte_view.scss';

var Corte = (props: any)=>{
	var { corte_id } = props;
	var [loadError, setLoadError] = useState<string>(null);
	var { setTitle } = useTitle();
	var [corte, setCorte] = useState<CorteClass>(null);
	
	useEffect(() => {
		get();
		setTitle(`Corte ${corte_id}`);
	}, []);
	
	
	var get = ()=>{
		API.getCorte(corte_id).then(res=>{
			if (res.error) return setLoadError(res.message);
			setCorte(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error al obtener los detalles del corte. (GCE-1)');
		})
	}
	
	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}
	
	if(!corte){
		return <Header loading text='Cargando datos' />
	}

	var corte_total = corte.payments.reduce((a,b)=>a+b.amount, 0);
	
	return <div className="co corte-view">
		<Table
			title={'Corte ' + corte_id}
			details
			striped
		>
			<Table.Row data={['Inició', corte.user_start.name]}/>
			<Table.Row data={['Fecha inicio', moment.unix(corte.start_date).format('DD/MMM/YY HH:mm')]}/>
			{!!corte.user_end && <>
				<Table.Divider />
				<Table.Row data={['Finalizó', corte.user_end.name]}/>
				<Table.Row data={['Fecha fin', moment.unix(corte.end_date).format('DD/MMM/YY HH:mm')]}/>
			</>}
			<Table.Divider />
			<Table.Row>
				<Table.Cell>Total Venta</Table.Cell>
				<Table.Cell style={{ fontSize: 18, padding: 16, fontWeight: 'bold', color: 'green' }}>${addCommas(corte_total)}</Table.Cell>
			</Table.Row>
		</Table>
		<Table
			striped
			title='Métodos de pago'
			headers={['ID', 'Método', 'Cambio', 'Subtotal']}
			collapsingIndexes={[0, 2, 3]}
			data={corte.payments.map(a=>[
				a.method_id,
				a.method_name,
				addCommas(a.change_amount || 0),
				addCommas(a.amount)
			])}
			emptyText={corte.payments.length === 0 && 'No se encontraron registros.'}
		/>
		<Table
			title='Ordenes'
			striped
			headers={['ID', 'Fecha', 'Venta', 'Total']}
			collapsingIndexes={[0, 3]}
			emptyText={corte.orders.length === 0 && 'No se encontraron registros.'}
		>
			{corte.orders.map(o=>(
				<Table.Row
					compact
					selectable
					collapsingIndexes={[0, 2, 3]}
					as={Link} 
					target={'_blank'}
					to={`/orders/${o.order_id}`}
					data={[
						o.order_id,
						moment.unix(o.date_paid).format('DD/MMM/YY HH:mm'),
						o.seller?.alias ? o.seller.alias.toUpperCase() : 'WEB',
						'$'+addCommas(o.total)
					]}
				/>
			))}
		</Table>
		<Table
			title='Productos'
			striped
			headers={['ID', 'Producto', 'Cantidad', 'Precio', 'Total']}
			emptyText={corte.cart.length === 0 && 'No se encontraron registros.'}
			>
			{corte.cart.map(i=>(
				<Table.Row
					selectable
					compact
					collapsingIndexes={[0, 2, 3, 4]}
					centeredIndexes={[2]}
					as={Link}
					target={'_blank'}
					to={`/products/${i.product_id}`}
					data={[
						i.product_id,
						i.product_name,
						i.quantity,
						'$'+addCommas(i.cost_single),
						'$'+addCommas(i.total),
					]}
				/>
			))}
		</Table>
	</div>
}
			
export default Corte;
import React, { useEffect, useState } from 'react';
import { Modal, Dropdown } from 'semantic-ui-react';
import { Button, Input, Header, Pagination, Message, Table, Field } from '../components';
import { bindClick, bindSimpleFormChange } from '../Util';
import { User, UserForm } from '../Classes';

import Validator from '../Validator';
import Toast from 'react-hot-toast';
import API from '../API';
import { useTitle } from '../Hooks';
import { access } from 'fs';

const PAGE_SIZE = 20;
const initialFormState = {
	first_name: '',
	last_name: '',
	email: '',
	password: '',
	alias: '',
	access: 0,
};

const user_access = [
	{ access_id: 0, access_name: 'Sin accesso' },
	{ access_id: 1 , access_name: 'Cajero' },
	{ access_id: 2 , access_name: 'Reportes' },
	{ access_id: 10 , access_name: 'Admin' }
]

var Users = ()=>{
	var { setTitle } = useTitle();
	var [users, setUsers] = useState<User[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [page, setPage] = useState<number>(0);
	var [filter, setFilter] = useState<string>('');
	var [createModal, setCreateModal] = useState<boolean>(false);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [searching, setSearching] = useState<boolean>(false);
	var [createForm, setCreateForm] = useState<UserForm>(initialFormState);
	var [searchUsers, setSearchUsers] = useState<User[]>(null);
	var [searchTimeout, setSearchTimeout] = useState<any>(null);
   var [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if(!users) get();
		setTitle('Usuarios');
	}, []);

	var get = async (get_page?: number)=>{
      var offset = (get_page > 0) ? Math.max(0, (get_page || page) * PAGE_SIZE) : 0;

		API.getUsers(offset, PAGE_SIZE).then(res=>{
			if(res.error) return setLoadError(res.message);
			return setUsers(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener los usuarios. (GUS-1)'); 
		}).finally(()=>{
			setLoading(false);
		})
	};

	var search = (query: string)=>{
		setSearching(true);
		setSearchUsers(null);
		API.searchUsers(query).then(res=>{
			if (res.error) return setLoadError(res.message);
			setSearchUsers(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al buscar el usuario. (SUS-1)');
		}).finally(()=>{
			setSearching(false);
		})
	}

	var onPageChange = (page: number)=>{
      setLoading(true)
		setPage(page);
		get(page);
	}

	var onSearchChange = (val: string)=>{
		setFilter(val);
		if(searchTimeout) clearTimeout(searchTimeout);
		if(val.length<3){
			return setSearchUsers(null);
		}
		setSearchTimeout(setTimeout(()=>{
			search(val)
		}, 200));
	}	

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!users){
		return <Header loading text='Cargando datos' />
	}

	var shown_users = searchUsers || users;

	var onCreateFormChange = bindSimpleFormChange(createForm, setCreateForm);

	var create = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(createForm, {
			first_name: [{
				rule: 'minLength', params: [3], label: 'Nombre'
			}],
			last_name: [{
				rule: 'minLength', params: [3], label: 'Apellido'
			}],
			email: [{
				rule: 'email', label: 'Correo electrónico'
			}],
			alias: [{
				rule: 'minLength', params: [3], label: 'Alias'
			}],
			password: [{
				rule: 'minLength', params: [6], label: 'Contraseña'
			}],
			access: [{
				rule: 'empty', label: 'Accesso'
			}],

		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.register(createForm.first_name, createForm.last_name, createForm.email, createForm.alias, createForm.password, createForm.access).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			get();
			Toast.success('Usuario creado!');
			setCreateModal(false);
			return setCreateForm(initialFormState);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando el usuario (U-CRT-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	};

	return <div>
		<Table
			style={{ maxWidth: 800, margin: 'auto' }}
			striped
			selectable
			title={'Usuarios'}
			headers={['Usuario', 'Nombre', 'Apellido', 'Alias', 'Email']}
			collapsingIndexes={[0, 1, 2, 3]}
			footer={
				shown_users.length >= PAGE_SIZE || page !== 0 ? (
					<div style={{ textAlign: 'right' }}>
						<Pagination page={page} onPageChange={onPageChange} />
					</div>
				) : null
			}
			inputElement={<Input placeholder='Buscar usuarios' value={filter} onChange={onSearchChange} />}
			button={<Button size='tiny' color='black' iconName='plus circle' text='Agregar' onClick={() => setCreateModal(true)}/>}
		>
			{searching || loading ?
				<Table.Row><Table.Cell className='empty' colSpan={5}><Header loading text='Cargando datos' /></Table.Cell></Table.Row> :
				shown_users.length !== 0 ?(
					shown_users.map(u => (
						<Table.Row
							key={u.user_id}
							href={`/users/${u.user_id}`}
							collapsingIndexes={[0,4]}
							data={[
								u.user_id,
								u.first_name,
								u.last_name,
								u.alias,
								u.email
							]}
						/>
					))
				) : (
					<Table.Cell row className='empty' colSpan={5}>No se encontraron registros</Table.Cell>
				)
			}
		</Table>
		<Modal open={createModal} size='tiny' onClose={bindClick(setCreateModal)}>
			<Modal.Header>Agregar Usuario</Modal.Header>
			<Modal.Content>
				<Input label='Nombre' onChange={onCreateFormChange('first_name')}/>
				<Input label='Apellido' onChange={onCreateFormChange('last_name')}/>
				<Input label='Email' onChange={onCreateFormChange('email')}/>
				<Input label='Alias' onChange={onCreateFormChange('alias')}/>
				<Input label='Contraseña' onChange={onCreateFormChange('password')}/>
				<Field label='Acceso'>
					<Dropdown
						placeholder='Accesso'
						fluid
						selection
						options={user_access.map(access => ({
							key: access.access_id,
							text: access.access_name,
							value: access.access_id
						}))}
						style={{marginBottom: 10}}
						value={createForm.access}
						onChange={(event, data) => onCreateFormChange('access')(data.value)}
					/> 
				</Field>
				<Message type="error" list={errorPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' onClick={bindClick(setCreateModal)} basic />
				<Button text='Agregar' color='black' onClick={create}/>
			</Modal.Actions>
		</Modal>
	</div>
}

export default Users;